import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function EditEcgModal({ open, setOpen, infos, get }) {
    const handleClose = () => setOpen(false);

    const [observation, setObservation] = useState('');
    const api = useApi();

    useEffect(() => {
        setObservation(infos?.observacao);
    }, [infos]);

    async function handleSubmit(event) {
        event.preventDefault();
        const toastId = toast.loading('Etidando ECG...');
        const payload = {
            prontuarioId: infos?.prontuario?.id,
            procedimentoId: infos?.item_conduta?.procedimento?.id,
            usuarioId: infos?.usuario?.id,
            observacao: observation
        };

        try {
            await api.updateConductProcedimento(infos.conduta_medica.id, payload);
            handleClose();
            get();
            setObservation('');
            toast.update(toastId, {
                render: 'ECG editado com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });
        } catch (error) {
            toast.update(toastId, {
                render: 'Erro ao editar ECG!',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
        }
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ textAlign: 'center' }}>Editar ECG</h3>
                <form onSubmit={handleSubmit}>
                    <TextField label="Observação" fullWidth value={observation} onChange={(e) => setObservation(e.target.value)} />
                    <Button variant="contained" type="submit" fullWidth sx={{ marginTop: '20px' }}>
                        Inserir
                    </Button>
                </form>
            </Box>
        </Modal>
    );
}
