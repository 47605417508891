import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Logo from '../../../../assets/images/Logos/LOGO_DO_LAC.jpg';

const ConjuntoLaudosPDF = ({ data }) => {
    const styles = {
        page: {
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 }
    };

    const formatarData = (data) => {
        const dataFormatada = new Date(data);
        const dataFormatadaString = dataFormatada.toLocaleDateString();
        const horaFormatadaString = dataFormatada.toLocaleTimeString();
        return dataFormatadaString + ' ' + horaFormatadaString;
    };

    function formatarData2(horaLaudo) {
        if (!horaLaudo) return '-';
        // Converte a string de data/hora para um objeto Date
        const partes = horaLaudo?.split(/[\s-:]/);

        // Criar um objeto Date
        const dataHoraObj = new Date(
            partes[2], // ano
            partes[1] - 1, // mês (subtrai 1 porque os meses em JavaScript são zero-indexados)
            partes[0], // dia
            partes[3], // hora
            partes[4], // minuto
            partes[5] // segundo
        );

        // Adicionar 3 horas ao objeto Date
        dataHoraObj.setHours(dataHoraObj.getHours() + 3);

        // Formatando a nova data/hora
        const novaDataHora = dataHoraObj.toLocaleString();

        return novaDataHora;
    }

    // COMPONENTE DA TABELA

    function DinamicTable({ dados }) {
        const styles = {
            table: { width: '100%', borderCollapse: 'collapse', margin: '5px' },
            cell: { borderBottom: '1px solid #999', padding: '5px', wordWrap: 'break-word' }
        };

        return (
            <View style={{ top: '4px', paddingLeft: '15px', paddingTop: '15px' }}>
                {dados?.map((item, index) => (
                    <View
                        key={index}
                        style={{
                            breakInside: 'auto',
                            flexDirection: 'column',
                            alignItems: 'start'
                        }}
                    >
                        <View style={{ top: '0px' }}>
                            <Text>Exame: {item?.exame?.nome}</Text>
                            <Text>
                                Método: <Text style={{ fontSize: 8 }}>{item?.exame?.metodo}</Text>
                            </Text>
                            <Text>
                                Material: <Text style={{ fontSize: 8 }}>{item?.exame?.material}</Text>
                            </Text>
                        </View>
                        <View style={{ padding: 2, width: '100%', top: '0px' }}>
                            {item?.resultado_campo_especifico.map((el) => (
                                <View
                                    style={{
                                        width: '100%',
                                        flexDirection: 'row',
                                        textAlign: 'center',
                                        justifyContent: 'space-around'
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            width: 200
                                        }}
                                    >
                                        {el?.campos_especifico?.campo_nome}
                                    </Text>

                                    <View style={{ alignItems: 'flex-start' }}>
                                        {item?.exame?.nome?.includes('HEMOGRAMA') || el?.campos_especifico?.calculo ? (
                                            <Text style={{ fontSize: 14, textAlign: 'right' }}>
                                                {el.porcentagem}% - {el.valor}
                                            </Text>
                                        ) : (
                                            <Text style={{ fontSize: 14, textAlign: 'right' }}>{el.valor}</Text>
                                        )}
                                    </View>
                                </View>
                            ))}
                        </View>

                        <View style={{ width: '40%', padding: 3 }}>
                            <Text>Valores de referência</Text>
                            {item?.resultado_campo_especifico.map((el) => (
                                <Text style={{ fontSize: 8 }}>{el?.campos_especifico?.valores}</Text>
                            ))}
                        </View>

                        <View style={{ border: '1px solid #999', width: '100%' }}></View>
                    </View>
                ))}
            </View>
        );
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image src={Logo} style={styles.image} alt="logo" />
                    <Text>LABORATÓRIO DE ANÁLISES CLÍNICAS SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                </View>

                <View style={styles.box}>
                    <View style={styles.labelArea}>
                        <Text>Informações</Text>
                    </View>
                    <View>
                        {data && (
                            <View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <View>
                                        <Text>
                                            Paciente: <Text style={styles.text1}> {data[0]?.paciente?.paciente?.nome_completo}</Text>
                                        </Text>
                                        <Text>
                                            Data Nascimento:
                                            <Text style={styles.text1}> {formatarData(data[0]?.paciente?.paciente?.data_nascimento)}</Text>
                                        </Text>
                                    </View>
                                    <View>
                                        <Text>
                                            Data Exame:
                                            <Text style={styles.text1}> {formatarData2(data[0]?.medico?.hora_laudo)}</Text>
                                        </Text>
                                        <Text>
                                            F.A.: <Text style={styles.text1}> {data[0]?.resultado_campo_especifico[0]?.id_prontuario}</Text>
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
                <View>
                    <DinamicTable dados={data} />
                </View>
                {/* <View
                    style={{
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px'
                    }}
                >
                    <Text style={{ width: '50%', borderTop: '1px solid #000' }} />
                    <Text>Responsável</Text>
                </View> */}
            </Page>
        </Document>
    );
};

export default ConjuntoLaudosPDF;
