import { Box, Grid, Chip, Button, Tooltip, IconButton } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { DateFilterComponent } from '../components/DateFilterComponent';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CreateModal from './components/CreateModal';
import { toast } from 'react-toastify';
import { IconArchive, IconEdit } from '@tabler/icons';

export function Lancconsolidado() {
    const api = useApi();
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const restrictStatus = ['ALTA_MED_PROC', 'ALTA_CONSULTORIO', 'ALTA', 'ALTA_RET_ACESSO'];
    const [dateFilter, setDateFilter] = useState({
        from: null,
        to: null
    });

    const columns = [
        { field: 'emissao', headerName: 'EMISSÃO', flex: 1 },
        { field: 'codigoFA', headerName: 'CÓDIGO DA FA', flex: 1 },
        { field: 'codigo', headerName: 'CÓDIGO', flex: 1 },
        { field: 'paciente', headerName: 'PACIENTE', flex: 1 },
        {
            field: 'status',
            headerName: 'STATUS',
            flex: 1,
            renderCell: (params) => {
                const statusColors = {
                    Finalizado: 'success',
                    Evasao: 'error',
                    Triagem: 'default',
                    Recepcao: 'default',
                    'Finalizada Manual': 'warning'
                };
                return <Chip label={params.value} color={statusColors[params.value] || 'default'} />;
            }
        },
        {
            field: 'acoes',
            headerName: 'AÇÕES',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setOpenCreateModal(true);
                                setSelectedRow(params.row);
                            }}
                        >
                            <IconEdit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Arquivar">
                        <IconButton
                            disabled={restrictStatus.includes(params.row.status)}
                            onClick={() => {
                                handleArchive(params.row.codigoFA);
                            }}
                        >
                            <IconArchive />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    async function handleDateFilter() {
        if (!dateFilter.from || !dateFilter.to) return toast.error('Por favor, selecione uma data');

        const payload = {
            dataInicial: dateFilter.from,
            dataFinal: dateFilter.to
        };

        const toastId = toast.loading('Buscando faturamentos...');

        try {
            const { data } = await api.getConsolidado(payload);

            if (data.length > 0) {
                toast.update(toastId, {
                    render: 'Faturamentos encontrados com sucesso',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                });
            } else {
                toast.update(toastId, {
                    render: 'Nenhum faturamento encontrado',
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000
                });
            }

            const valuesList = data.map((item) => ({
                ...item,
                id: item.id,
                emissao: item.prontuario.createdAt,
                codigoFA: item.prontuario_id,
                codigo: item.prontuario.id_paciente,
                paciente: item.prontuario.paciente.nome_completo,
                status: item.status_andamento,
                pacienteData: item?.prontuario.paciente
            }));

            setRows(valuesList);
        } catch (error) {
            console.log('🚀 ~ handleDateFilter ~ error:', error);
        }
    }

    async function handleArchive(rowId) {
        const toastId = toast.loading('Arquivando faturamento');

        try {
            await api.arquivarFaturamento(rowId);
            await handleDateFilter();
            toast.update(toastId, {
                render: 'Faturamento arquivado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    return (
        <MainCard title="Cadastro de Faturamento" sx={{ position: 'relative' }}>
            <Grid container spacing={2}>
                <CreateModal open={openCreateModal} setOpen={setOpenCreateModal} selectedRow={selectedRow} />
                <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
                    <DateFilterComponent
                        dateFilter={dateFilter}
                        setDateFilter={setDateFilter}
                        handleDateFilter={handleDateFilter}
                        typeViews={['day', 'month', 'year']}
                        singleInput={false}
                    />
                </Box>

                <Box sx={{ width: '100%' }}>
                    {rows.length > 0 ? <DataGrid rows={rows} columns={columns} /> : <p>Nenhum registro encontrado</p>}
                </Box>
            </Grid>
        </MainCard>
    );
}
