import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Outlet } from 'react-router-dom';

import Logo from '../../src/assets/images/Logos/logo.png';
import Logo2 from '../../src/assets/logobw.png';
import { useState } from 'react';
import { useEffect } from 'react';
import { IconClock } from '@tabler/icons';

const NurginLayoute = () => {
    const [dataAtual, setDataAtual] = useState(new Date());
    const formatarNumero = (numero) => {
        return numero < 10 ? `0${numero}` : numero;
    };

    const dia = formatarNumero(dataAtual.getDate());
    const mes = formatarNumero(dataAtual.getMonth() + 1);
    const ano = dataAtual.getFullYear();

    // ----EFFECTS --- //

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#0f4c64',
                    height: '100px'
                }}
            >
                <img src={Logo2} alt="logo bw" width={300} style={{ marginLeft: '15px' }} />
                <img
                    src={Logo}
                    alt="logo Santa Casa"
                    style={{
                        width: '150px',
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        padding: '15px',
                        marginTop: '20px'
                        // marginLeft: '-330px'
                    }}
                />

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', marginRight: '20px' }}>
                    <IconClock size={60} color="#fff" />
                    <Typography variant="h2" sx={{ fontSize: '2rem', color: '#fff' }}>
                        {dia}/{mes}/{ano}
                    </Typography>
                    <Typography variant="h2" sx={{ fontSize: '2rem', color: '#fff' }}>
                        {' '}
                        {formatarNumero(dataAtual.getHours())}:{formatarNumero(dataAtual.getMinutes())}:
                        {formatarNumero(dataAtual.getSeconds())}
                    </Typography>
                </Box>
            </Box>

            <Outlet />
        </Box>
    );
};

export default NurginLayoute;
