import React, { useEffect, useState } from 'react';
import Sound from '../assets/sound.mp3';

export function PainelSoundComponent({ list }) {
    const [playSound, setPlaySound] = useState(false);
    const [tempList, setTempList] = useState([]);

    // validando duplicação

    function arraysHaveDifference(arr1, arr2) {
        if (arr1.length > arr2.length) return true;
        if (arr1.length < arr2.length) {
            setTempList(arr1);
            return false;
        }
        return !arr1.every((item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]));
    }

    function verifyDuplicate() {
        if (arraysHaveDifference(list, tempList)) {
            setPlaySound(arraysHaveDifference(list, tempList));
            setTempList(list);
        }
    }

    useEffect(() => {
        verifyDuplicate();
    }, [list]);

    // som

    function playNotificationSound() {
        const audio = new Audio(Sound);
        audio
            .play()
            .then(() => {
                setPlaySound(false);
            })
            .catch((error) => {
                console.error('Erro ao tocar o som:', error);
            });
    }

    useEffect(() => {
        if (playSound) {
            playNotificationSound();
        }
    }, [playSound]);

    return (
        <>
            {playSound && (
                <audio style={{ display: 'none' }} controls autoPlay>
                    <track kind="captions" src="#" srclang="en" label="Portuguese" />
                    <source src={Sound} type="audio/mp3" />
                    Seu navegador não suporta o elemento de áudio.
                </audio>
            )}
        </>
    );
}
