import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    FormControlLabel,
    Grid,
    IconButton,
    Modal,
    OutlinedInput,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { formatarDataIdade } from 'utils/dataIdade';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconLungs, IconArrowAutofitHeight, IconThermometer, IconPercentage, IconArrowLeft, IconArticle } from '@tabler/icons';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CloseIcon from '@mui/icons-material/Close';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import CalculateIcon from '@mui/icons-material/Calculate';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { NurseSignature } from './component/Signature';
import { ModalRoom } from './component/ModalRoom';
import MedicineApplication from '../PDF/MedicineApplication';
import { pdf } from '@react-pdf/renderer';
import { PinCodeModal } from 'components/PinCodeModal';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { removeMask } from 'utils/removeMask';
import CollectLab from '../PDF/CollectLab';
import ConfirmIdentity from './component/ConfirmIdentity';
import { LoadBw } from 'components/LoadBw';
import { MonitoringReportModal } from '../Nurse/components/MonitoringReportModal';
import { Print } from '@mui/icons-material';
import { formatarData } from 'utils/formatarData';
import ReactInputMask from 'react-input-mask';
import { NurseSignatureRaioX } from './component/SignatureNurseRaiox';
import ProcedimentosPdf from '../PDF/ProcedimentosPdf';

export function GeneralNursing() {
    const getFilePluginInstance = getFilePlugin();
    const { DownloadButton } = getFilePluginInstance;
    const printPluginInstance = printPlugin();
    const { PrintButton } = printPluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    const api = useApi();
    const navigate = useNavigate();
    const [prontuarioData, setProntuarioData] = useState([]);
    const { prontuarioId } = useParams();
    const [medicine, setMedicine] = useState({});
    const [triagem, setTriagem] = useState({});
    const [activeUserId, setActiveUserId] = useState(null);
    const [paciente, setPaciente] = useState({});
    const [medicalRecordObs, setMedicalRecordObs] = useState([]);
    const [medication, setMedication] = useState([]);
    const [cpf, setCpf] = useState('');
    const [rowsNursing, setRowsNursing] = useState([]);
    const [rowsRaiox, setRowsRaiox] = useState([]);
    const [labData, setLabData] = useState([]);
    const [nurseReport, setNurseReport] = useState([]);
    const [allusers, setAllUsers] = useState([]);
    const [selectedLabColeta, setSelectedLabColeta] = useState([]);
    const [selectedRaiox, setSelectedRaiox] = useState([]);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [openModalPdfViewer, setOpenModalPdfViewer] = useState(false);
    const [assinaturaId, setAssinaturaId] = useState(null);
    const [openSignature, setOpenSignature] = useState(false);
    const [openConfirmarIdentidade, setOpenConfirmarIdentidade] = useState(false);
    const [selectedMedAply, setSelectedMedAply] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [isRecivedSelected, setIsRecivedSelected] = useState(false);
    const [openVerifyPinCode, setOpenVerifyPinCode] = useState(false);
    const pinCodeIsActive = JSON.parse(localStorage.getItem('pinCodeStatus'));
    const [setorUsuario, setSetorUsuario] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openModalRoom, setOpenModalRoom] = useState(false);
    const [openConfirmarIdentidadeRaiox, setOpenConfirmarIdentidadeRaiox] = useState(false);
    const [openConfirmIdentityLiberarRaiox, setOpenConfirmIdentityLiberarRaiox] = useState(false);
    const [openConfirmIdendityRemoveAccess, setOpenConfirmIdendityRemoveAccess] = useState(false);
    const [openConfirmLiberarRemoveAccess, setOpenConfirmLiberarRemoveAccess] = useState(false);
    const [openSignatureRemoveAccess, setOpenSignatureRemoveAccess] = useState(false);
    const [openSignatureRaiox, setOpenSignatureRaiox] = useState(false);
    const [rowTypeAccess, setRowTypeAccess] = useState(null);
    const [loading, setLoading] = useState(false);
    // procedimentos
    const [registredExams, setRegistredExams] = useState([]);
    const [selectedItens, setSelectedItens] = useState([]);
    // relatorio de acompanhamento
    const [openConfirmIdentity, setOpenConfirmIdentity] = useState(false);
    const [openReportModalRemoveAccess, setOpenReportModalRemoveAccess] = useState(false);
    const stylePdf = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        height: '80vh'
    };

    const procedimentosColumns = [
        { field: 'description', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'observation', headerName: 'Observation', flex: 1 },
        { field: 'status', headerName: 'STATUS', flex: 1 }
    ];

    const columnsMedicalRecord = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'dia', headerName: 'DIA', flex: 1 },
        { field: 'hora', headerName: 'HORA', flex: 1 },
        { field: 'conteudo', headerName: 'CONTEÚDO', flex: 1 },
        { field: 'responsavel', headerName: 'RESPONSÁVEL', flex: 1 }
    ];

    const columns = [
        { field: 'data', headerName: 'DATA', flex: 1 },
        { field: 'hora', headerName: 'HORA', flex: 1 },
        { field: 'tipo', headerName: 'TIPO', flex: 0.8 },
        { field: 'user', headerName: 'RESPONSÁVEL', flex: 1 },
        { field: 'conteudo', headerName: 'CONTEÚDO', flex: 2 }
    ];

    const columnsRaiox = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'observacao', headerName: 'OBSERVAÇÃO', flex: 1 },
        {
            field: 'acoes',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={params.row.status === 'R-LIBERADO' || params.row.status === 'R-FINALIZADO'}
                                onClick={() => {
                                    setSelectedRaiox((prev) => {
                                        const unicChecked = prev.some((item) => item.id === params.row.id);
                                        if (unicChecked) {
                                            return prev.filter((item) => item.id !== params.row.id);
                                        }
                                        return [...prev, params.row];
                                    });
                                }}
                            />
                        }
                        label="Liberar"
                    />
                </>
            )
        }
    ];

    const columnsNursing = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'type', headerName: 'TIPO', flex: 1, valueGetter: (params) => translateNursingStatus(params.row.type) },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'user', headerName: 'RESPONSAVEL', flex: 1 },
        {
            field: 'acoes',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Imprimir">
                    <Button
                        onClick={() => {
                            getPdfBlob(params?.row?.blob_name);
                            setOpenModalPdfViewer(true);
                        }}
                    >
                        <Print />
                    </Button>
                </Tooltip>
            )
        }
    ];

    const columnsMedication = [
        { field: 'id', headerName: 'CÓD', flex: 0.4 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'qtd', headerName: 'QTDE', flex: 0.4 },
        { field: 'unidade', headerName: 'UNIDADE', flex: 0.5 },
        { field: 'observacao', headerName: 'OBSERVAÇÃO', flex: 1 },
        { field: 'aplicacao', headerName: 'APLICAÇÃO', flex: 0.5 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={
                                    params.row.status === 'ATENDIMENTO' ||
                                    params.row.status === 'APLICADO' ||
                                    params.row.status === 'ENF-LIBERADO'
                                }
                                onClick={() => {
                                    setSelectedRow((prev) => {
                                        const unicChecked = prev.some((item) => item.id === params.row.id);
                                        if (unicChecked) {
                                            return prev.filter((item) => item.id !== params.row.id);
                                        }
                                        return [...prev, params.row];
                                    });
                                    setIsRecivedSelected(true);
                                }}
                            />
                        }
                        label="Recebido"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={
                                    params.row.status === 'SEPARACAO' ||
                                    params.row.status === 'APLICADO' ||
                                    params.row.status === 'ENF-LIBERADO'
                                }
                                onClick={() => {
                                    setSelectedMedAply((prev) => {
                                        const unicChecked = prev.some((item) => item.id === params.row.id);
                                        if (unicChecked) {
                                            return prev.filter((item) => item.id !== params.row.id);
                                        }
                                        return [...prev, params.row];
                                    });
                                    setIsRecivedSelected(false);
                                }}
                            />
                        }
                        label="Aplicado"
                    />
                </>
            )
        }
    ];

    const columnsLab = [
        { field: 'id', headerName: 'CÓD', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'observacao', headerName: 'Observação', flex: 1 },
        {
            field: 'actions',
            headerName: 'COLETA',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={params.row.status === 'COLETADO' || params.row.status === 'REALIZADO'}
                                onClick={() => {
                                    setSelectedLabColeta((prev) => {
                                        const unicChecked = prev.some((item) => item.id === params.row.id);
                                        if (unicChecked) {
                                            return prev.filter((item) => item.id !== params.row.id);
                                        }
                                        return [...prev, params.row];
                                    });
                                }}
                            />
                        }
                        label="Coletado"
                    />
                </>
            )
        }
    ];

    function translateStatusMedical(status) {
        switch (status) {
            case 'MEDICACAO':
                return 'RETORNO';
            default:
                return status;
        }
    }

    function translateNursingStatus(status) {
        switch (status) {
            case 'LABORATORIO':
                return 'COLETA';
            case 'MEDICACAO':
                return 'APL. MEDICACAO';
            default:
                return status;
        }
    }

    async function getPdfBlob(blobName) {
        try {
            const { data } = await api.getBlob(blobName);
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            setPdfBlob(url);
        } catch (error) {
            toast.error(`Erro ao regatar documento: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    async function getUserBycpfReciveMedication(cpf) {
        try {
            const { data } = await api.getUserByCpf(cpf);
            setActiveUserId(data?.id);
            setOpenSignature(true);
            return data?.id;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getUserBycpfFreeRaiox(cpf) {
        try {
            const { data } = await api.getUserByCpf(cpf);
            setActiveUserId(data?.id);
            setOpenSignatureRaiox(true);
            return data?.id;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getUserByRemoveAccess(cpf) {
        try {
            const { data } = await api.getUserByCpf(cpf);
            setActiveUserId(data?.id);
            setOpenSignatureRemoveAccess(true);
            return data?.id;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getUserBycpf(cpf) {
        try {
            const { data } = await api.getUserByCpf(cpf);
            setActiveUserId(data?.id);
            return data?.id;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getUserBycpfRelatorio(cpf) {
        try {
            const { data } = await api.getUserByCpf(cpf);
            setActiveUserId(data?.id);
            setOpenModal(true);
            return data?.id;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getProntuario() {
        try {
            const { data } = await api.getProntuarioById(prontuarioId);

            setProntuarioData(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getRecord() {
        try {
            if (prontuarioData?.paciente?.id) {
                const { data } = await api.getMedicalRecord(prontuarioId, prontuarioData?.paciente?.id);
                setMedicine(data?.medicacao);
                setTriagem(data?.triagem);
                setPaciente(prontuarioData?.paciente);
                handleMedicalRecord();
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleMedicalRecord() {
        try {
            const { data } = await api.getMedicalRecordObservation(prontuarioId);
            const rows = data.map((item) => ({ ...item, responsavel: item?.usuario?.nome }));
            setMedicalRecordObs(rows);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getAllConduct() {
        try {
            const { data } = await api.getAllPendentesByProntuario(prontuarioId);
            setRowTypeAccess(data.find((item) => item.tipo === 'ACESSO'));
            const medicationFIltered = data?.filter((item) => item?.tipo === 'MEDICAMENTO');

            const rowsMedication = medicationFIltered?.map((item) => ({
                ...item,
                id: item?.id,
                descricao: item?.conduta_medica?.item_conduta?.item_pedido?.descricao,
                qtd: item?.conduta_medica?.item_conduta?.quantidade_item,
                unidade: item?.conduta_medica?.item_conduta.item_pedido?.unidade?.descricao,
                aplicacao: item?.conduta_medica?.item_conduta?.via_aplicacao,
                observacao: item?.conduta_medica?.observacao
            }));
            setMedication(rowsMedication);
            const labFIltered = data.filter((item) => item?.tipo === 'LABORATORIO');
            console.log('🚀 ~ getAllConduct ~ labFIltered:', labFIltered);
            const rowsLab = labFIltered.map((item) => ({
                ...item,
                id: item?.id,
                descricao: item?.conduta_medica?.item_conduta?.exame?.nome,
                status: item?.status,
                observacao: item?.conduta_medica?.observacao
            }));
            const raioxRows = data
                .filter((item) => item?.tipo === 'RAIOX')
                .map((item) => ({
                    ...item,
                    id: item?.conduta_medica?.item_conduta?.id,
                    atendimento_id: item?.id,
                    descricao: item?.conduta_medica?.item_conduta?.tipos_raio_x?.descricao,
                    status: item?.status,
                    observacao: item?.conduta_medica?.observacao
                }));
            const procedimentosRow = data
                .filter((item) => item?.tipo == 'PROCEDIMENTO')
                .map((item) => ({
                    id: item?.id,
                    description: item?.conduta_medica?.item_conduta?.procedimento?.descricao,
                    date: formatarData(item?.createdAt),
                    observation: item?.conduta_medica?.observacao,
                    status: item?.finalizado ? 'Finalizado' : 'Pendente'
                }));
            setRegistredExams(procedimentosRow);
            setRowsRaiox(raioxRows);
            setLabData(rowsLab);
            return true;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function users() {
        try {
            const { data } = await api.getAllUsers();
            setAllUsers(data);
        } catch (error) {
            console.log('🚀 ~ users ~ error:', error);
        }
    }

    async function getNurseReport() {
        try {
            const { data } = await api.getAllNurseReport(prontuarioId);
            const rows = data?.map((item) => {
                return {
                    ...item,
                    data: item?.dia,
                    hora: item?.hora,
                    tipo: item?.tipo,
                    user: allusers?.usuarios?.find((user) => user?.id === item?.responsavel_id)?.nome
                };
            });
            setNurseReport(rows);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleReciveMedication() {
        const toastID = toast.loading('Atualizando...');
        const payload = selectedRow.map((item) => {
            return {
                id: item?.id,
                prontuarioId: prontuarioId
            };
        });

        try {
            await api.updateMedicationReceived(payload);
            getAllConduct();
            window.location.reload();
            toast.update(toastID, { render: 'Atualizado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            console.log('🚀 ~ handleReciveMedication ~ error:', error);
            toast.update(toastID, { render: error?.response?.data?.message, type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    async function liberarRaiox() {
        const payload = selectedRaiox.map((item) => {
            return {
                id: item?.id,
                atendimento_id: item?.atendimento_id,
                responsavel_id: activeUserId
            };
        });

        try {
            await api.liberarExamesRaiox(payload);
            window.location.reload();
            toast.success('Exames liberados com sucesso');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleFinishedLab() {
        setLoading(true);
        const toastID = toast.loading('Atualizando...');
        const userIdentity = removeMask(localStorage.getItem('userIdentity'));
        const userId = await getUserBycpf(userIdentity);
        const payload = selectedLabColeta.map((item) => {
            return {
                id: item?.id,
                responsavel_id: userId,
                assinatura_id: assinaturaId,
                prontuarioId: prontuarioId
            };
        });

        try {
            const { status } = await api.updateLaboratoryFinished(payload);
            if (status == 200) {
                const arrayIds = payload.map((item) => item.id);
                await generateLabPdfBlob(arrayIds);
                toast.update(toastID, { render: 'Atualizado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            console.log('🚀 ~ handleFinishedLab ~ error:', error);
            toast.update(toastID, { render: error?.response?.data?.message, type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    async function generateLabPdfBlob(arrayIds) {
        try {
            // Gerar o blob do PDF
            const { data } = await api.getAllByArrayIds(arrayIds);
            const labFIltered = data.filter((item) => item?.tipo === 'LABORATORIO');
            const rowsLab = labFIltered.map((item) => ({
                ...item,
                id: item?.id,
                descricao: item?.conduta_medica?.item_conduta?.exame?.nome,
                status: item?.status
            }));
            const content = { paciente, exames: rowsLab };
            const document = <CollectLab data={content} />;
            const asPdf = pdf(document);
            const pdfBlob = await asPdf.toBlob();
            // Iniciar transação no CESS
            const payload = {
                identificacao: removeMask(String(localStorage.getItem('userIdentity'))),
                paciente: paciente?.nome_completo,
                tipo: 'Coleta',
                prontuario_id: prontuarioId
            };

            await cessTransactionSignature(payload, pdfBlob);
            setOpenModalRoom(true);
        } catch (error) {
            toast.error(`Erro ao gerar aplicação: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    async function generateLinkedPdfBlob(arrayIds) {
        try {
            // Gerar o blob do PDF
            const { data } = await api.getAllByArrayIds(arrayIds);
            const procedimentosFiltrados = data.filter((item) => item?.tipo === 'PROCEDIMENTO');
            const rowsProcedimento = procedimentosFiltrados.map((item) => ({
                ...item,
                id: item?.id,
                descricao: item?.conduta_medica?.item_conduta?.procedimento?.descricao,
                status: item?.status
            }));
            const content = { paciente, procedimentos: rowsProcedimento };
            const document = <ProcedimentosPdf data={content} />;
            const asPdf = pdf(document);
            const pdfBlob = await asPdf.toBlob();
            // Iniciar transação no CESS
            const payload = {
                identificacao: removeMask(String(localStorage.getItem('userIdentity'))),
                paciente: paciente?.nome_completo,
                tipo: 'Procedimento',
                prontuario_id: prontuarioId
            };
            await cessTransactionSignature(payload, pdfBlob);
            setOpenModalRoom(true);
        } catch (error) {
            toast.error(`Erro ao gerar procedimento: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    async function cessTransactionSignature(payload, pdfBlob) {
        const responseCess = await api.initTransactionCess(payload);
        // Enviar o arquivo para assinatura
        if (responseCess.status == 200 && responseCess.data) {
            const tcn = responseCess.data.tcn;
            localStorage.setItem('tcn', tcn);
            const formData = new FormData();
            formData.append('pdf', pdfBlob);
            formData.append('transactionTcn', tcn);
            const responseUpload = await api.uploadPdfCess(formData);
            if (responseUpload.status == 200) {
                localStorage.removeItem('tcn');
                const userInfo = JSON.parse(localStorage.getItem('userInfos'));
                if (userInfo?.usuario.setor != 'médico') {
                    localStorage.removeItem('userIdentity');
                }
                setOpenVerifyPinCode(false);
            }
        }
    }

    async function generateMedicinePdfBlob(array) {
        try {
            // Gerar o blob do PDF
            const { data } = await api.getAllByArrayIds(array);
            const medicationFIltered = data?.filter((item) => item?.tipo === 'MEDICAMENTO');
            const rowsMedication = medicationFIltered?.map((item) => ({
                ...item,
                id: item?.id,
                descricao: item?.conduta_medica?.item_conduta?.item_pedido?.descricao,
                qtd: item?.conduta_medica?.item_conduta?.quantidade_item,
                unidade: item?.conduta_medica?.item_conduta.item_pedido?.unidade?.descricao,
                aplicacao: item?.conduta_medica?.item_conduta?.via_aplicacao
            }));
            const content = { paciente, medicamentos: rowsMedication };
            const document = <MedicineApplication data={content} />;
            const asPdf = pdf(document);
            const pdfBlob = await asPdf.toBlob();
            // Iniciar transação no CESS
            const payload = {
                identificacao: removeMask(String(localStorage.getItem('userIdentity'))),
                paciente: paciente?.nome_completo,
                tipo: 'Aplicação',
                prontuario_id: prontuarioId
            };

            await cessTransactionSignature(payload, pdfBlob);
            setOpenModalRoom(true);
        } catch (error) {
            toast.error(`Erro ao gerar aplicação: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    async function getNursingCertifiedRecords(prontuarioId) {
        try {
            const { data } = await api.buscarRegistrosProcedimentosProntuarioId(prontuarioId);

            const rowsFormated = data.map((element) => ({
                ...element,
                id: element.id,
                type: element.tipo,
                blob_name: element.stored_blob_name,
                user: element?.usuario.nome,
                date: element.formated_date
            }));
            console.log('🚀 ~ rowsFormated ~ rowsFormated:', rowsFormated);
            setRowsNursing(rowsFormated);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleApplyMedication() {
        setLoading(true);
        const toastID = toast.loading('Atualizando...');
        const userIdentity = removeMask(localStorage.getItem('userIdentity'));
        const userId = await getUserBycpf(userIdentity);

        const payload = selectedMedAply.map((item) => {
            return {
                id: item?.id,
                responsavel_id: userId,
                prontuarioId: prontuarioId
            };
        });

        try {
            const { status } = await api.updateMedicationFinished(payload);
            if (status === 200) {
                const arrayIds = payload.map((item) => item.id);
                await generateMedicinePdfBlob(arrayIds);
                toast.update(toastID, { render: 'Atualizado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            setLoading(false);
            toast.update(toastID, { render: error?.response?.data?.message, type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    async function handleClickLiberationMedicine() {
        const applyedMedication = medication.filter((item) => item.status === 'APLICADO');
        try {
            await api.liberarMedicacao(applyedMedication.map((item) => ({ id: item.id })));
            window.location.reload();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleClickApplyMedicine() {
        // Caso não seja médico, sempre vai pedir o CPF
        if (setorUsuario !== 'médico' || !pinCodeIsActive) {
            setOpenVerifyPinCode(true);
        } else {
            await handleApplyMedication();
        }
    }

    async function handleClickCollectLab() {
        // Caso não seja médico, sempre vai pedir o CPF
        if (setorUsuario !== 'médico' || !pinCodeIsActive) {
            setOpenVerifyPinCode(true);
        } else {
            await handleFinishedLab();
        }
    }

    async function handleConfirmPinCode() {
        const option = localStorage.getItem('option');
        setOpenVerifyPinCode(false);
        if (option == 'medicacao') {
            await handleApplyMedication();
        } else if (option == 'laboratorio') {
            await handleFinishedLab();
        } else if (option == 'procedimento') {
            await handleFinishLinkExam();
        }
    }

    function handleCloseModalRelatorio(value) {
        setOpenModal(value);
        if (value === false) {
            window.location.reload();
        }
    }

    function handleSelection(selectedItems) {
        setSelectedItens(selectedItems);
    }

    async function linkedExamsHandler() {
        if (setorUsuario !== 'médico' || !pinCodeIsActive) {
            setOpenVerifyPinCode(true);
        } else {
            handleFinishLinkExam();
        }
    }

    async function handleFinishLinkExam() {
        const payload = selectedItens.map((item) => ({ id: item }));
        const toastId = toast.loading('Finalizando exames...');
        const userIdentity = removeMask(localStorage.getItem('userIdentity'));
        await getUserBycpf(userIdentity);
        try {
            const { status } = await api.finishLinkedExams(payload);
            toast.update(toastId, { render: 'Exames finalizados com sucesso', type: 'success', isLoading: false, autoClose: 2000 });

            if (status == 200) {
                const arrayIds = payload.map((item) => item.id);
                await generateLinkedPdfBlob(arrayIds);
                toast.update(toastId, { render: 'Atualizado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao finalizar exames', type: 'error', isLoading: false, autoClose: 2000 });
        }
    }

    async function getUserBycpf(cpf) {
        try {
            const { data } = await api.getUserByCpf(cpf);
            if (!data?.id) return toast.error('Cpf informado não encontrado nos registros do sistema');
            setActiveUserId(data?.id);
            setOpenConfirmIdentity(false);
            return data?.id;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleRemoveAccess() {
        try {
            await api.removeAccess(rowTypeAccess.id, activeUserId);
            setOpenReportModalRemoveAccess(true);
        } catch (error) {
            console.log('🚀 ~ handleRemoveAccess ~ error:', error);
        }
    }

    function handleCloseModalRelatorioRemoveAccess(value) {
        setOpenReportModalRemoveAccess(value);
        if (value === false) {
            navigate('/main/enfermagem');
        }
    }

    useEffect(() => {
        getRecord();
    }, [prontuarioData]);

    useEffect(() => {
        getNurseReport();
    }, [allusers, openModal]);

    useEffect(() => {
        const dadosUsuario = JSON.parse(localStorage.getItem('userInfos'));
        setSetorUsuario(dadosUsuario?.usuario.setor);
        getAllConduct();
        users();
        getProntuario();
        getNursingCertifiedRecords(prontuarioId);
        handleMedicalRecord();
    }, []);

    return (
        <MainCard title={`Enfermagem - ${prontuarioData?.paciente?.nome_completo}`}>
            <LoadBw loading={loading} />
            <PinCodeModal open={openVerifyPinCode} setOpen={setOpenVerifyPinCode} confirmPin={handleConfirmPinCode} />

            <MonitoringReportModal
                open={openModal}
                setOpen={handleCloseModalRelatorio}
                patient={{
                    id: prontuarioData?.paciente?.id,
                    name: prontuarioData?.paciente?.nome_completo,
                    prontuario_id: prontuarioData?.id
                }}
                activeUserId={activeUserId}
                cpf={cpf}
            />
            <MonitoringReportModal
                open={openReportModalRemoveAccess}
                setOpen={handleCloseModalRelatorioRemoveAccess}
                patient={{
                    id: prontuarioData?.paciente?.id,
                    name: prontuarioData?.paciente?.nome_completo,
                    prontuario_id: prontuarioData?.id
                }}
                activeUserId={activeUserId}
            />

            <Dialog open={openConfirmIdentity}>
                <DialogTitle>Confirmação de identidade</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Typography variant="h4">Informe seu CPF para confirmar sua identidade</Typography>
                        <ReactInputMask mask="999.999.999-99" value={cpf} onChange={(e) => setCpf(e.target.value)}>
                            {(inputProps) => (
                                <OutlinedInput
                                    autoComplete="off"
                                    id="outlined-adornment-cpf-register"
                                    type="text"
                                    name="cpf"
                                    inputProps={inputProps}
                                />
                            )}
                        </ReactInputMask>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setCpf('');
                                setOpenConfirmIdentity(false);
                            }}
                        >
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                getUserBycpfRelatorio(removeMask(cpf));
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmIdentityLiberarRaiox}>
                <DialogTitle>Confirmação de identidade</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Typography variant="h4">Informe seu CPF para confirmar sua identidade</Typography>
                        <ReactInputMask mask="999.999.999-99" value={cpf} onChange={(e) => setCpf(e.target.value)}>
                            {(inputProps) => (
                                <OutlinedInput
                                    autoComplete="off"
                                    id="outlined-adornment-cpf-register"
                                    type="text"
                                    name="cpf"
                                    inputProps={inputProps}
                                />
                            )}
                        </ReactInputMask>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setCpf('');
                                setOpenConfirmIdentityLiberarRaiox(false);
                            }}
                        >
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                getUserBycpfFreeRaiox(removeMask(cpf));
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmLiberarRemoveAccess}>
                <DialogTitle>Confirmação de identidade</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Typography variant="h4">Informe seu CPF para confirmar sua identidade</Typography>
                        <ReactInputMask mask="999.999.999-99" value={cpf} onChange={(e) => setCpf(e.target.value)}>
                            {(inputProps) => (
                                <OutlinedInput
                                    autoComplete="off"
                                    id="outlined-adornment-cpf-register"
                                    type="text"
                                    name="cpf"
                                    inputProps={inputProps}
                                />
                            )}
                        </ReactInputMask>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setCpf('');
                                setOpenConfirmLiberarRemoveAccess(false);
                            }}
                        >
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                getUserByRemoveAccess(removeMask(cpf));
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <ConfirmIdentity
                openConfirmarIdentidade={openConfirmarIdentidade}
                setCpf={setCpf}
                cpf={cpf}
                getUserBycpf={getUserBycpfReciveMedication}
                setOpenConfirmarIdentidade={setOpenConfirmarIdentidade}
            />

            <ConfirmIdentity
                openConfirmarIdentidade={openConfirmarIdentidadeRaiox}
                setCpf={setCpf}
                cpf={cpf}
                getUserBycpf={getUserBycpfFreeRaiox}
                setOpenConfirmarIdentidade={setOpenConfirmarIdentidadeRaiox}
            />

            <ConfirmIdentity
                openConfirmarIdentidade={openConfirmIdendityRemoveAccess}
                setCpf={setCpf}
                cpf={cpf}
                getUserBycpf={handleRemoveAccess}
                setOpenConfirmarIdentidade={setOpenConfirmIdendityRemoveAccess}
            />

            <NurseSignature
                confirmFunction={isRecivedSelected ? handleReciveMedication : handleApplyMedication}
                setAssinaturaId={setAssinaturaId}
                open={openSignature}
                setOpen={setOpenSignature}
                userId={activeUserId}
            />
            <NurseSignatureRaioX
                confirmFunction={liberarRaiox}
                setAssinaturaId={setAssinaturaId}
                open={openSignatureRaiox}
                setOpen={setOpenSignatureRaiox}
                userId={activeUserId}
            />
            <NurseSignatureRaioX
                confirmFunction={handleRemoveAccess}
                setAssinaturaId={setAssinaturaId}
                open={openSignatureRemoveAccess}
                setOpen={setOpenSignatureRemoveAccess}
                userId={activeUserId}
            />

            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                {pdfBlob && (
                    <Modal
                        open={openModalPdfViewer}
                        onClose={() => {
                            setPdfBlob(null);
                            setOpenModalPdfViewer(false);
                        }}
                    >
                        <Box sx={stylePdf}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: '1rem',
                                    padding: '4px',
                                    alignItems: 'center',
                                    backgroundColor: '#eeeeee',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
                                }}
                            >
                                <DownloadButton />
                                <PrintButton />
                                <ZoomOutButton />
                                <ZoomPopover />
                                <ZoomInButton />
                            </Box>
                            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                                <Viewer fileUrl={pdfBlob} plugins={[getFilePluginInstance, printPluginInstance, zoomPluginInstance]} />
                            </Worker>
                        </Box>
                    </Modal>
                )}

                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', position: 'absolute', right: 0, top: '-60px' }}>
                        <p>
                            PRONTUÁRIO: <b>{triagem?.prontuario_id}</b>
                        </p>
                        <p>
                            FICHA DE ATENDIMENTO: <b> {medicine?.paciente_id}</b>
                        </p>
                        <p>
                            DATA:{' '}
                            <b>
                                {' '}
                                {triagem?.data} {triagem.hora}
                            </b>
                        </p>
                        <p>
                            IDADE: <b> {formatarDataIdade(paciente?.data_nascimento)}</b>
                        </p>
                        <p>
                            SITUAÇÃO MÉDICA: <b>{translateStatusMedical(prontuarioData?.status)}</b>
                        </p>
                    </Box>
                    <Box sx={{ padding: '10px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h4>ESCUTA INICIAL</h4>

                            <h4>
                                RESPONSÁVEL: <b>{triagem?.usuario?.nome}</b>
                            </h4>
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MonitorHeartIcon />
                                <TextField
                                    label="SIS"
                                    type="number"
                                    value={triagem?.pa_1}
                                    name="pa_sis"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px', marginLeft: '.5rem' }}
                                />
                                <CloseIcon sx={{ margin: '0 5px' }} />
                                <TextField
                                    label="DIA"
                                    type="number"
                                    placeholder="DIA"
                                    value={triagem?.pa_2}
                                    name="pa_dia"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FavoriteIcon />
                                <TextField
                                    label="FC"
                                    type="number"
                                    value={triagem?.fc}
                                    name="fc"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconLungs />
                                <TextField
                                    label="FR"
                                    type="number"
                                    value={triagem?.fr}
                                    name="fc"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <VaccinesIcon />
                                <TextField
                                    label="DEXTRO"
                                    type="number"
                                    value={triagem?.dextro}
                                    name="fc"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MonitorWeightIcon />
                                <TextField
                                    disabled
                                    label="Peso"
                                    name="peso"
                                    type="text"
                                    value={triagem.peso}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px', marginLeft: '.5rem' }}
                                />
                                <IconArrowAutofitHeight sx={{ margin: '0 5px' }} />
                                <TextField
                                    disabled
                                    label="Altura"
                                    name="altura"
                                    type="text"
                                    value={triagem.altura}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px', marginLeft: '.5rem' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CalculateIcon />
                                <TextField
                                    label="IMC"
                                    name="IMC"
                                    type="number"
                                    value={triagem.imc}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconThermometer />
                                <TextField
                                    label="Temperatura"
                                    name="temperatura"
                                    type="text"
                                    value={triagem?.temperatura}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconPercentage />
                                <TextField
                                    label="Saturação"
                                    name="saturacao"
                                    type="number"
                                    value={triagem?.saturacao}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: '10px' }}>
                            QUEIXA: <b>{triagem?.queixa ? triagem?.queixa : 'QUEIXA NÃO INFORMADA'}</b>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p>
                                MÉDICAÇÃO EM USO:{' '}
                                {medicine?.c?.length === 0
                                    ? 'NEGA'
                                    : medicine?.medicacao_em_uso?.map((item, index, array) => (
                                          <span key={index}>
                                              {array?.length - 1 === index ? <b>{item?.toUpperCase()}</b> : <b>{item?.toUpperCase()} |</b>}{' '}
                                          </span>
                                      ))}
                            </p>
                            <p>
                                ALERGIA:{' '}
                                {medicine?.alergia?.length === 0
                                    ? 'NEGA'
                                    : medicine?.alergia?.map((item, index, array) => (
                                          <span key={index}>
                                              {array?.length - 1 === index ? <b>{item?.toUpperCase()}</b> : <b>{item?.toUpperCase()} |</b>}{' '}
                                          </span>
                                      ))}
                            </p>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '10px', marginBottom: '10px', gap: '20px' }}>
                    {prontuarioData?.salas_enfermagem?.descricao ? (
                        <p>SALA DE ESPERA: {prontuarioData?.salas_enfermagem?.descricao?.toUpperCase()}</p>
                    ) : (
                        ''
                    )}
                    <ModalRoom setOpenModal={setOpenModal} getProntuario={getProntuario} open={openModalRoom} setOpen={setOpenModalRoom} />
                </Box>

                {!!medicalRecordObs?.length && (
                    <Box sx={{ borderRadius: '7px', border: '1px solid #ccc', padding: '10px', marginTop: '10px' }}>
                        <Box>
                            <h3>Ficha médica</h3>
                        </Box>

                        <DataGrid
                            rows={medicalRecordObs}
                            columns={columnsMedicalRecord}
                            hideFooter
                            getRowHeight={() => 'auto'}
                            sx={{
                                maxHeight: '300px',
                                overflow: 'auto',
                                '& .MuiDataGrid-row': {
                                    padding: '10px'
                                }
                            }}
                        />
                    </Box>
                )}

                <Box>
                    {medication?.length > 0 && (
                        <Box>
                            <h3>Medicação</h3>
                            <DataGrid
                                rows={medication}
                                getRowHeight={() => 'auto'}
                                sx={{ maxHeight: '400px' }}
                                columns={columnsMedication}
                                hideFooter
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', alignItems: 'center', gap: '20px' }}>
                                <Button
                                    disabled={selectedRow?.length == 0 || selectedMedAply?.length > 0}
                                    variant="contained"
                                    onClick={() => {
                                        if (setorUsuario != 'médico') {
                                            setOpenConfirmarIdentidade(true);
                                        } else {
                                            setOpenSignature(true);
                                        }
                                    }}
                                >
                                    Receber
                                </Button>
                                <Button
                                    disabled={selectedMedAply?.length == 0 || selectedRow?.length > 0}
                                    variant="contained"
                                    onClick={() => {
                                        localStorage.setItem('option', 'medicacao');
                                        handleClickApplyMedicine();
                                    }}
                                >
                                    Aplicar
                                </Button>
                                <Button
                                    disabled={medication.every((item) => item.status != 'APLICADO')}
                                    variant="contained"
                                    onClick={handleClickLiberationMedicine}
                                >
                                    Liberar
                                </Button>
                            </Box>
                        </Box>
                    )}
                    <Box>
                        {registredExams?.length > 0 && (
                            <>
                                <h3>PROCEDIMENTOS</h3>
                                <DataGrid
                                    rows={registredExams}
                                    columns={procedimentosColumns}
                                    hideFooter={true}
                                    checkboxSelection
                                    onRowSelectionModelChange={handleSelection}
                                    isRowSelectable={(params) => params.row.status !== 'Finalizado'}
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '10px' }}>
                                    <Button
                                        variant="contained"
                                        disabled={registredExams.every((item) => item.status == 'Finalizado')}
                                        onClick={() => {
                                            localStorage.setItem('option', 'procedimento');
                                            linkedExamsHandler();
                                        }}
                                    >
                                        Finalizar
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                    {labData?.length > 0 && (
                        <Box sx={{ marginTop: '50px' }}>
                            <h3>Laboratório</h3>
                            <DataGrid
                                rows={labData}
                                sx={{ maxHeight: '400px' }}
                                getRowHeight={() => 'auto'}
                                columns={columnsLab}
                                hideFooter
                            />
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                <Button
                                    variant="contained"
                                    disabled={selectedLabColeta?.length == 0}
                                    onClick={() => {
                                        localStorage.setItem('option', 'laboratorio');
                                        handleClickCollectLab();
                                    }}
                                >
                                    Coletar Laboratório
                                </Button>
                            </Box>
                        </Box>
                    )}

                    {rowsRaiox?.length > 0 && (
                        <Box>
                            <h3>RAIO-X</h3>
                            <DataGrid
                                rows={rowsRaiox}
                                sx={{ maxHeight: '400px' }}
                                columns={columnsRaiox}
                                hideFooter
                                getRowHeight={() => 'auto'}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', alignItems: 'center', gap: '40px' }}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if (setorUsuario != 'médico') {
                                            setOpenConfirmarIdentidadeRaiox(true);
                                        } else {
                                            setOpenConfirmIdentityLiberarRaiox(true);
                                        }
                                    }}
                                    disabled={selectedRaiox?.length == 0}
                                >
                                    Liberar
                                </Button>
                            </Box>
                        </Box>
                    )}

                    <Box
                        sx={{
                            padding: '10px',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            borderRadius: '7px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>ACOMPANHAMENTOS DE ENFERMAGEM CERTIFICADOS</h3>
                        </Box>

                        {rowsNursing?.length > 0 && <DataGrid columns={columnsNursing} rows={rowsNursing} />}
                    </Box>

                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>RELATÓRIO DE ACOMPANHAMENTO</h3>
                            <Tooltip title="Gerar Relatório">
                                <IconButton onClick={() => setOpenConfirmIdentity(true)}>
                                    <IconArticle />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <DataGrid
                            // getRowHeight={() => 'auto'}
                            rows={nurseReport}
                            sx={{ maxHeight: '400px' }}
                            columns={columns}
                            hideFooter
                        />
                    </Box>
                    {!!rowTypeAccess && (
                        <Box sx={{ margin: '20px 0' }}>
                            <Button
                                onClick={() => {
                                    if (setorUsuario != 'médico') {
                                        setOpenConfirmLiberarRemoveAccess(true);
                                    } else {
                                        setOpenSignatureRemoveAccess(true);
                                    }
                                }}
                                variant="contained"
                            >
                                Retirar acesso
                            </Button>
                        </Box>
                    )}

                    <Fab variant="extended" sx={{ position: 'fixed', bottom: '20px', right: '20px' }} onClick={() => navigate(-1)}>
                        <IconArrowLeft />
                        Voltar
                    </Fab>
                </Box>
            </Grid>
        </MainCard>
    );
}
