const azureAPI = process.env.REACT_APP_api_azure;
const vmAPI = process.env.REACT_APP_api_vm;
const railwayAPI = process.env.REACT_APP_api_railway;
const ENV_TYPE = process.env.REACT_APP_ENV_TYPE || 'development';
const urlConnectionMapping = {
    development: railwayAPI,
    homolog: vmAPI,
    production: azureAPI
};
const urlConnection = urlConnectionMapping[ENV_TYPE];
// Mapeamento das conexões
const azureWEBSOCKET = process.env.REACT_APP_websocket_azure;
const railwayWEBSOCKET = process.env.REACT_APP_websocket_railway;
const vmWEBSOCKET = process.env.REACT_APP_websocket_vm;
const urlSocketConnectionMapping = {
    development: railwayWEBSOCKET,
    homolog: vmWEBSOCKET,
    production: azureWEBSOCKET
};
const urlSocketConnection = urlSocketConnectionMapping[ENV_TYPE];
export { urlConnection, urlSocketConnection };
