// assets
import { IconDoor, IconMedicineSyrup } from '@tabler/icons';
import BuildIcon from '@mui/icons-material/Build';

const icons = {
    IconDoor,
    IconMedicineSyrup,
    BuildIcon
};

const enfermagem = {
    id: 'enfermagem',
    title: 'Enfermagem',
    type: 'group',
    children: [
        {
            id: 'rooms',
            title: 'Salas',
            type: 'item',
            url: '/main/enfermagem/salas',
            icon: icons.IconDoor,
            breadcrumbs: false
        },
        {
            id: 'enfermagem',
            title: 'Enfermagem',
            type: 'item',
            url: '/main/enfermagem',
            icon: icons.IconMedicineSyrup,
            breadcrumbs: false
        },
        {
            id: 'manutencao',
            title: 'Manutenção Ficha',
            type: 'item',
            url: '/main/manutencao-ficha',
            icon: icons.BuildIcon,
            breadcrumbs: false
        }
    ]
};

export default enfermagem;
