import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, FormControlLabel, IconButton, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { VolunteerActivism } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { IconTrash } from '@tabler/icons';
import { useParams } from 'react-router';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxHeight: '90vh',
    minHeight: '30vh'
};

export function Medicine({ open, setOpen, name, prontuarioId, get }) {
    const handleClose = () => setOpen(false);
    const { serviceId } = useParams();
    const api = useApi();
    const [inputValue, setInputValue] = useState({ descricao: '' });
    const [viaAplicacao, setViaAplicacao] = useState('');
    const [selectedExams, setSelectedExams] = useState(null);
    const [medicine, setMedicine] = useState([]);
    const [observation, setObservation] = useState('');
    const [qtd, setQtd] = useState(1);
    const [medicineList, setMedicineList] = useState([]);
    const [rows, setRows] = useState([]);
    const [isAntimicrobiano, setIsAntimicrobiano] = useState(false);
    const [alreadyUsed, setAlreadyUsed] = useState(false);

    const handleChange = (event) => {
        setViaAplicacao(event.target.value);
    };

    async function getMedicine() {
        try {
            const { data } = await api.getAllItems();
            setMedicine(data.filter((item) => item?.descricao !== '.'));
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    const handleInsertExam = (selectedExam) => {
        setSelectedExams(selectedExam);
        setInputValue({ descricao: '' });
    };

    useEffect(() => {
        if (open) {
            getMedicine();
        }
    }, [open]);

    async function handleSubmit() {
        const toastId = toast.loading('Inserindo medicamentos...');

        try {
            for (const item of medicineList) {
                await api.linkMedicinesConduct(item); // Requisição feita uma de cada vez
            }

            toast.update(toastId, {
                render: 'Medicamentos inseridos com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });

            get();
            handleClose();
            setQtd('');
            setObservation('');
            setViaAplicacao('');
            setMedicineList([]);
            setRows([]);
        } catch (error) {
            toast.update(toastId, {
                render: 'Erro ao inserir medicamentos!',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
            console.error('Erro ao enviar medicamentos:', error);
        }
    }

    function handleClearState() {
        setQtd(1);
        setObservation('');
        setViaAplicacao('');
        setSelectedExams(null);
        setInputValue({ descricao: '' });
        setIsAntimicrobiano(false);
        setAlreadyUsed(false);
    }

    function handleTableInformation() {
        const row = medicineList.map((item, index) => {
            return {
                ...item,
                name: medicine.find((medicine) => medicine.id === item.itemId)?.descricao,
                qtd: item.quantidadeItem,
                via: item.viaAplicacao,
                id: index
            };
        });
        setRows(row);
    }

    function handleAddMultipleMedication() {
        const userString = localStorage.getItem('userInfos');
        const userObject = JSON.parse(userString);

        const payload = {
            itemId: selectedExams?.id,
            diluicaoId: null,
            viaAplicacao: viaAplicacao,
            quantidadeItem: qtd,
            quantidadeDiluicao: null,
            frequencia: 'AGORA',
            observacao: observation,
            prontuarioId: prontuarioId,
            responsavelId: userObject.usuario.id,
            uso_antimicrobiano: alreadyUsed ? 'Sim' : 'Não',
            atendimentoId: serviceId
        };

        setMedicineList((prev) => [...prev, payload]);

        handleClearState();
    }

    useEffect(() => {
        handleTableInformation();
    }, [medicineList]);

    const columns = [
        { field: 'name', headerName: 'MEDICAMENTO', flex: 1 },
        { field: 'qtd', headerName: 'QUANTIDADE', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'via', headerName: 'VIA DE APLICAÇÃO', flex: 1 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <Tooltip title="Excluir">
                    <IconButton
                        onClick={() => {
                            setRows(rows.filter((item) => item.id !== params.row.id));
                            setMedicineList(rows.filter((item) => item.id !== params.row.id));
                        }}
                    >
                        <IconTrash />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography variant="h4" gutterBottom>
                    MEDICAMENTOS - {name}
                </Typography>

                <form style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginBottom: '10px' }}>
                    {/* <Typography variant="h5" gutterBottom sx={{ margin: '15px 0 5px 0' }}>
                        MEDICAMENTO
                    </Typography> */}
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={medicine}
                            getOptionLabel={(option) => option.descricao}
                            inputValue={inputValue.descricao}
                            value={selectedExams} // Adicionado para controlar o valor selecionado
                            onInputChange={(event, newInputValue) => setInputValue({ descricao: newInputValue })}
                            onChange={(event, newValue) => {
                                setSelectedExams(newValue);
                                console.log('🚀 ~ newValue:', newValue);
                                if (newValue?.antimicrobiano) {
                                    setIsAntimicrobiano(true);
                                } else {
                                    setIsAntimicrobiano(false);
                                }
                            }} // Alterado para lidar com o valor selecionado
                            sx={{ flexGrow: 1, minWidth: 200 }}
                            renderInput={(params) => <TextField {...params} fullWidth label="Medicamento" />}
                        />
                        <TextField
                            label="QTD"
                            onChange={(e) => {
                                const value = Number(e.target.value);
                                if (value >= 1 && value <= 100) {
                                    setQtd(value);
                                }
                            }}
                            value={qtd}
                            type="number"
                            min="1"
                            max="100"
                            sx={{ width: '100px', flexShrink: 0 }}
                        />
                        <TextField
                            label="UN"
                            sx={{ width: '100px', flexShrink: 0 }}
                            InputLabelProps={{ shrink: true }}
                            value={selectedExams && selectedExams?.unidade?.sigla}
                        />
                        <TextField
                            sx={{ flexGrow: 1, minWidth: 150 }}
                            label="Via de aplicação"
                            select
                            SelectProps={{
                                native: true
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={viaAplicacao}
                            onChange={handleChange}
                        >
                            <option value="">Selecione</option>
                            <option value="intravenosa">Intravenosa - IV</option>
                            <option value="intramuscular">Intramuscular - IM</option>
                            <option value="subcutanea">Subcutânea - SC</option>
                            <option value="oral">Oral</option>
                            <option value="topica">Tópica</option>
                            <option value="inalatorio">Inalatório</option>
                            <option value="oftalmico">Oftálmico</option>
                        </TextField>
                    </Box>

                    {isAntimicrobiano && (
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <p>Uso prévido de antimicrobianos :</p>
                            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <p>Não</p>
                                <FormControlLabel control={<Switch onChange={(e) => setAlreadyUsed(e.target.checked)} />} label="Sim" />
                            </Box>
                        </Box>
                    )}

                    <Typography variant="h5" gutterBottom sx={{ margin: '5px 0 5px 0' }}>
                        Observação
                    </Typography>
                    <TextField
                        label="Observação"
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                        fullWidth
                        rows={2}
                        multiline
                    />
                </form>
                <Box sx={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
                    <Button variant="contained" type="button" onClick={handleAddMultipleMedication}>
                        Inserir
                    </Button>
                </Box>
                {rows.length > 0 && (
                    <>
                        <Box>
                            <h3>Listagem dos medicamentos selecionados</h3>
                            <DataGrid
                                columns={columns}
                                rows={rows}
                                sx={{ width: '100%', maxHeight: '200px', overflow: 'auto' }}
                                hideFooter
                            />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                            <Button variant="contained" onClick={handleSubmit}>
                                Salvar medicamentos
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
}
