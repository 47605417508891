import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Divider, Button, TextField, Link } from '@mui/material';
import PinField from 'react-pin-field';
import './styles.scss';
import { useApi } from 'Service/axios';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';
import { removeMask } from 'utils/removeMask';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    padding: 4,
    borderRadius: '7px'
};

export const PinCodeModal = ({ open, setOpen, confirmPin }) => {
    const api = useApi();
    const [completed, setCompleted] = useState(false);
    const [pinCode, setPinCode] = useState('');
    const [error, setError] = useState(false);
    const [cpf, setCpf] = useState('');
    const [needPin, setNeedPin] = useState(false);
    const [userSector, setUserSector] = useState('');
    const [showPin, setShowPin] = useState(false);
    const [loading, setLoading] = useState(false);
    const refCode = useRef([]);
    const userId = JSON.parse(localStorage.getItem('id'));

    async function handleVerifyPinCode(cpf, pinCode, userId) {
        const payload = {
            identificacao: cpf,
            otp: pinCode
        };

        try {
            setLoading(true);
            const { status } = await api.oauthWithBirdId(payload);
            if (status == 200) {
                localStorage.setItem('pinCodeStatus', true);
                localStorage.setItem('userIdentity', cpf);
            }
            setError(false);
            setCompleted(true);
            await confirmPin();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.setItem('pinCodeStatus', false);
            }
            refCode.current.forEach((input) => (input.value = ''));
            setError(true);
            setPinCode('');
            setCompleted(false);
        } finally {
            setLoading(false);
        }
    }

    async function verifyTokenCess(cpf) {
        const toastId = toast.loading('Validando usuário...');
        try {
            const userId = localStorage.getItem('id');
            const { status } = await api.verifyPinCode({ identificacao: cpf, usuario_id: userId });
            if (status == 200) {
                localStorage.setItem('pinCodeStatus', true);
                localStorage.setItem('userIdentity', cpf);
                setNeedPin(false);
                setError(false);
                setCompleted(true);
                await confirmPin();
            }
            toast.update(toastId, {
                render: 'Usuário verificado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            if (error?.response?.status == 401 || error?.response?.status == 400) {
                setShowPin(true);
                setNeedPin(true);
                localStorage.setItem('pinCodeStatus', false);
                toast.update(toastId, {
                    render: 'Token BirdID Expirado ou inexistente',
                    type: 'warning',
                    isLoading: false,
                    autoClose: 3000
                });
            } else if (error?.response?.status == 422) {
                localStorage.setItem('pinCodeStatus', false);
                toast.update(toastId, {
                    render: error?.response?.data?.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000
                });
            } else {
                setOpen(false);
                toast.update(toastId, {
                    render: `Erro ao verificar token de certificado: ${error?.response?.data?.message ?? error?.message}`,
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000
                });
            }
        }
    }

    // Controle CPF
    useEffect(() => {
        if (removeMask(cpf).length === 11) {
            verifyTokenCess(removeMask(cpf));
        }
    }, [cpf]);

    // Controle PIN
    useEffect(() => {
        if (pinCode.length === 6) {
            handleVerifyPinCode(cpf, pinCode, userId);
        }
    }, [pinCode]);

    useEffect(() => {
        const dadosUsuario = JSON.parse(localStorage.getItem('userInfos'));
        setUserSector(dadosUsuario.usuario.setor);
        const statusPin = JSON.parse(localStorage.getItem('pinCodeStatus'));
        if (dadosUsuario.usuario.setor != 'médico') {
            setNeedPin(true);
        }
        if (!statusPin) {
            setNeedPin(true);
        }
        setError(false);
        setCompleted(false);
        return () => {
            refCode?.current?.forEach((input) => (input.value = ''));
            setCompleted(false);
        };
    }, []);

    return (
        <Modal
            open={open}
            // onClose={() => {
            //     setCpf('');
            //     setNeedPin(false);
            //     setShowPin(false);
            //     setOpen(false);
            // }}
        >
            <Box sx={style}>
                <h3 style={{ textAlign: 'center' }}>{!loading ? 'Verifique o código PIN no seu aplicativo BirdID' : 'Aguarde...'}</h3>
                <Divider />

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '20px 0'
                    }}
                >
                    {(!showPin && needPin) || (userSector != 'médico' && !showPin && needPin) ? (
                        <ReactInputMask mask="999.999.999-99" value={cpf} onChange={(e) => setCpf(e.target.value)}>
                            {(inputProps) => <TextField autoComplete="off" label="CPF" inputProps={inputProps} />}
                        </ReactInputMask>
                    ) : null}

                    {showPin && needPin && (
                        <PinField
                            className="pin-field"
                            validate="0123456789"
                            inputMode="numeric"
                            length={6}
                            ref={refCode}
                            onChange={(value) => {
                                setPinCode(value);
                                setError(false);
                            }}
                            disabled={completed || loading}
                        />
                    )}
                </Box>
                <Box sx={{ display: 'flex', gap: '.3rem', marginTop: '2rem', justifyContent: 'center' }}>
                    <Typography>Caso seu certificado não seja Soluti, clique </Typography>
                    <Link
                        href="https://certifierauthhub.vaultid.com.br/?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiNjc0NzU4ZmNmYzBiNDVlMzI1ODczOTdmIiwiY2xpZW50X2lkIjoiNDU4NjQ4ODMwMDAxMTYiLCJjbG91ZF9lbnZpcm9ubWVudCI6ImJpcmQiLCJzY29wZSI6ImFjY2Vzc19zZXNzaW9uIiwiaWF0IjoxNzMyNzI5MDg0LCJleHAiOjE3NjQyNjUwODQsImlzcyI6ImNlcnRpZmllci1hdXRoLWh1YiJ9.WT5P5-XEqw2BsHp1tRCITqrYIQ-ma_WdftsT_TYEMZajDXdAlI2wbU8MI4nygVuPSmiMW4y6-6GZXQ4ZEFGnXbvAXsm03WKFHFc8JgsCjo5UIwcELgKw8thDEfpbjMZXqYS8B5xf-UHNOUWoh8iBI5NoC5RpUn8trDF2QuPllvjwzwKQSP-q09K4is_1_7yxCYkaoHk7Xh89HV74Qcdt_em0dwMKHwyM9QPoEaB0YKV8rZwviIIHlP1QkPFmN-ftOw2UEf0Pb0D8Ts4ODRLtkLrJw50osAo0EZi3NmjUs7Ho5wYFToq0cpqhRP9Vedy_E9pClg3qjcNhfwBkm1ptlw"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        AQUI
                    </Link>
                </Box>
                <Box sx={{ display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
                    <Button variant="outlined" color="error" onClick={() => window.location.reload()}>
                        Cancelar
                    </Button>
                </Box>
                {error && (
                    <Typography color="error" sx={{ textAlign: 'center' }}>
                        Código PIN incorreto. Tente novamente.
                    </Typography>
                )}
            </Box>
        </Modal>
    );
};
