import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    OutlinedInput,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { IconArchive, IconArticle, IconEdit, IconTrash } from '@tabler/icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { MonitoringReportModal } from './components/MonitoringReportModal';
import { useNavigate } from 'react-router';
import TempoMedio from 'components/Triagem/Cards/TempoMedio';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import ReactInputMask from 'react-input-mask';
import { removeMask } from 'utils/removeMask';
import { toast } from 'react-toastify';

export function NursingService() {
    const api = useApi();
    const [NurseService, setNurseService] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openConfirmIdentity, setOpenConfirmIdentity] = useState(false);
    const [cpf, setCpf] = useState('');
    const [activeUserId, setActiveUserId] = useState(null);
    const [checked, setChecked] = useState(false);
    const [averageWaitTime, setAverageWaitTime] = useState('');
    const [waitTime, setWaitTime] = useState('');
    const navigate = useNavigate();

    const columns = [
        { field: 'prontuario_id', headerName: 'F.A', flex: 0.5 },
        { field: 'name', headerName: 'PACIENTE', flex: 1 },
        { field: 'age', headerName: 'IDADE', flex: 0.3 },
        { field: 'data', headerName: 'DATA', flex: 0.5 },
        { field: 'hora', headerName: 'HORA', flex: 0.5 },
        { field: 'status', headerName: 'STATUS', flex: 0.5 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.3,
            renderCell: (params) => (
                <>
                    <Tooltip title="Acompanhamento">
                        <IconButton
                            onClick={() => {
                                setSelectedRow(params.row);
                                setOpenConfirmIdentity(true);
                            }}
                        >
                            <IconArticle />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Visualizar geral">
                        <IconButton onClick={() => navigate(`/main/enfermagem/geral/${params.row.prontuario_id}`)}>
                            <IconArchive />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    function handleChandeChecked() {
        setChecked(!checked);
    }

    async function getUserBycpf(cpf) {
        try {
            const { data } = await api.getUserByCpf(cpf);
            if (!data?.id) return toast.error('Cpf informado não encontrado nos registros do sistema');
            setActiveUserId(data?.id);
            setOpenConfirmIdentity(false);
            setOpenModal(true);
            return data?.id;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getAllNurse() {
        try {
            const { data } = await api.getAllNurse();
            console.log('🚀 ~ getAllNurse ~ data:', data);
            const rows = data.map((item) => ({
                ...item,
                // id: item?.conduta_medica?.prontuario?.paciente?.id,
                age: item?.conduta_medica?.prontuario?.paciente?.idade,
                name: item?.conduta_medica?.prontuario?.paciente?.nome_completo,
                data: item.dia,
                hora: item.hora,
                status: translateStatus(item.conduta_medica?.prontuario?.status),
                prontuario_id: item?.prontuario_id,
                createdAt: item?.createdAt
            }));

            // Ordenando pela data e autismo
            const sortedRows = rows
                .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                .sort(
                    (a, b) =>
                        parseInt(b.conduta_medica?.prontuario?.paciente?.autismo) -
                        parseInt(a.conduta_medica?.prontuario?.paciente?.autismo)
                );

            setNurseService(sortedRows);
        } catch (error) {
            console.log('🚀 ~ getAllNurse ~ error:', error);
        }
    }

    function translateStatus(status) {
        switch (status) {
            case 'MEDICACAO':
                return 'RETORNO';
            default:
                return status;
        }
    }

    useEffect(() => {
        getAllNurse();
    }, []);

    useEffect(() => {
        if (NurseService.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(NurseService[0]?.createdAt);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [NurseService]);

    useEffect(() => {
        if (NurseService.length > 0) {
            const calculateAverageWaitTime = () => {
                const currentDate = new Date();
                const totalWaitTimeInSeconds = NurseService.reduce((accumulator, record) => {
                    const recordDate = new Date(record.createdAt);
                    const timeDifference = currentDate.getTime() - recordDate.getTime();
                    return accumulator + Math.floor(timeDifference / 1000);
                }, 0);

                const averageWaitTimeInSeconds = totalWaitTimeInSeconds / NurseService.length;
                const hours = Math.floor(averageWaitTimeInSeconds / 3600);
                const minutes = Math.floor((averageWaitTimeInSeconds % 3600) / 60);
                const seconds = Math.floor(averageWaitTimeInSeconds % 60);

                setAverageWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateAverageWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [NurseService]);

    return (
        <MainCard title="ENFERMAGEM" sx={{ height: '100%' }}>
            <Grid spacing={gridSpacing} sx={{ position: 'relative', height: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        position: 'absolute',
                        top: '-93px',
                        right: 0
                    }}
                >
                    <TempoMedio tempoMedio={averageWaitTime} />
                    <TempoEspera tempoEspera={waitTime} />
                    <Aguardando aguardandoQTD={NurseService?.length} />
                </div>

                <MonitoringReportModal open={openModal} setOpen={setOpenModal} patient={selectedRow} activeUserId={activeUserId} />

                <Box sx={{ width: '100%', height: '100%' }}>
                    <Box sx={{ display: 'flex', gap: '.4rem', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Typography>Interno</Typography>
                        <Switch checked={checked} onChange={handleChandeChecked} inputProps={{ 'aria-label': 'controlled' }} />
                    </Box>

                    {checked ? (
                        <DataGrid
                            sx={{ width: '100%', height: '100%', maxHeight: 'calc(100vh - 200px)' }}
                            rows={NurseService.filter((item) => item?.conduta_medica?.prontuario?.interno === 1)}
                            columns={columns}
                        />
                    ) : (
                        <DataGrid
                            sx={{ width: '100%', height: '100%', maxHeight: 'calc(100vh - 200px)' }}
                            rows={NurseService}
                            columns={columns}
                        />
                    )}
                </Box>

                <Dialog open={openConfirmIdentity}>
                    <DialogTitle>Confirmação de identidade</DialogTitle>
                    <DialogContent>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Typography variant="h4">Informe seu CPF para confirmar sua identidade</Typography>
                            <ReactInputMask mask="999.999.999-99" value={cpf} onChange={(e) => setCpf(e.target.value)}>
                                {(inputProps) => (
                                    <OutlinedInput
                                        autoComplete="off"
                                        id="outlined-adornment-cpf-register"
                                        type="text"
                                        name="cpf"
                                        inputProps={inputProps}
                                    />
                                )}
                            </ReactInputMask>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    setCpf('');
                                    setOpenConfirmIdentity(false);
                                }}
                            >
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    getUserBycpf(removeMask(cpf));
                                }}
                            >
                                Confirmar
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </Grid>
        </MainCard>
    );
}
