import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    OutlinedInput,
    Switch,
    Tab,
    Tooltip,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import { ArchiveOutlined, Check } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { IconArticle, IconClock } from '@tabler/icons';
import Laco from '../../../assets/images/Logos/laco.jpg';
import { Box } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { MonitoringReportModal } from './components/MonitoringReportModal';
import ReactInputMask from 'react-input-mask';
import { removeMask } from 'utils/removeMask';

export function MaintenanceMedicalAppointment() {
    const api = useApi();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [rowsRetorno, setRowsRetorno] = useState([]);
    const [defaultRows, setDefaultRows] = useState([]);
    const [checked, setChecked] = useState(false);
    const [closedChips, setClosedChips] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openConfirmIdentity, setOpenConfirmIdentity] = useState(false);
    const [activeUserId, setActiveUserId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [cpf, setCpf] = useState('');

    //tab
    const [value, setValue] = useState('1');
    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const columns = [
        { field: 'ficha', headerName: 'F.A', flex: 0.5 },
        {
            field: 'paciente',
            headerName: 'PACIENTE',
            flex: 1.5,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {params.row.paciente}
                    {params.row.autismo && <img src={Laco} alt="Laco" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />}
                </div>
            )
        },
        { field: 'idade', headerName: 'IDADE', flex: 0.5 },
        { field: 'data', headerName: 'DATA', flex: 0.5 },
        { field: 'hora', headerName: 'HORA', flex: 0.5 },
        { field: 'status', headerName: 'STATUS', flex: 0.5 },
        { field: 'sala', headerName: 'SALA', flex: 0.5 },
        {
            field: 'enfermagem',
            headerName: 'ENFERMAGEM',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    {params.row.statusMedicacao === 'APLICADO' ? (
                        <Check />
                    ) : params.row.statusMedicacao === 'SEPARACAO' || params.row.statusMedicacao === 'PARCIAL' ? (
                        <IconClock />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'lab',
            headerName: 'EXAMES',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    {params.row.statusLaboratorio === 'COLETA' || params.row.statusLaboratorio === 'PARCIAL' ? (
                        <IconClock />
                    ) : params.row.statusLaboratorio === 'REALIZADO' ? (
                        <Check />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'raiox',
            headerName: 'RAIO-X',
            flex: 0.5,
            renderCell: (params) => <>{params.row.statusRaiox == null ? '-' : params.row.statusRaiox ? <Check /> : <IconClock />} </>
        },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Acompanhamento">
                            <IconButton
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    setOpenConfirmIdentity(true);
                                }}
                            >
                                <IconArticle />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Atendimento">
                            <IconButton onClick={() => handleNavigateMedicalRecord(params.row)}>
                                <ArchiveOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    const columnsRetorno = [
        { field: 'ficha', headerName: 'F.A', flex: 0.5 },
        {
            field: 'paciente',
            headerName: 'PACIENTE',
            flex: 1.5,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {params.row.paciente}
                    {params.row.autismo && <img src={Laco} alt="Laco" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />}
                </div>
            )
        },
        { field: 'idade', headerName: 'IDADE', flex: 0.5 },
        { field: 'data', headerName: 'DATA', flex: 0.5 },
        { field: 'hora', headerName: 'HORA', flex: 0.5 },
        { field: 'status', headerName: 'STATUS', flex: 0.5 },
        { field: 'sala', headerName: 'SALA', flex: 0.5 },
        {
            field: 'enfermagem',
            headerName: 'MEDICAÇÃO',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    {params.row.statusMedicacao === 'APLICADO' ? (
                        <Check />
                    ) : params.row.statusMedicacao === 'SEPARACAO' || params.row.statusMedicacao === 'PARCIAL' ? (
                        <IconClock />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'lab',
            headerName: 'EXAMES',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    {params.row.statusLaboratorio === 'COLETA' || params.row.statusLaboratorio === 'PARCIAL' ? (
                        <IconClock />
                    ) : params.row.statusLaboratorio === 'REALIZADO' ? (
                        <Check />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'raiox',
            headerName: 'RAIO-X',
            flex: 0.5,
            renderCell: (params) => <>{params.row.statusRaiox == null ? '-' : params.row.statusRaiox ? <Check /> : <IconClock />} </>
        },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Acompanhamento">
                            <IconButton
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    setOpenConfirmIdentity(true);
                                }}
                            >
                                <IconArticle />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Ficha">
                            <IconButton onClick={() => handleNavigateMedicalRecord(params.row)}>
                                <ArchiveOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    const tab = (event) => {
        setChecked(event.target.checked);
    };

    async function getTable() {
        try {
            const { data } = await api.getPatientsByClinic();

            const row = data
                .filter((element) => element.status == 'CONSULTORIO' || element.status == 'TRIAGEM')
                .map((item) => ({
                    ...item,
                    ficha: item?.id ?? '-',
                    fa: item?.paciente?.id ?? '-',
                    idade: item?.paciente?.idade ?? '-',
                    data: item?.data ?? '-',
                    data_atendimento: item?.data_atendimento ?? '-',
                    hora: item?.hora_atendimento ? item.hora_atendimento.slice(0, 5) : '-',
                    status: item?.status ?? '-',
                    paciente: item?.paciente?.nome_completo ?? '-',
                    sala: item?.sala ?? '-',
                    enfermagem: ' - ',
                    lab:
                        item?.todasCondutasFinalizadasPorTipo?.laboratorio !== undefined
                            ? item.todasCondutasFinalizadasPorTipo.laboratorio
                                ? 'Finalizado'
                                : 'Pendente'
                            : '-',
                    raiox:
                        item?.todasCondutasFinalizadasPorTipo?.raiox !== undefined
                            ? item.todasCondutasFinalizadasPorTipo.raiox
                                ? 'Finalizado'
                                : 'Pendente'
                            : '-',
                    autismo: item?.paciente?.autismo ?? '-'
                }));

            const rowRetorno = data
                .filter((element) => element.status == 'MEDICACAO')
                .map((item) => ({
                    ...item,
                    ficha: item?.id ?? '-',
                    fa: item?.paciente?.id ?? '-',
                    idade: item?.paciente?.idade ?? '-',
                    data: item?.data ?? '-',
                    data_atendimento: item?.data_atendimento ?? '-',
                    hora: item?.hora_atendimento ? item.hora_atendimento.slice(0, 5) : '-',
                    status: item?.status ?? '-',
                    paciente: item?.paciente?.nome_completo ?? '-',
                    sala: item?.sala ?? '-',
                    enfermagem: ' - ',
                    lab:
                        item?.todasCondutasFinalizadasPorTipo?.laboratorio !== undefined
                            ? item.todasCondutasFinalizadasPorTipo.laboratorio
                                ? 'Finalizado'
                                : 'Pendente'
                            : '-',
                    raiox:
                        item?.todasCondutasFinalizadasPorTipo?.raiox !== undefined
                            ? item.todasCondutasFinalizadasPorTipo.raiox
                                ? 'Finalizado'
                                : 'Pendente'
                            : '-',
                    autismo: item?.paciente?.autismo ?? '-'
                }));

            setRows(row);
            setRowsRetorno(rowRetorno);
            setDefaultRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    function handleNavigateMedicalRecord(row) {
        // navigate(`/main/manutencao-ficha/${row.ficha}/${row.fa}/${row.paciente}`);
        navigate(`/main/consultorio/manutencao/atendimento/${row.ficha}/${row.fa}/${row.paciente}`);
    }

    useEffect(() => {
        const getData = () => {
            getTable();
        };
        getData();
        const interval = setInterval(getData, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (checked) {
            setRows(defaultRows.filter((i) => i?.interno));
        } else {
            setRows(defaultRows.filter((i) => !i?.interno));
        }
    }, [checked]);

    // reabertura de fichas

    async function getClosedChips() {
        try {
            const { data } = await api.closedChips();
            console.log('🚀 ~ getClosedChips ~ data:', data);

            const row = data.map((item) => ({
                ...item,
                ficha: item?.id ?? '-',
                fa: item?.paciente?.id ?? '-',
                idade: item?.paciente?.idade ?? '-',
                data: item?.data ?? '-',
                data_atendimento: item?.data_atendimento ?? '-',
                hora: item?.hora_atendimento ? item.hora_atendimento.slice(0, 5) : '-',
                status: item?.status ?? '-',
                paciente: item?.paciente?.nome_completo ?? '-',
                sala: item?.sala ?? '-',
                enfermagem: ' - ',
                lab:
                    item?.todasCondutasFinalizadasPorTipo?.laboratorio !== undefined
                        ? item.todasCondutasFinalizadasPorTipo.laboratorio
                            ? 'Finalizado'
                            : 'Pendente'
                        : '-',
                raiox:
                    item?.todasCondutasFinalizadasPorTipo?.raiox !== undefined
                        ? item.todasCondutasFinalizadasPorTipo.raiox
                            ? 'Finalizado'
                            : 'Pendente'
                        : '-',
                autismo: item?.paciente?.autismo ?? '-'
            }));

            setClosedChips(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        getClosedChips();
    }, []);

    // relatorio de acompanhamento
    async function getUserBycpf(cpf) {
        try {
            const { data } = await api.getUserByCpf(cpf);
            if (!data?.id) return toast.error('Cpf informado não encontrado nos registros do sistema');
            setActiveUserId(data?.id);
            setOpenConfirmIdentity(false);
            setOpenModal(true);
            return data?.id;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    return (
        <MainCard title="Manutencao de fichas" sx={{ position: 'relative' }}>
            <MonitoringReportModal open={openModal} setOpen={setOpenModal} patient={selectedRow} activeUserId={activeUserId} />
            <Dialog open={openConfirmIdentity}>
                <DialogTitle>Confirmação de identidade</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Typography variant="h4">Informe seu CPF para confirmar sua identidade</Typography>
                        <ReactInputMask mask="999.999.999-99" value={cpf} onChange={(e) => setCpf(e.target.value)}>
                            {(inputProps) => (
                                <OutlinedInput
                                    autoComplete="off"
                                    id="outlined-adornment-cpf-register"
                                    type="text"
                                    name="cpf"
                                    inputProps={inputProps}
                                />
                            )}
                        </ReactInputMask>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setCpf('');
                                setOpenConfirmIdentity(false);
                            }}
                        >
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                getUserBycpf(removeMask(cpf));
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Grid spacing={gridSpacing}>
                <Box sx={{ position: 'absolute', top: 20, right: 20, display: 'flex', alignItems: 'center' }}>
                    <Typography>Interno</Typography>
                    <Switch checked={checked} onChange={tab} inputProps={{ 'aria-label': 'controlled' }} />
                </Box>

                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                            <Tab label="FICHAS ABERTAS ESCUTA INICIAL" value="1" />
                            <Tab label="FICHAS ABERTAS RETORNO" value="2" />
                            <Tab label="FICHAS FECHADAS" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        {rows.length > 0 ? (
                            <DataGrid rows={rows} columns={columns} sx={{ height: 'calc(100vh - 400px)' }} disableSelectionOnClick />
                        ) : (
                            <p>Nenhuma ficha aberta</p>
                        )}
                    </TabPanel>
                    <TabPanel value="2" sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        {rowsRetorno.length > 0 ? (
                            <DataGrid
                                rows={rowsRetorno}
                                columns={columnsRetorno}
                                sx={{ height: 'calc(100vh - 350px)' }}
                                pageSize={5}
                                disableSelectionOnClick
                            />
                        ) : (
                            <p>Nenhuma ficha de retorno</p>
                        )}
                    </TabPanel>
                    <TabPanel value="3">
                        {closedChips.length > 0 ? (
                            <DataGrid
                                sx={{ marginTop: '-3rem', height: 'calc(100vh - 350px)' }}
                                rows={closedChips}
                                columns={columns}
                                disableSelectionOnClick
                            />
                        ) : (
                            <p>Nenhuma ficha fechada</p>
                        )}
                    </TabPanel>
                </TabContext>
            </Grid>
        </MainCard>
    );
}
