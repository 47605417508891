import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { formatarData } from 'utils/formatarData';
import { IconEye } from '@tabler/icons';
import { pdf } from '@react-pdf/renderer';
import ConjuntoLaudosPDF from 'views/Relatorios/PDF/ConjuntoLaudos';
import { PinCodeModal } from 'components/PinCodeModal';
import { IconFileText } from '@tabler/icons-react';
import { removeMask } from 'utils/removeMask';
import { LoadBw } from 'components/LoadBw';
import { ModalForViewingResults } from '../CustomerService/components/ModalForViewingResults';

export function WriteOffExamsLabWithoutSignature() {
    const { id, nome } = useParams();
    const api = useApi();
    const [rows, setRows] = useState([]);
    const [rowsEspecial, setRowsEspecial] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    // certificar laudo
    const [openVerifyPinCode, setOpenVerifyPinCode] = useState(false);
    const pinCodeIsActive = JSON.parse(localStorage.getItem('pinCodeStatus'));
    const [setorUsuario, setSetorUsuario] = useState(null);
    const [loadingBw, setLoadingBw] = useState(false);
    //modal de resultados
    const [openModalResultados, setOpenModalResultados] = useState(false);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'exame', headerName: 'EXAME', flex: 1 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.4,
            renderCell: (params) => (
                <>
                    <Tooltip title="Certificar Laudo">
                        <IconButton
                            onClick={() => {
                                setCurrentRow(params.row);
                                handleClickCertificate();
                            }}
                        >
                            <IconFileText />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Visualizar Resultados">
                        <IconButton onClick={() => showResult(params.row)}>
                            <IconEye />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    function showResult(row) {
        if (row?.conduta_medica?.exame_prontuario?.exames_especiai) {
            window.open(
                `/laboratorio/resultado-especial/${row?.prontuario_id}/${row?.conduta_medica?.exame_prontuario?.exames_especiai?.id}/${row?.conduta_medica?.exame_prontuario?.id}`
            );
        } else {
            setCurrentRow(params.row);
            setOpenModalResultados(true);
        }
    }

    async function getAllItens(id) {
        try {
            const { data } = await api.getAllPendindExamsLabByProntuarioIdWithoutSignature(id);
            console.log('🚀 ~ getAllItens ~ data:', data);

            const normalExams = data.filter((item) => item?.conduta_medica?.exame_prontuario?.exame);
            const especialExams = data.filter((item) => item?.conduta_medica?.exame_prontuario?.exames_especiai);

            const row = normalExams.map((item) => ({
                ...item,
                id: item?.id,
                prontuarioId: item?.prontuario?.id,
                pacienteId: item?.prontuario?.paciente?.id,
                exame: item?.conduta_medica?.exame_prontuario?.exame?.nome,
                exameId: item?.conduta_medica?.exame_prontuario?.exame?.id
            }));

            const rowEspecial = especialExams.map((item) => ({
                ...item,
                id: item?.id,
                prontuarioId: item?.prontuario?.id,
                pacienteId: item?.prontuario?.paciente?.id,
                exame: item?.conduta_medica?.exame_prontuario?.exames_especiai?.nome,
                exameId: item?.conduta_medica?.exame_prontuario?.exames_especiai?.id
            }));
            console.log(especialExams);

            setRows(row);
            setRowsEspecial(rowEspecial);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getLaudoData(payload) {
        try {
            const response = await api.getResultsForLaudos(payload);
            const agruparDados = response?.data.map((el) => {
                const resultadoCampoEspecifico = el.resultado_campo_especifico;
                const data = formatarData(el?.medico?.data_execucao);

                // agrupar os campos por categoria
                const camposAgrupados = resultadoCampoEspecifico.reduce((agrupados, item) => {
                    const categoria = item.campos_especifico.categoria;

                    // Se a categoria já existir no objeto agrupados, adicione o item a ela
                    if (agrupados[categoria]) {
                        agrupados[categoria].push(item);
                    } else {
                        // Se a categoria ainda não existir, crie um novo array com o item
                        agrupados[categoria] = [item];
                    }

                    return agrupados;
                }, {});
                for (const categoria in camposAgrupados) {
                    camposAgrupados[categoria].sort((a, b) => a.id - b.id);
                }
                return {
                    ...el,
                    data,
                    camposAgrupados
                };
            });

            const ordenarExames = (a, b) => {
                const aNome = a?.exame?.nome?.toLowerCase();
                const bNome = b?.exame?.nome?.toLowerCase();

                if (aNome?.includes('hemograma') || aNome?.includes('urina')) {
                    return -1;
                } else if (bNome?.includes('hemograma') || bNome?.includes('urina')) {
                    return 1;
                }

                return 0;
            };

            response?.data?.sort(ordenarExames);

            const parsedData = payload?.map(async (item) => {
                let observacao = await api.getObservation(
                    item.prontuarioId,
                    item.especial ? null : item.exameId,
                    item.especial ? item.exameEspecialId : null,
                    item.idExameProntuario
                );
                return observacao;
            });

            agruparDados.map((item) => ({ ...item, parsedData }));
            agruparDados.map((item) => ({ ...item, parsedData }));
            toast.success('Laudos carregados com sucesso');
            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleClickCertificate() {
        // Caso não seja médico, sempre vai pedir o CPF
        if (setorUsuario !== 'médico' || !pinCodeIsActive) {
            setOpenVerifyPinCode(true);
        } else {
            await handleConfirmPinCode();
        }
    }

    async function handleConfirmPinCode() {
        setOpenVerifyPinCode(false);
        certificateExam();
    }

    async function generateLabPdfBlob(laudoPayload) {
        try {
            const dataToPdf = await getLaudoData(laudoPayload);
            const document = <ConjuntoLaudosPDF data={dataToPdf} />;
            const asPdf = pdf(document);
            const pdfBlob = await asPdf.toBlob();
            // Iniciar transação no CESS
            const payload = {
                identificacao: removeMask(String(localStorage.getItem('userIdentity'))),
                paciente: nome,
                tipo: 'Laudo',
                exame_prontuario_id: laudoPayload[0]?.idExameProntuario,
                prontuario_id: id
            };

            const responseCess = await api.initTransactionCess(payload);
            // Enviar o arquivo para assinatura
            if (responseCess.status == 200 && responseCess.data) {
                const tcn = responseCess.data.tcn;
                localStorage.setItem('tcn', tcn);
                const formData = new FormData();
                formData.append('pdf', pdfBlob);
                formData.append('transactionTcn', tcn);
                const responseUpload = await api.uploadPdfCess(formData);
                if (responseUpload.status == 200) {
                    localStorage.removeItem('tcn');
                    const userInfo = JSON.parse(localStorage.getItem('userInfos'));
                    if (userInfo.usuario.setor != 'médico') {
                        localStorage.removeItem('userIdentity');
                    }
                    setOpenVerifyPinCode(false);
                    await api.certificarLaudo(currentRow?.conduta_medica?.exame_prontuario?.id);
                }
            }
            window.location.reload();
        } catch (error) {
            toast.error(`Erro ao gerar certificação de laudo: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    async function certificateExam() {
        setLoadingBw(true);

        try {
            localStorage.setItem('option', 'laudo');

            const isEspecial = currentRow?.conduta_medica?.exame_prontuario?.exame?.id ? false : true;
            console.log('🚀 ~ certificateExam ~ currentRow:', currentRow);

            const payload = [
                {
                    idExameProntuario: currentRow?.conduta_medica?.exame_prontuario?.id,
                    exameId: isEspecial ? null : currentRow?.conduta_medica?.exame_prontuario.exame?.id,
                    exameEspecialId: isEspecial ? currentRow?.conduta_medica?.exame_prontuario.exames_especiai?.id : null,
                    prontuarioId: id,
                    especial: isEspecial
                }
            ];

            await generateLabPdfBlob(payload);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        const dadosUsuario = JSON.parse(localStorage.getItem('userInfos'));
        setSetorUsuario(dadosUsuario.usuario.setor);
    }, []);

    useEffect(() => {
        getAllItens(id);
    }, []);

    return (
        <MainCard title={`FA ${id} - ${nome}`}>
            <ModalForViewingResults open={openModalResultados} setOpen={setOpenModalResultados} currentRow={currentRow} />
            <LoadBw loading={loadingBw} />
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <PinCodeModal open={openVerifyPinCode} setOpen={setOpenVerifyPinCode} confirmPin={handleConfirmPinCode} />
                <Box sx={{ width: '100%' }}>
                    {rows?.length > 0 && (
                        <>
                            <h3 style={{ textAlign: 'center' }}>EXAMES NORMAIS</h3>
                            <DataGrid rows={rows} columns={columns} hideFooter={true} />
                        </>
                    )}
                </Box>
                <Box sx={{ width: '100%' }}>
                    {rowsEspecial?.length > 0 && (
                        <>
                            <h3 style={{ textAlign: 'center' }}>EXAMES ESPECIAIS</h3>
                            <DataGrid rows={rowsEspecial} columns={columns} hideFooter={true} />
                        </>
                    )}
                </Box>
            </Grid>
        </MainCard>
    );
}
