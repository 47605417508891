import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReportPdf from './ReportPdf';

//pdf viewer
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import { pdf } from '@react-pdf/renderer';
import { useState } from 'react';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: '90%'
};

export default function ModalPdf({ open, setOpen, data, name, id }) {
    // PDF MODAL VIEWER PLUGINS
    const getFilePluginInstance = getFilePlugin();
    const { DownloadButton } = getFilePluginInstance;
    const printPluginInstance = printPlugin();
    const { PrintButton } = printPluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover, CurrentScale } = zoomPluginInstance;
    const [pdfBlob, setPdfBlob] = useState(null);

    // blod

    const [pdfBlobUrl, setPdfBlobUrl] = useState(null);

    useEffect(() => {
        const generateBlob = async () => {
            const asPdf = pdf(<ReportPdf data={data} name={name} id={id} />);
            const blob = await asPdf.toBlob();
            const blobUrl = URL.createObjectURL(blob);
            setPdfBlobUrl(blobUrl);
        };

        generateBlob();
    }, [data]);

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={{ ...style, width: '80vw', height: '80vh' }}>
                {pdfBlobUrl && (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '1rem',
                                alignItems: 'center',
                                backgroundColor: '#eeeeee',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
                                marginTop: '-1rem'
                            }}
                        >
                            <DownloadButton />
                            <PrintButton />
                            <ZoomOutButton />
                            <ZoomPopover />
                            <ZoomInButton />
                        </Box>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                            <Viewer
                                defaultScale={1.5}
                                fileUrl={pdfBlobUrl}
                                plugins={[getFilePluginInstance, printPluginInstance, zoomPluginInstance]}
                            />
                        </Worker>
                    </>
                )}
            </Box>
        </Modal>
    );
}
