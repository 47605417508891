import { Box, Button, Grid } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { DateFilterComponent } from '../components/DateFilterComponent';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';

export function ArqConsolidado() {
    const api = useApi();
    const [procedimentosAgrupados, setProcedimentosAgrupados] = useState([]);
    const [payload, setPayload] = useState([]);
    const [idsFaturamentos, setIdsFaturamentos] = useState([]);
    const [dateFilter, setDateFilter] = useState({
        from: null,
        to: null
    });
    const columns = [
        { field: 'codigo', headerName: 'CÓDIGO', flex: 1 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'tipo', headerName: 'TIPO', flex: 1 },
        { field: 'quantidade', headerName: 'QUANTIDADE', flex: 0.5 }
    ];

    async function handleDateFilter() {
        if (!dateFilter.from) return toast.error('Por favor, selecione uma data');
        const payload = {
            dataInicial: dateFilter.from
        };

        const toastId = toast.loading('Buscando faturamentos...');

        try {
            const { data } = await api.getConsolidadaArquivadas(payload);
            setIdsFaturamentos(data.faturamentos_listados);
            console.log('🚀 ~ handleDateFilter ~ data:', data);
            const row = data.procedimentosAgrupados.map((item) => ({
                ...item,
                id: v4()
            }));
            setPayload(data);
            setProcedimentosAgrupados(row);
            toast.update(toastId, {
                render: 'Faturamentos encontrados com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            toast.update(toastId, {
                render: 'Nenhum faturamento encontrado',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
            console.log('🚀 ~ handleDateFilter ~ error:', error);
        }
    }

    async function handleSaveAndDownloadTxt() {
        const toastId = toast.loading('Gerando arquivo TXT...');

        try {
            // Marcar faturamentos como TXT_GERADO
            const { status } = await api.marcarTxtGerado({ faturamentos_listados: idsFaturamentos });

            if (status == 200) {
                const { data } = await api.generateTxtConsolidado(payload);
                const blob = new Blob([data], { type: 'text/plain' });
                const url = URL.createObjectURL(blob);

                // Criar um link temporário e disparar o download
                const a = document.createElement('a');
                a.href = url;
                a.download = 'faturamento-consolidado.txt';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                toast.update(toastId, {
                    render: 'Arquivo TXT gerado com sucesso',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                });
                // Revogar a URL após o download para evitar vazamento de memória
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log('🚀 ~ handleSaveAndDownloadTxt ~ error:', error);
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    return (
        <MainCard title="Faturamento Consolidado" sx={{ position: 'relative' }}>
            <Grid container spacing={2}>
                <Box sx={{ position: 'absolute', top: 18, right: 20, display: 'flex', gap: 20, alignItems: 'center' }}>
                    <DateFilterComponent
                        dateFilter={dateFilter}
                        setDateFilter={setDateFilter}
                        handleDateFilter={handleDateFilter}
                        typeViews={['month', 'year']}
                        singleInput={true}
                    />
                    <Button
                        variant="contained"
                        onClick={handleSaveAndDownloadTxt}
                        sx={{ width: '200px' }}
                        disabled={procedimentosAgrupados.length === 0}
                    >
                        Gravar
                    </Button>
                </Box>
                <Box sx={{ width: '100%' }}>
                    {procedimentosAgrupados?.length > 0 ? (
                        <DataGrid
                            sx={{ minHeight: '100%', maxHeight: 'calc(100vh - 250px)' }}
                            rows={procedimentosAgrupados}
                            columns={columns}
                        />
                    ) : (
                        <p>Nenhum registro encontrado</p>
                    )}
                </Box>
            </Grid>
        </MainCard>
    );
}
