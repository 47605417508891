import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    Typography,
    MenuItem
} from '@mui/material';
import { toast } from 'react-toastify';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

import InputMask from 'react-input-mask';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Context } from 'context/UserContext';

// components
import { FormBox } from './style';
import { useContext } from 'react';

const UserRegisterForm = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [setorMedico, setSetorMedico] = useState(false);
    const [strength, setStrength] = useState(0);
    const [etapa, setEtapa] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [level, setLevel] = useState();

    const { functions } = useContext(Context);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        changePassword('123456');
    }, []);

    // SUBMIT
    const handleRegister = async (values) => {
        // campos de médico para validação
        const fields = [
            'nome_completo',
            'data_nascimento',
            'local',
            'crm_coren',
            'filiacao',
            'rg',
            'data_expedicao',
            'endereco_completo',
            'bairro',
            'cep',
            'especialidade',
            'carga_horaria_semanal',
            'filhos',
            'casado'
        ];

        // monta um objeto apenas com valores !== de ""
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        // objeto para caso seja médico
        const medicDetails = {};

        // pega os valores que precisar ser Num e faz o parse / monta o medic details caso tenha valores
        fields.forEach((field) => {
            if (filteredValues[field]) {
                if (field === 'carga_horaria_semanal' || field === 'filhos' || field === 'casado') {
                    medicDetails[field] = Number(filteredValues[field]);
                } else {
                    medicDetails[field] = filteredValues[field];
                }
                delete filteredValues[field];
            }
        });

        // caso tenha o medic details passa ele para o objeto principal
        if (Object.keys(medicDetails).length > 0) {
            filteredValues.medicDetails = medicDetails;
        }

        // caso o setor não seja médico, tira esses campos do objeto
        if (filteredValues.setor !== 'médico') {
            delete filteredValues.filhos;
            delete filteredValues.casado;
        } else {
            delete filteredValues.filhos;
            delete filteredValues.casado;
            medicDetails.filhos = Number(values.filhos);
            medicDetails.casado = values.casado;
        }

        // monta o payload final
        const user = filteredValues;

        try {
            await functions.registerUser(user);
            // window.location.reload();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    const checkSetor = (e, values) => {
        const { value } = e.target;
        if (value === 'médico') {
            setSetorMedico(true);
        } else {
            setSetorMedico(false);
            values.nome_completo = '';
            values.data_nascimento = '';
            values.local = '';
            values.crm_coren = '';
            values.filiacao = '';
            values.rg = '';
            values.data_expedicao = '';
            values.endereco_completo = '';
            values.bairro = '';
            values.cep = '';
            values.carga_horaria_semanal = '';
            values.especialidade = '';
            values.casado = false;
            values.filhos = 0;
        }
    };

    const removeMask = (value) => {
        return value.replace(/[^\d]/g, '');
    };

    // verifica se os campos não foram preenchidos
    function isAnyFieldEmpty(values) {
        const fieldsToCheck = ['nome', 'cpf', 'email', 'setor', 'telefone', 'role', 'password', 'cbo', 'cns'];

        for (const field of fieldsToCheck) {
            if (values[field]?.trim() === '') {
                return true;
            }
        }

        return false;
    }

    function isAnyMedicFieldEmpty(values) {
        const fields = ['crm_coren'];

        for (const field of fields) {
            if (values[field]?.trim() === '') {
                return true;
            }
        }

        return false;
    }

    const handleBack = () => {
        return setEtapa((prevEtapa) => prevEtapa - 1);
    };

    return (
        <Formik
            initialValues={{
                nome: '',
                email: '',
                setor: '',
                telefone: '',
                role: '',
                password: '',
                cbo: '',
                cns: '',
                nome_completo: '',
                data_nascimento: '',
                local: '',
                cpf: '',
                crm_coren: '',
                filiacao: '',
                rg: '',
                data_expedicao: '',
                endereco_completo: '',
                bairro: '',
                cep: '',
                carga_horaria_semanal: 0,
                especialidade: '',
                casado: false,
                filhos: 0,
                submit: null
            }}
            validationSchema={Yup.object().shape({
                nome: Yup.string().min(3).required('Nome é obrigatorio'),
                cpf: Yup.string().required('CPF é obrigatório'),
                email: Yup.string().email('tem que ser um email valido').max(100).required('Email é obrigatorio'),
                setor: Yup.string().required('Setor é obrigatório'),
                role: Yup.string().required('Nível de acesso é obrigatório'),
                telefone: Yup.string()
                    .matches(/^\([1-9]{2}\) [9]{0,1}[0-9]{4}-[0-9]{4}$/, 'Telefone inválido')
                    .required('O telefone é obrigatório'),
                password: Yup.string()
                    .min(6, 'Senha deve conter pelo menos 6 caracteres')
                    .matches(
                        /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/,
                        'A senha deve conter no mínimo 6 caracteres, alfanuméricos com pelo menos um caractere especial'
                    )
                    .required('Senha é obrigatória'),
                cbo: Yup.string().required('CBO é obrigatório'),
                cns: Yup.string().required('CNS é obrigatório'),
                nome_completo: setorMedico && Yup.string().required('Nome Completo é obrigatório'),
                crm_coren: setorMedico && Yup.string().required('Número de CRM / COREN é obrigatório')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                    values.cep = removeMask(values.cep);
                    values.cbo = removeMask(values.cbo);
                    values.cns = removeMask(values.cns);
                    values.cpf = removeMask(values.cpf);
                    values.rg = removeMask(values.rg);
                    values.telefone = removeMask(values.telefone);

                    await handleRegister(values);
                } catch (err) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.msg });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* CADASTRO PADRÃO */}

                    {etapa === 0 && (
                        <>
                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-nome-register">Nome</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-nome-register"
                                        type="text"
                                        value={values.nome}
                                        name="nome"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.nome && errors.nome && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.nome}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth error={Boolean(touched.cpf && errors.cpf)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-cpf-register">CPF</InputLabel>
                                    <InputMask mask="999.999.999-99" value={values.cpf} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-cpf-register"
                                                type="text"
                                                name="cpf"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.cpf && errors.cpf && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.cpf}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.telefone && errors.telefone)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-telefone-register">Celular</InputLabel>
                                    <InputMask mask="(99) 99999-9999" value={values.telefone} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-telefone-register"
                                                type="text"
                                                name="telefone"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.telefone && errors.telefone && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.telefone}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.email && errors.email)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-email-register">Email</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email-register"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl fullWidth error={Boolean(touched.cbo && errors.cbo)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-cbo-register">N° CBO</InputLabel>
                                    <InputMask mask="9999-99" value={values.cbo} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-cbo-register"
                                                type="text"
                                                name="cbo"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.cbo && errors.cbo && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.cbo}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth error={Boolean(touched.cns && errors.cns)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-cns-register">N° CNS</InputLabel>
                                    <InputMask mask="999-9999-9999-9999" value={values.cns} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-cns-register"
                                                type="text"
                                                name="cns"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.cns && errors.cns && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.cns}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.role && errors.role)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-role-register">Nível de acesso</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-role-register"
                                        type="text"
                                        value={values.role}
                                        name="role"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    >
                                        <MenuItem value={'administrador'}>Administrador</MenuItem>
                                        <MenuItem value={'Recepcao'}>Recepcionista</MenuItem>
                                        <MenuItem value={'Laboratorio'}>Laboratório</MenuItem>
                                        <MenuItem value={'farmacia'}>Farmácia</MenuItem>
                                        <MenuItem value={'medico'}>Médico</MenuItem>
                                        <MenuItem value={'Farm / Lab'}>Farm / Lab</MenuItem>
                                        <MenuItem value={'Raio-x'}>Raio-x</MenuItem>
                                        <MenuItem value={'Farm/Escuta/Lab'}>Farm / Escuta Inicial / Lab</MenuItem>
                                        <MenuItem value={'salaEscuta'}>Sala Escuta / Enfermagem</MenuItem>
                                        <MenuItem value={'chamadas'}>Chamadas</MenuItem>
                                    </Select>
                                    {touched.role && errors.role && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.role}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.setor && errors.setor)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-setor-register">Setor</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-setor-register"
                                        type="text"
                                        value={values.setor}
                                        name="setor"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e);
                                            checkSetor(e, values);
                                        }}
                                        inputProps={{}}
                                    >
                                        <MenuItem value={'Recepcao'}>Recepção</MenuItem>
                                        <MenuItem value={'Triagem'}>Triagem</MenuItem>
                                        <MenuItem value={'Enfermagem'}>Enfermagem</MenuItem>
                                        <MenuItem value={'médico'}>Consultorio Médico</MenuItem>
                                        <MenuItem value={'Farmacia'}>Farmácia</MenuItem>
                                        <MenuItem value={'Pronto_Socorro'}>Pronto Socorro</MenuItem>
                                        <MenuItem value={'Laboratorio'}>Laboratório</MenuItem>
                                        <MenuItem value={'Raio_X'}>Raio X</MenuItem>
                                        <MenuItem value={'Emergencia'}>Emergência</MenuItem>
                                        <MenuItem value={'chamadas'}>Chamadas</MenuItem>
                                        <MenuItem value={'Administrador'}>Administrador</MenuItem>
                                    </Select>
                                    {touched.setor && errors.setor && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.setor}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormControl
                                fullWidth
                                error={Boolean(touched.password && errors.password)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor="outlined-adornment-password-register">Senha</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password-register"
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    name="password"
                                    label="Password"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changePassword(e.target.value);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {touched.password && errors.password && (
                                    <FormHelperText error id="standard-weight-helper-text-password-register">
                                        {errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {strength !== 0 && (
                                <FormControl fullWidth>
                                    <Box sx={{ mb: 2 }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <Box
                                                    style={{ backgroundColor: level?.color }}
                                                    sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" fontSize="0.75rem">
                                                    {level?.label}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </FormControl>
                            )}
                        </>
                    )}
                    {/* CADASTRO SE FOR  MÉDICO */}
                    {etapa === 1 && setorMedico && (
                        <>
                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.nome_completo && errors.nome_completo)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-nome_completo-register">Nome Completo</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-nome_completo-register"
                                        type="text"
                                        value={values.nome_completo}
                                        name="nome_completo"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.nome_completo && errors.nome_completo && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.nome_completo}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.data_nascimento && errors.data_nascimento)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-data_nascimento-register">Data Nascimento</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-data_nascimento-register"
                                        type="date"
                                        value={values.data_nascimento}
                                        name="data_nascimento"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.data_nascimento && errors.data_nascimento && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.data_nascimento}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.local && errors.local)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-local-register">Local</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-local-register"
                                        type="text"
                                        value={values.local}
                                        name="local"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.local && errors.local && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.local}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.crm_coren && errors.crm_coren)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-crm_coren-register">CRM / COREN</InputLabel>
                                    <InputMask mask="999999" value={values.crm_coren} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-crm_coren-register"
                                                type="text"
                                                name="crm_coren"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.crm_coren && errors.crm_coren && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.crm_coren}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.filiacao && errors.filiacao)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-filiacao-register">Filiação</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-filiacao-register"
                                        type="text"
                                        value={values.filiacao}
                                        name="filiacao"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.filiacao && errors.filiacao && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.filiacao}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth error={Boolean(touched.rg && errors.rg)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-rg-register">RG</InputLabel>
                                    <InputMask mask="99.999.999-9" value={values.rg} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-rg-register"
                                                type="text"
                                                name="rg"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.rg && errors.rg && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.rg}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.data_expedicao && errors.data_expedicao)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-data_expedicao-register">Data Expedição</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-data_expedicao-register"
                                        type="date"
                                        value={values.data_expedicao}
                                        name="data_expedicao"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.data_expedicao && errors.data_expedicao && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.data_expedicao}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.endereco_completo && errors.endereco_completo)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-endereco_completo-register">Endereço</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-endereco_completo-register"
                                        type="text"
                                        value={values.endereco_completo}
                                        name="endereco_completo"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.endereco_completo && errors.endereco_completo && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.endereco_completo}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.bairro && errors.bairro)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-bairro-register">Bairro</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-bairro-register"
                                        type="text"
                                        value={values.bairro}
                                        name="bairro"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.bairro && errors.bairro && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.bairro}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth error={Boolean(touched.cep && errors.cep)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-cep-register">CEP</InputLabel>
                                    <InputMask mask="99999-999" value={values.cep} onBlur={handleBlur} onChange={handleChange}>
                                        {(inputProps) => (
                                            <OutlinedInput
                                                id="outlined-adornment-cep-register"
                                                type="text"
                                                name="cep"
                                                inputProps={inputProps}
                                            />
                                        )}
                                    </InputMask>
                                    {touched.cep && errors.cep && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.cep}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.carga_horaria_semanal && errors.carga_horaria_semanal)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-carga_horaria_semanal-register">
                                        Carga Horária Semanal
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-carga_horaria_semanal-register"
                                        type="number"
                                        value={values.carga_horaria_semanal}
                                        name="carga_horaria_semanal"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.carga_horaria_semanal && errors.carga_horaria_semanal && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.carga_horaria_semanal}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.especialidade && errors.especialidade)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-especialidade-register">Especialidade</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-especialidade-register"
                                        type="text"
                                        value={values.especialidade}
                                        name="especialidade"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.especialidade && errors.especialidade && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.especialidade}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>

                            <FormBox>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.casado && errors.casado)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-casado-register">Casado</InputLabel>
                                    <Select
                                        style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                        id="outlined-adornment-casado-register"
                                        type="text"
                                        value={values.casado}
                                        name="casado"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    >
                                        <MenuItem value={false}>Não</MenuItem>
                                        <MenuItem value={true}>Sim</MenuItem>
                                    </Select>
                                    {touched.casado && errors.casado && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.casado}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.filhos && errors.filhos)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-filhos-register">Filhos</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-filhos-register"
                                        type="number"
                                        value={values.filhos}
                                        name="filhos"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.filhos && errors.filhos && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.filhos}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormBox>
                        </>
                    )}

                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                    )}

                    {/* BOTÕES */}
                    <Box sx={{ mt: 2 }}>
                        {etapa === 0 && !setorMedico && (
                            <AnimateButton>
                                <Button
                                    sx={{ marginBottom: '3em' }}
                                    disableElevation
                                    disabled={isSubmitting || (etapa === 0 && !setorMedico && isAnyFieldEmpty(values))}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {isSubmitting || (etapa === 0 && !setorMedico && isAnyFieldEmpty(values))
                                        ? 'Campos obrigatórios faltando'
                                        : 'Cadastrar usuário'}
                                </Button>
                            </AnimateButton>
                        )}
                        {setorMedico && etapa === 1 && (
                            <AnimateButton>
                                <Button
                                    sx={{ marginBottom: '3em' }}
                                    disableElevation
                                    disabled={isSubmitting || (etapa === 1 && setorMedico && isAnyMedicFieldEmpty(values))}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {isSubmitting || (etapa === 1 && setorMedico && isAnyMedicFieldEmpty(values))
                                        ? 'Campos obrigatórios faltando'
                                        : 'Cadastrar usuário'}
                                </Button>
                            </AnimateButton>
                        )}
                        {etapa === 0 && setorMedico && (
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    disabled={etapa === 0 && setorMedico && isAnyFieldEmpty(values)}
                                    size="large"
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setEtapa((prevEtapa) => prevEtapa + 1)}
                                >
                                    Avançar
                                </Button>
                            </AnimateButton>
                        )}
                        {etapa > 0 && (
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    type="button"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleBack(values)}
                                >
                                    Voltar
                                </Button>
                            </AnimateButton>
                        )}
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default UserRegisterForm;
