export const TitleComponent = ({ title, width }) => {
    return (
        <div
            style={{
                color: 'white',
                padding: '5px',
                maxWidth: width || '400px'
            }}
        >
            <h3
                style={{
                    padding: '0',
                    backgroundColor: '#2196f3',
                    borderTopRightRadius: '100px',
                    borderBottomRightRadius: '100px',
                    padding: '15px'
                }}
            >
                {title}
            </h3>
        </div>
    );
};
