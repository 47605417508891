import {
    Button,
    Checkbox,
    Drawer,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormBox } from './style';
import useScriptRef from 'hooks/useScriptRef';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { IconDeviceFloppy } from '@tabler/icons';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useState } from 'react';

const DrawerCreateItem = ({
    open,
    handleClose,
    getItems,
    almoxarifados,
    categorias,
    unidades,
    localizacoes,
    filtredLocations,
    handleChangeLocations,
    ...others
}) => {
    // ============================= INSTÂNCIAS =============================
    const theme = useTheme();
    const api = useApi();
    const scriptedRef = useScriptRef();
    // ============================= STATE =============================
    const [stateCheckBox, setStateCheckBox] = useState({
        validade: false,
        lote: false,
        fracionamento: false,
        psicotropicos: false,
        antimicrobiano: false,
        gotas: false
    });
    const { validade, lote, fracionamento, psicotropicos, antimicrobiano, gotas } = stateCheckBox;
    const [loading, setLoading] = useState(false);
    // ============================= FUNÇÕES =============================
    const handleChangeCheckbox = (event) => {
        setStateCheckBox({
            ...stateCheckBox,
            [event.target.name]: event.target.checked
        });
    };

    const clearValues = (values) => {
        values.item = '';
        values.apelido = '';
        values.categoria_id = '';
        values.unidade_id = '';
        values.minimo = '';
        values.almoxarifado_id = '';
        values.localizacao_id = '';
        values.validade = '';
        values.lote = '';
        values.fracionamento = '';
        values.psicotropicos = '';
        values.antimicrobiano = '';
        values.gotas = '';
    };

    const handleRegister = async (values) => {
        try {
            setLoading(true);
            values.validade = validade;
            values.lote = lote;
            values.fracionamento = fracionamento;
            values.psicotropicos = psicotropicos;
            values.antimicrobiano = antimicrobiano;
            values.gotas = gotas;
            const response = await api.createItem(values);
            toast.success(response.data.mensagem);
            // limpar campos
            clearValues(values);
            getItems();
            handleClose();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.mensagem);
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box sx={{ width: '450px', padding: '.75em', height: '100%' }}>
                <Formik
                    initialValues={{
                        item: '',
                        apelido: '',
                        categoria_id: '',
                        unidade_id: '',
                        minimo: '',
                        almoxarifado_id: '',
                        localizacao_id: '',
                        validade: '',
                        lote: '',
                        fracionamento: '',
                        psicotropicos: '',
                        antimicrobiano: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        item: Yup.string().required('Descricao é obrigatorio')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            await handleRegister(values);
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.msg });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            {...others}
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
                        >
                            <Box>
                                <Typography variant="h3">Item</Typography>
                                <Typography variant="h4" sx={{ margin: '1em 0 1em 0' }}>
                                    Geral
                                </Typography>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.item && errors.item)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-item-register">Item</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-item-register"
                                            type="text"
                                            value={values.item}
                                            name="item"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.item && errors.item && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.item}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.apelido && errors.apelido)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-apelido-register">Apelido</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-apelido-register"
                                            type="text"
                                            value={values.apelido}
                                            name="apelido"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.apelido && errors.apelido && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.apelido}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.categoria_id && errors.categoria_id)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <TextField
                                            id="categoria_id"
                                            name="categoria_id"
                                            select
                                            onChange={(e) => handleChange(e)}
                                            onBlur={handleBlur}
                                            value={values.categoria_id}
                                            label="Categorias"
                                            SelectProps={{
                                                native: false
                                            }}
                                        >
                                            {categorias.map((categoria) => (
                                                <MenuItem key={categoria.id} value={categoria.id}>
                                                    {categoria.descricao}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {touched.categoria_id && errors.categoria_id && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.categoria_id}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.unidade_id && errors.unidade_id)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <TextField
                                            id="unidade_id"
                                            name="unidade_id"
                                            select
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.unidade_id}
                                            label="Unidades"
                                            SelectProps={{
                                                native: false
                                            }}
                                        >
                                            {unidades.map((unidade) => (
                                                <MenuItem key={unidade.id} value={unidade.id}>
                                                    {unidade.descricao}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        {touched.unidade_id && errors.unidade_id && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.unidade_id}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.minimo && errors.minimo)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-minimo-register">Mínimo</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-minimo-register"
                                            type="text"
                                            value={values.minimo}
                                            name="minimo"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.minimo && errors.minimo && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.minimo}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.almoxarifado_id && errors.almoxarifado_id)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <TextField
                                            id="almoxarifado_id"
                                            name="almoxarifado_id"
                                            select
                                            value={values.almoxarifado_id}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleChangeLocations(e.target.value);
                                            }}
                                            onBlur={handleBlur}
                                            label="Almoxarifado"
                                            SelectProps={{
                                                native: false
                                            }}
                                        >
                                            {almoxarifados.map((almoxarifado) => (
                                                <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                                    {almoxarifado.descricao}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        {touched.almoxarifado_id && errors.almoxarifado_id && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.almoxarifado_id}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.localizacao_id && errors.localizacao_id)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <TextField
                                            id="localizacao_id"
                                            name="localizacao_id"
                                            select
                                            value={values.localizacao_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Localização"
                                            SelectProps={{
                                                native: false
                                            }}
                                        >
                                            {filtredLocations.map((localizacao) => (
                                                <MenuItem key={localizacao.id} value={localizacao.id}>
                                                    {localizacao.descricao}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        {touched.localizacao_id && errors.localizacao_id && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.localizacao_id}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                                <FormBox>
                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <FormGroup>
                                            <Box>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={validade} onChange={handleChangeCheckbox} name="validade" />
                                                    }
                                                    label="Validade"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={lote} onChange={handleChangeCheckbox} name="lote" />}
                                                    label="Lote"
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={fracionamento}
                                                            onChange={handleChangeCheckbox}
                                                            name="fracionamento"
                                                        />
                                                    }
                                                    label="Fracionamento"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={psicotropicos}
                                                            onChange={handleChangeCheckbox}
                                                            name="psicotropicos"
                                                        />
                                                    }
                                                    label="Psicotrópicos"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={gotas} onChange={handleChangeCheckbox} name="gotas" />}
                                                    label="Gotas"
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={antimicrobiano}
                                                            onChange={handleChangeCheckbox}
                                                            name="antimicrobiano"
                                                        />
                                                    }
                                                    label="Antimicrobiano"
                                                />
                                            </Box>
                                        </FormGroup>
                                    </FormControl>
                                </FormBox>
                            </Box>
                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            {/* BOTÕES */}
                            <Box sx={{ mt: 2, width: '20%', marginLeft: 'auto' }}>
                                <AnimateButton>
                                    <Button disableElevation disabled={loading} fullWidth size="large" type="submit" variant="contained">
                                        {loading ? 'Aguarde' : 'Adicionar'}
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Drawer>
    );
};

export default DrawerCreateItem;
