import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Divider, IconButton, Tooltip } from '@mui/material';
import { IconFileTypePng } from '@tabler/icons-react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconDownload } from '@tabler/icons';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
};

export default function ViewImagesModal({ id }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [imagesArray, setImagesArray] = useState([]);
    const api = useApi();

    async function getFiles() {
        try {
            const { data } = await api.getImagesByProntuario(id);
            console.log('🚀 ~ getImages ~ data:', data);

            setImagesArray(data);
        } catch (error) {
            console.error('Erro ao obter os documentos:', error);
        }
    }

    useEffect(() => {
        if (open) {
            getFiles();
        }
    }, [open]);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'stored_blob_name', headerName: 'Nome', flex: 1 },
        { field: 'mimetype', headerName: 'Tipo', flex: 1 },
        {
            field: 'action',
            headerName: 'Ação',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Tooltip title="Baixar documento">
                        <IconButton onClick={() => handleDownload(params.row.stored_blob_name, params.row.mimetype)}>
                            <IconDownload />
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    ];

    async function handleDownload(blobName, type) {
        try {
            const { data } = await api.getBlobFile(blobName);

            const blob = new Blob([data], { type: type || 'application/octet-stream' });

            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = blobName || 'download-file';
            document.body.appendChild(link);
            link.click();
            link.remove();

            URL.revokeObjectURL(url);
        } catch (error) {
            console.log('🚀 ~ handleDownload ~ error:', error);
            console.error('Erro ao baixar o arquivo:', error.message);
        }
    }

    return (
        <div>
            <Tooltip title="Visualizar documentos">
                <Button onClick={handleOpen}>
                    <IconFileTypePng />
                </Button>
            </Tooltip>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Visualizar documentos</h3>
                    <Divider sx={{ marginBottom: '1rem' }} />

                    <DataGrid rows={imagesArray} columns={columns} />
                </Box>
            </Modal>
        </div>
    );
}
