import { Box, Button, CircularProgress, Grid, IconButton, Modal, TextField, Tooltip } from '@mui/material';
import { useApi } from 'Service/axios';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { formatarDataIdade } from 'utils/dataIdade';
import { MedicalRecordModal } from './components/MedicalRecordModal';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconLungs, IconArrowAutofitHeight, IconThermometer, IconFileAnalytics, IconPercentage } from '@tabler/icons';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CloseIcon from '@mui/icons-material/Close';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import CalculateIcon from '@mui/icons-material/Calculate';
import { Atestado } from '../Atestado';
import MedicalPrescription from './components/MedicalPrescripotion';
import { ApiTwoTone, Check, Print } from '@mui/icons-material';
import { IconClock } from '@tabler/icons-react';
import { DataGrid } from '@mui/x-data-grid';
import MedicalFinalizationModal from '../FinalizationModal';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { IconPill } from '@tabler/icons';
import ConjuntoLaudosPDF from './components/ConjuntoLaudos';
import CancelIcon from '@mui/icons-material/Cancel';
import { formatarData } from 'utils/formatarData';
import { PinCodeModal } from 'components/PinCodeModal';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import RelatorioFichaMedica from 'views/ManutencaoFicha/pdf/RelatorioFichaMedica';
import { removeMask } from 'utils/removeMask';
import { v4 } from 'uuid';
import PdfAllLabResult from 'views/Laboratorio/Atendimentos/WriteOffItens/components/PdfAllLab';

export function MedicalRecord() {
    // PDF MODAL VIEWER PLUGINS
    const getFilePluginInstance = getFilePlugin();
    const { DownloadButton } = getFilePluginInstance;
    const printPluginInstance = printPlugin();
    const { PrintButton } = printPluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    // APPLICATION
    const { prontuarioId, pacienteId, name, serviceId } = useParams();
    const api = useApi();
    const navigate = useNavigate();
    const consultorio = JSON.parse(localStorage.getItem('consultorio'));
    const pinCodeIsActive = JSON.parse(localStorage.getItem('pinCodeStatus'));
    const [setorUsuario, setSetorUsuario] = useState('');
    const [disableChanges, setDisableChanges] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [medicine, setMedicine] = useState({});
    const [triagem, setTriagem] = useState({});
    const [prontuario, setProntuario] = useState({});
    const [paciente, setPaciente] = useState({});
    const [medicalRecordObs, setMedicalRecordObs] = useState([]);
    const [openAtestado, setOpenAtestado] = useState(false);
    const [rowsNursing, setRowsNursing] = useState([]);
    const [openVerifyPinCode, setOpenVerifyPinCode] = useState(false);
    const [openReceita, setOpenReceita] = useState(false);
    const [openFinalizationModal, setOpenFinalizationModal] = useState(false);
    const [openModalPdfViewer, setOpenModalPdfViewer] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowsAtestadoReceita, setRowsAtestadoReceita] = useState([]);
    const [triggerReload, setTriggerReload] = useState(false);
    const [openModalLaudo, setOpenModalLaudo] = useState(false);
    const [data, setData] = useState({});
    const [pdfBlob, setPdfBlob] = useState(null);
    const [nurseReport, setNurseReport] = useState([]);
    const [openModalAllExamsChecked, setOpenModalAllExamsChecked] = useState(false);
    const [allusers, setAllUsers] = useState([]);
    const [pdfRecord, setPdfRecord] = useState(null);

    const stylePdf = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        height: '90vh'
    };

    const columnsNursing = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'type', headerName: 'TIPO', flex: 1, valueGetter: (params) => translateNursingStatus(params.row.type) },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'user', headerName: 'RESPONSAVEL', flex: 1 },
        {
            field: 'acoes',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Imprimir">
                    <Button
                        onClick={() => {
                            getPdfBlob(params?.row?.blob_name);
                            setOpenModalPdfViewer(true);
                        }}
                    >
                        <Print />
                    </Button>
                </Tooltip>
            )
        }
    ];

    const columns = [
        { field: 'cod', headerName: 'CÓDIGO', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'qtd', headerName: 'QUANTIDADE', flex: 0.5 },
        { field: 'unidade', headerName: 'UNIDADE', flex: 0.5 },
        {
            field: 'aplicacao',
            headerName: 'APLICAÇÃO',
            flex: 0.5,
            renderCell: (params) => <p style={{ textTransform: 'uppercase' }}>{params.row.aplicacao}</p>
        },
        { field: 'observacao', headerName: 'OBSERVAÇÃO', flex: 1 },
        {
            field: 'status',
            headerName: 'STATUS',
            flex: 0.5,
            renderCell: (params) => verificarStatus(params.row)
        }
    ];

    const columnsLabRaioProcedimentos = [
        { field: 'cod', headerName: 'CÓDIGO', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'observacao', headerName: 'OBSERVAÇÃO', flex: 1 },
        {
            field: 'status',
            headerName: 'STATUS',
            flex: 0.5,
            renderCell: (params) => verificarStatus(params.row)
        }
    ];

    const columnsLab = [
        { field: 'cod', headerName: 'CÓDIGO', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'observacao', headerName: 'OBSERVAÇÃO', flex: 1 },
        {
            field: 'status',
            headerName: 'STATUS',
            flex: 0.5,
            renderCell: (params) => verificarStatus(params.row)
        },
        {
            field: 'laudo',
            headerName: 'LAUDO',
            flex: 0.4,
            renderCell: (params) => {
                return (
                    <Tooltip title="Visualizar laudo">
                        <IconButton onClick={() => handleReport(params)}>
                            <Print />
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    ];

    const columnsMedicalRecord = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'dia', headerName: 'DIA', flex: 1 },
        { field: 'hora', headerName: 'HORA', flex: 1 },
        { field: 'conteudo', headerName: 'CONTEÚDO', flex: 1 },
        { field: 'responsavel', headerName: 'RESPONSÁVEL', flex: 1 }
    ];

    const columnsReport = [
        { field: 'data', headerName: 'DATA', flex: 1 },
        { field: 'hora', headerName: 'HORA', flex: 1 },
        { field: 'tipo', headerName: 'TIPO', flex: 0.8 },
        { field: 'user', headerName: 'RESPONSÁVEL', flex: 1 },
        { field: 'conteudo', headerName: 'CONTEÚDO', flex: 2 }
    ];

    const columnsAtestadoReceita = [
        { field: 'id', headerName: 'ID DOC', flex: 1 },
        { field: 'tipo', headerName: 'TIPO', flex: 1 },
        { field: 'medico', headerName: 'MEDICO', flex: 1 },
        {
            field: 'acoes',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Imprimir">
                    <Button
                        onClick={() => {
                            getPdfBlob(params?.row?.stored_blob_name);
                            setOpenModalPdfViewer(true);
                        }}
                    >
                        <Print />
                    </Button>
                </Tooltip>
            )
        }
    ];

    function translateNursingStatus(status) {
        switch (status) {
            case 'LABORATORIO':
                return 'COLETA';
            case 'MEDICACAO':
                return 'APL. MEDICACAO';
            default:
                return status;
        }
    }

    function handleReport(params) {
        const isSpecialExam = params.row?.item_conduta?.exame === null;
        // Definindo os ids com base na condição
        const exameId = !isSpecialExam ? params.row?.item_conduta?.exame?.id : null;
        const exameEspecialId = isSpecialExam ? params.row?.item_conduta?.exames_especiai?.id : null;
        const itemConduta = params?.row?.item_conduta;
        const prontuarioId = params?.row?.prontuario_id;

        if (itemConduta?.registro_laudo_enf?.stored_blob_name) {
            // Abre o visualizador de PDF com o blob
            getPdfBlob(itemConduta.registro_laudo_enf.stored_blob_name);
            setOpenModalPdfViewer(true);
            return;
        }

        // Caso não haja stored_blob_name, verifica os IDs para buscar ou abrir o laudo
        if (exameId !== null) {
            getLaudoData([
                {
                    idExameProntuario: itemConduta?.id,
                    exameId,
                    exameEspecialId,
                    prontuarioId,
                    especial: isSpecialExam
                }
            ]);
        } else if (exameEspecialId !== null) {
            window.open(`/laboratorio/resultado-especial/${prontuarioId}/${exameEspecialId}/${itemConduta?.id}`);
        }
    }

    function verificarStatus(item) {
        switch (item.tipo) {
            case 'MEDICAMENTO':
                if (item.status === 'ENF-LIBERADO') {
                    return (
                        <Tooltip title="Finalizado">
                            <Check />
                        </Tooltip>
                    );
                } else if (item?.conduta_inativada) {
                    return (
                        <Tooltip title="Cancelado">
                            <CancelIcon />
                        </Tooltip>
                    );
                }
                break;

            case 'LABORATÓRIO':
            case 'LABORATORIO':
                if (item.status === 'REALIZADO') {
                    return (
                        <Tooltip title="Finalizado">
                            <Check />
                        </Tooltip>
                    );
                }
                break;

            case 'RAIO-X':
            case 'RAIOX':
                if (item.status == 'R-FINALIZADO') {
                    return (
                        <Tooltip title="Finalizado">
                            <Check />
                        </Tooltip>
                    );
                }
                break;

            case 'PROCEDIMENTO':
                if (item.status == 'FINALIZADO') {
                    return (
                        <Tooltip title="Finalizado">
                            <Check />
                        </Tooltip>
                    );
                }
                break;

            default:
                break;
        }

        return (
            <Tooltip title="Aguardando">
                <IconClock />
            </Tooltip>
        );
    }

    async function getProntuario() {
        const toastId = toast.loading('Buscando laudos...');
        try {
            const { data } = await api.getProntuarioById(prontuarioId);
            setProntuario(data);
            toast.update(toastId, { render: 'Ficha carregada com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao buscar ficha', type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    async function getRecord() {
        try {
            const { data } = await api.getMedicalRecord(prontuarioId, pacienteId);
            setMedicine(data?.medicacao);
            setTriagem(data?.triagem);
            const res = await api.getPacienteById(pacienteId);
            setPaciente(res.data);
            handleMedicalRecord();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getStatusAtendimento(atendimentoId) {
        try {
            const { data } = await api.getStatusAtendimento(atendimentoId);
            if (data?.status_conduta) setDisableChanges(true);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getConduct() {
        try {
            const { data } = await api.getMedicalConduct(prontuarioId, serviceId);

            const row = data.map((item) => {
                let descricao;
                let qtd = '-';
                let unidade = '-';
                let aplicacao = '-';

                if (item?.tipo === 'LABORATORIO' || item?.tipo === 'LABORATÓRIO') {
                    if (item.item_conduta.exame) {
                        descricao = item?.item_conduta.exame?.nome;
                    } else {
                        descricao = item?.item_conduta.exames_especiai?.nome;
                    }
                } else if (item?.tipo === 'RAIO-X' || item?.tipo === 'RAIOX') {
                    descricao = item?.item_conduta.tipos_raio_x?.descricao;
                } else if (item?.tipo === 'MEDICAMENTO') {
                    let medInfo = item?.item_conduta?.item_pedido;

                    descricao = medInfo.descricao;
                    qtd = item?.item_conduta?.quantidade_item;
                    unidade = medInfo.unidade.sigla;
                    aplicacao = item?.item_conduta.via_aplicacao;
                } else if (item?.tipo === 'PROCEDIMENTO') {
                    descricao = item?.item_conduta?.procedimento?.descricao;
                }

                return {
                    ...item,
                    cod: item.id,
                    descricao: descricao,
                    qtd,
                    unidade,
                    aplicacao
                };
            });
            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleMedicalRecord() {
        try {
            const { data } = await api.getMedicalRecordObservation(prontuarioId, serviceId);
            const rows = data.map((item) => ({ ...item, responsavel: item?.usuario?.nome }));
            setMedicalRecordObs(rows);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    function handleBackPage() {
        navigate('/main/consultorio/atendimento');
    }

    function handleCloseAtestado() {
        setOpenAtestado(false);
    }

    async function getAtestadoReceita() {
        try {
            const { data } = await api.getAtestadoReceitaByProntuario(prontuarioId, serviceId);
            console.log('🚀 ~ getAtestadoReceita ~ data:', data);
            const rows = data.map((item) => ({
                ...item,
                uuid: v4(),
                tipo: item?.dias ? 'ATESTADO' : 'RECEITA',
                medico: item?.usuario?.nome ?? ''
            }));
            setTriggerReload(false);
            setRowsAtestadoReceita(rows);
        } catch (error) {
            toast.error(`Ocorreu um erro ao buscar os dados: ${error}`);
        }
    }

    async function getLaudoData(laudoPayload) {
        const toastId = toast.loading('Buscando laudos...');
        try {
            const response = await api?.getResultsForLaudos(laudoPayload);

            const agruparDados = response?.data.map((el) => {
                const resultadoCampoEspecifico = el.resultado_campo_especifico;
                const data = formatarData(el?.medico?.data_execucao);

                // Use o método reduce para agrupar os campos por categoria
                const camposAgrupados = resultadoCampoEspecifico.reduce((agrupados, item) => {
                    const categoria = item.campos_especifico.categoria;

                    // Se a categoria já existir no objeto agrupados, adicione o item a ela
                    if (agrupados[categoria]) {
                        agrupados[categoria].push(item);
                    } else {
                        // Se a categoria ainda não existir, crie um novo array com o item
                        agrupados[categoria] = [item];
                    }

                    return agrupados;
                }, {});
                for (const categoria in camposAgrupados) {
                    camposAgrupados[categoria].sort((a, b) => a.id - b.id);
                }
                return {
                    ...el,
                    data,
                    camposAgrupados
                };
            });

            response?.data?.sort((a, b) => {
                const aNome = a?.exame?.nome?.toLowerCase();
                const bNome = b?.exame?.nome?.toLowerCase();

                if (aNome?.includes('hemograma') || aNome?.includes('urina')) {
                    return -1;
                } else if (bNome?.includes('hemograma') || bNome?.includes('urina')) {
                    return 1;
                }

                return 0;
            });

            const parsedData = laudoPayload?.map(async (item) => {
                let observacao = await api.getObservation(
                    item.prontuarioId,
                    item.especial ? null : item.exameId,
                    item.especial ? item.exameId : null,
                    item.idExameProntuario
                );
                return observacao;
            });

            agruparDados.map((item) => ({ ...item, parsedData }));
            setOpenModalLaudo(true);
            setData(response.data);
            toast.update(toastId, { render: 'Laudos carregados com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao buscar laudos', type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    async function getNursingCertifiedRecords(prontuarioId) {
        try {
            const { data } = await api.buscarRegistrosProcedimentosProntuarioId(prontuarioId);
            console.log('🚀 ~ getNursingCertifiedRecords ~ data:', data);
            const rowsFormated = data.map((element) => ({
                id: element.id,
                type: element.tipo,
                blob_name: element.stored_blob_name,
                user: element.usuario.nome,
                date: element.formated_date
            }));
            setRowsNursing(rowsFormated);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    function handleConfirmPinCode() {
        const doctorOption = localStorage.getItem('doctorOption');
        setOpenVerifyPinCode(false);
        if (doctorOption == 'atestado') {
            setOpenAtestado(true);
        } else if (doctorOption == 'receita') {
            setOpenReceita(true);
        } else if (doctorOption == 'finalizar-conduta') {
            setOpenFinalizationModal(true);
        }
    }

    async function generateMedicinePdfBlob() {
        try {
            const { data } = await api.buscarRegistrosProcedimentosProntuarioId(prontuarioId);
            const rowsFormated = data.map((element) => ({
                id: element.id,
                type: element.tipo,
                blob_name: element.stored_blob_name,
                user: element.usuario.nome,
                date: element.formated_date
            }));
            // Gerar o blob do PDF
            const content = {
                ficha: {
                    id: triagem?.prontuario_id,
                    nome: name,
                    data: triagem?.data,
                    idade: formatarDataIdade(paciente?.data_nascimento)
                },
                paciente: paciente,
                prontuario: prontuario,
                triagem: { hora: triagem?.hora, responsavel: triagem?.usuario?.nome, dados: triagem },
                sala: prontuario?.salas_enfermagem?.descricao ?? 'N/A',
                medicamentos: rows.filter((item) => item.tipo === 'MEDICAMENTO'),
                laboratorio: rows.filter((item) => item.tipo === 'LABORATORIO' || item.tipo === 'LABORATÓRIO'),
                raiox_procedimentos: rows.filter((item) => item.tipo === 'RAIO-X' || item.tipo === 'RAIOX' || item.tipo === 'PROCEDIMENTO'),
                atestado_receita: rowsAtestadoReceita,
                procedimentos_enfermagem: rowsFormated
            };
            const document = <RelatorioFichaMedica data={content} />;
            const asPdf = pdf(document);
            const pdfBlob = await asPdf.toBlob();
            // Iniciar transação no CESS
            const payload = {
                identificacao: removeMask(String(localStorage.getItem('userIdentity'))),
                paciente: paciente?.nome_completo,
                tipo: 'finalizacao-conduta',
                prontuario_id: prontuarioId
            };

            const responseCess = await api.initTransactionCess(payload);
            // Enviar o arquivo para assinatura
            if (responseCess.status == 200 && responseCess.data) {
                const tcn = responseCess.data.tcn;
                localStorage.setItem('tcn', tcn);
                const formData = new FormData();
                formData.append('pdf', pdfBlob);
                formData.append('transactionTcn', tcn);
                const responseUpload = await api.uploadPdfCess(formData);
                if (responseUpload.status == 200) {
                    localStorage.removeItem('tcn');
                    const userInfo = JSON.parse(localStorage.getItem('userInfos'));
                    if (userInfo.usuario.setor != 'médico') {
                        localStorage.removeItem('userIdentity');
                    }
                    setOpenVerifyPinCode(false);
                    // window.location.reload();
                    return true;
                }
            }
        } catch (error) {
            toast.error(`Erro ao gerar relatório de finalização: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    async function getPdfBlob(blobName) {
        try {
            const { data } = await api.getBlob(blobName);
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            setPdfBlob(url);
        } catch (error) {
            toast.error(`Erro ao regatar documento: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    async function fetchData() {
        try {
            setLoadingData(true);
            await Promise.all([getRecord(), getConduct(), getAtestadoReceita(), getProntuario(), getNursingCertifiedRecords(prontuarioId)]);
            setLoadingData(false);
            setTriggerReload(false);
            handleCloseAtestado();
        } catch (error) {
            setLoadingData(false);
            toast.error('Erro ao buscar dados da ficha:', error);
        }
    }

    async function getNurseReport() {
        try {
            const { data } = await api.getAllNurseReport(prontuarioId);
            const rows = data?.map((item) => {
                return {
                    ...item,
                    data: item?.dia,
                    hora: item?.hora,
                    tipo: item?.tipo,
                    user: allusers?.find((user) => user?.id === item?.responsavel_id)?.nome
                };
            });
            setNurseReport(rows);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function users() {
        try {
            const { data } = await api.getAllUsers();
            setAllUsers(data.usuarios);
        } catch (error) {
            console.log('🚀 ~ users ~ error:', error);
        }
    }

    async function getPdfReportData(prontuarioId) {
        try {
            const { data } = await api.getPdfReport(prontuarioId);
            setPdfRecord(data);
            console.log('🚀 ~ users ~ data:', data);
        } catch (error) {
            console.log('🚀 ~ users ~ error:', error);
        }
    }

    useEffect(() => {
        if (triggerReload) getAtestadoReceita();
    }, [triggerReload]);

    useEffect(() => {
        fetchData();
        getStatusAtendimento(serviceId);
        users();
        const dadosUsuario = JSON.parse(localStorage.getItem('userInfos'));
        setSetorUsuario(dadosUsuario.usuario.setor);
    }, []);

    useEffect(() => {
        if (allusers?.length > 0) {
            getNurseReport();
        }
    }, [allusers]);

    return (
        <MainCard title={`${consultorio?.descricao} - ${name}`}>
            {loadingData && (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
            {!loadingData && (
                <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                    {pdfBlob && (
                        <Modal
                            open={openModalPdfViewer}
                            onClose={() => {
                                setPdfBlob(null);
                                setOpenModalPdfViewer(false);
                            }}
                        >
                            <Box sx={stylePdf}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '1rem',
                                        padding: '4px',
                                        alignItems: 'center',
                                        backgroundColor: '#eeeeee',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
                                    }}
                                >
                                    <DownloadButton />
                                    <PrintButton />
                                    <ZoomOutButton />
                                    <ZoomPopover />
                                    <ZoomInButton />
                                </Box>
                                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                                    <Viewer fileUrl={pdfBlob} plugins={[getFilePluginInstance, printPluginInstance, zoomPluginInstance]} />
                                </Worker>
                            </Box>
                        </Modal>
                    )}
                    <Modal
                        open={openModalLaudo}
                        onClose={() => setOpenModalLaudo(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={stylePdf}>
                            <PDFViewer width={'100%'} height={'100%'}>
                                <ConjuntoLaudosPDF data={data} />
                            </PDFViewer>
                        </Box>
                    </Modal>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', position: 'absolute', right: 0, top: '-60px' }}>
                            <p>
                                PRONTUÁRIO: <b>{triagem?.prontuario_id}</b>
                            </p>
                            <p>
                                FICHA DE ATENDIMENTO: <b> {medicine?.paciente_id}</b>
                            </p>
                            <p>
                                DATA: <b> {triagem?.data}</b> <b>{triagem?.hora}</b>
                            </p>
                            <p>
                                IDADE: <b> {formatarDataIdade(paciente?.data_nascimento)}</b>
                            </p>
                            <p>
                                STATUS: <b> {prontuario?.status}</b>
                            </p>
                        </Box>
                        <Box sx={{ padding: '10px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>ESCUTA INICIAL</h4>

                                <h4>
                                    RESPONSÁVEL: <b>{triagem?.usuario?.nome}</b>
                                </h4>
                            </Box>
                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <MonitorHeartIcon />
                                    <TextField
                                        label="SIS"
                                        type="number"
                                        value={triagem?.pa_1}
                                        name="pa_sis"
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px', marginLeft: '.5rem' }}
                                    />
                                    <CloseIcon sx={{ margin: '0 5px' }} />
                                    <TextField
                                        label="DIA"
                                        type="number"
                                        placeholder="DIA"
                                        value={triagem?.pa_2}
                                        name="pa_dia"
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FavoriteIcon />
                                    <TextField
                                        label="FC"
                                        type="number"
                                        value={triagem?.fc}
                                        name="fc"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconLungs />
                                    <TextField
                                        label="FR"
                                        type="number"
                                        value={triagem?.fr}
                                        name="fc"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <VaccinesIcon />
                                    <TextField
                                        label="DEXTRO"
                                        type="number"
                                        value={triagem?.dextro}
                                        name="fc"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <MonitorWeightIcon />
                                    <TextField
                                        disabled
                                        label="Peso"
                                        name="peso"
                                        type="text"
                                        value={triagem.peso}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px', marginLeft: '.5rem' }}
                                    />
                                    <IconArrowAutofitHeight sx={{ margin: '0 5px' }} />
                                    <TextField
                                        disabled
                                        label="Altura"
                                        name="altura"
                                        type="text"
                                        value={triagem.altura}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px', marginLeft: '.5rem' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CalculateIcon />
                                    <TextField
                                        label="IMC"
                                        name="IMC"
                                        type="number"
                                        value={triagem.imc}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconThermometer />
                                    <TextField
                                        label="Temperatura"
                                        name="temperatura"
                                        type="text"
                                        value={triagem?.temperatura}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconPercentage />
                                    <TextField
                                        label="Saturação"
                                        name="saturacao"
                                        type="number"
                                        value={triagem?.saturacao}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '10px' }}>
                                QUEIXA: <b>{triagem?.queixa ? triagem?.queixa : 'QUEIXA NÃO INFORMADA'}</b>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <p>
                                    MÉDICAÇÃO EM USO:{' '}
                                    {medicine?.medicacao_em_uso?.length === 0
                                        ? 'NEGA'
                                        : medicine?.medicacao_em_uso?.map((item, index, array) => (
                                              <span key={index}>{array?.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                                          ))}
                                </p>
                                <p>
                                    ALERGIA:{' '}
                                    {medicine?.alergia?.length === 0
                                        ? 'NEGA'
                                        : medicine?.alergia?.map((item, index, array) => (
                                              <span key={index}>{array?.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                                          ))}
                                </p>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ borderRadius: '7px', border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h3>FICHA MÉDICA</h3>
                            <MedicalRecordModal
                                paciente={paciente}
                                prontuarioId={prontuarioId}
                                getRecord={getRecord}
                                serviceId={serviceId}
                                disableChanges={disableChanges}
                            />
                        </Box>
                        {!!medicalRecordObs?.length && (
                            <DataGrid
                                rows={medicalRecordObs}
                                columns={columnsMedicalRecord}
                                hideFooter
                                getRowHeight={() => 'auto'}
                                sx={{
                                    maxHeight: '300px',
                                    overflow: 'auto',
                                    '& .MuiDataGrid-row': {
                                        padding: '10px'
                                    }
                                }}
                            />
                        )}
                    </Box>

                    <Box sx={{ borderRadius: '7px', border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h3>CONDUTA MÉDICA</h3>
                            <Button
                                variant="contained"
                                onClick={() => navigate(`/main/consultorio/conduta/${prontuarioId}/${name}/${serviceId}`)}
                                disabled={disableChanges}
                            >
                                Adicionar
                            </Button>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {rows?.length === 0 ? (
                                <p>Nenhum dado encontrado</p>
                            ) : (
                                <>
                                    {rows.filter((item) => item.tipo === 'MEDICAMENTO')?.length > 0 && (
                                        <>
                                            <h3>MEDICAMENTOS</h3>
                                            <DataGrid
                                                sx={{ maxHeight: '400px' }}
                                                columns={columns}
                                                getRowHeight={() => 'auto'}
                                                rows={rows.filter((item) => item.tipo === 'MEDICAMENTO')}
                                                hideFooter
                                            />
                                        </>
                                    )}
                                    {rows.filter((item) => item.tipo === 'LABORATORIO' || item.tipo === 'LABORATÓRIO')?.length > 0 && (
                                        <>
                                            <h3>LABORATÓRIO</h3>
                                            <DataGrid
                                                sx={{ maxHeight: '400px' }}
                                                columns={columnsLab}
                                                getRowHeight={() => 'auto'}
                                                rows={rows.filter((item) => item.tipo === 'LABORATORIO' || item.tipo === 'LABORATÓRIO')}
                                                hideFooter
                                            />
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
                                                <Tooltip title="Visualizar todos os exames finalizados">
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            setOpenModalAllExamsChecked(true);
                                                        }}
                                                        disabled={
                                                            rows
                                                                .filter(
                                                                    (item) => item.tipo === 'LABORATORIO' || item.tipo === 'LABORATÓRIO'
                                                                )
                                                                .filter((item) => item?.item_conduta?.registro_laudo_enf)?.length == 0
                                                        }
                                                    >
                                                        <Print />
                                                    </Button>
                                                </Tooltip>
                                            </Box>

                                            <Modal open={openModalAllExamsChecked} onClose={() => setOpenModalAllExamsChecked(false)}>
                                                <Box sx={stylePdf}>
                                                    <PDFViewer width={'100%'} height={'100%'}>
                                                        <PdfAllLabResult
                                                            examsChecked={rows
                                                                .filter(
                                                                    (item) => item?.tipo === 'LABORATORIO' || item.tipo === 'LABORATÓRIO'
                                                                )
                                                                .filter((item) => item?.item_conduta?.registro_laudo_enf)}
                                                        />
                                                    </PDFViewer>
                                                </Box>
                                            </Modal>
                                        </>
                                    )}
                                    {rows.filter((item) => item.tipo === 'RAIOX' || item.tipo === 'RAIO-X' || item.tipo === 'PROCEDIMENTO')
                                        ?.length > 0 && (
                                        <>
                                            <h3>RAIO-X / PROCEDIMENTOS</h3>
                                            <DataGrid
                                                sx={{ maxHeight: '400px' }}
                                                getRowHeight={() => 'auto'}
                                                columns={columnsLabRaioProcedimentos}
                                                rows={rows.filter(
                                                    (item) =>
                                                        item.tipo === 'RAIOX' || item.tipo === 'RAIO-X' || item.tipo === 'PROCEDIMENTO'
                                                )}
                                                hideFooter
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            padding: '10px',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            borderRadius: '7px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>ATESTADOS E RECEITAS</h3>
                            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                <Tooltip title="Receita">
                                    <Button
                                        onClick={() => {
                                            localStorage.setItem('doctorOption', 'receita');
                                            if (setorUsuario != 'médico') {
                                                setOpenVerifyPinCode(true);
                                            } else if (pinCodeIsActive) {
                                                setOpenReceita(true);
                                            } else {
                                                setOpenVerifyPinCode(true);
                                            }
                                        }}
                                        disabled={disableChanges}
                                        variant="contained"
                                    >
                                        <IconPill />
                                    </Button>
                                </Tooltip>
                                <MedicalPrescription
                                    open={openReceita}
                                    setOpen={setOpenReceita}
                                    paciente={paciente}
                                    triagem={triagem}
                                    setTriggerReload={setTriggerReload}
                                    setorUsuario={setorUsuario}
                                    pinCodeIsActive={pinCodeIsActive}
                                    setOpenVerifyPinCode={setOpenVerifyPinCode}
                                    fetchData={getAtestadoReceita}
                                />
                                <Tooltip title="Atestado">
                                    <Button
                                        variant="contained"
                                        disabled={disableChanges}
                                        onClick={() => {
                                            localStorage.setItem('doctorOption', 'atestado');
                                            if (setorUsuario != 'médico') {
                                                setOpenVerifyPinCode(true);
                                            } else if (pinCodeIsActive) {
                                                setOpenAtestado(true);
                                            } else {
                                                setOpenVerifyPinCode(true);
                                            }
                                        }}
                                    >
                                        <IconFileAnalytics />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>

                        {rowsAtestadoReceita?.length > 0 && (
                            <DataGrid
                                getRowHeight={() => 'auto'}
                                columns={columnsAtestadoReceita}
                                rows={rowsAtestadoReceita}
                                getRowId={(row) => row?.uuid}
                            />
                        )}
                    </Box>

                    <Box
                        sx={{
                            padding: '10px',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            borderRadius: '7px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>ACOMPANHAMENTOS DE ENFERMAGEM CERTIFICADOS</h3>
                        </Box>

                        {rowsNursing?.length > 0 && <DataGrid columns={columnsNursing} rows={rowsNursing} />}
                    </Box>

                    {nurseReport.length > 0 && (
                        <Box
                            sx={{
                                padding: '10px',
                                marginTop: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                borderRadius: '7px',
                                border: '1px solid #ccc'
                            }}
                        >
                            <h3>RELATÓRIO DE ACOMPANHAMENTO</h3>
                            <DataGrid
                                columnsReportgetRowHeight={(params) => Math.max(50, params?.size || 0)}
                                rows={nurseReport}
                                sx={{ maxHeight: '400px' }}
                                columns={columnsReport}
                                hideFooter
                            />
                        </Box>
                    )}

                    {/* <Box sx={{ borderRadius: '7px', border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h3>RELATÓRIO FINAL DE ATENDIMENTO</h3>
                            <Tooltip title="Imprimir">
                                <Button
                                    onClick={() => {
                                        getPdfReportData(prontuarioId);
                                        setOpenPdfReport(true);
                                    }}
                                >
                                    <Print />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box> */}

                    {/* <Modal
                        open={openPdfReport}
                        onClose={() => setOpenPdfReport(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={stylePdf}>
                            <PDFViewer width={'100%'} height={'100%'}>
                                <PdfReport data={pdfRecord} />
                            </PDFViewer>
                        </Box>
                    </Modal> */}

                    <Box sx={{ width: '100%', marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="contained" color="error" onClick={handleBackPage}>
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            disabled={disableChanges}
                            onClick={() => {
                                localStorage.setItem('doctorOption', 'finalizar-conduta');
                                if (setorUsuario != 'médico') {
                                    setOpenVerifyPinCode(true);
                                } else if (pinCodeIsActive) {
                                    handleConfirmPinCode();
                                } else {
                                    setOpenVerifyPinCode(true);
                                }
                            }}
                        >
                            Finalizar Conduta
                        </Button>
                    </Box>
                    <MedicalFinalizationModal
                        open={openFinalizationModal}
                        setOpenFinalizationModal={setOpenFinalizationModal}
                        prontuarioId={prontuarioId}
                        rows={rows}
                        generateMedicinePdfBlob={generateMedicinePdfBlob}
                    />
                    <Atestado
                        open={openAtestado}
                        handleClose={handleCloseAtestado}
                        paciente={paciente}
                        data={triagem?.data}
                        prontuarioId={prontuarioId}
                        setTriggerReload={setTriggerReload}
                        fetchData={getAtestadoReceita}
                    />
                    <PinCodeModal open={openVerifyPinCode} setOpen={setOpenVerifyPinCode} confirmPin={handleConfirmPinCode} />
                </Grid>
            )}
        </MainCard>
    );
}
