import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Modal,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { BtnArea, CustomInput, InputArea, TitleArea } from './styles';
import { DataGrid } from '@mui/x-data-grid';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { formatarData } from 'utils/formatarData';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { IconTrash } from '@tabler/icons';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

const ExamModal = ({ open, setOpen, currentRow, getAllProntuario }) => {
    const api = useApi();
    const gridRef = useRef();
    const [openAddExam, setOpenAddExam] = useState(false);
    const [exams, setExams] = useState([]);
    const [newExm, setNewExam] = useState('');
    const [registredExams, setRegistredExams] = useState([]);
    const [selectedItens, setSelectedItens] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    async function getAllXRayData() {
        try {
            const { data } = await api.getAllXRay();
            setExams(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        getAllXRayData();
    }, []);

    const columns = [
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'date', headerName: 'Date', flex: 1 },
        { field: 'observacao', headerName: 'Observação', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 1 },
        { field: 'liberacao', headerName: 'Liberação', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title="Deletar">
                    <IconButton
                        onClick={() => {
                            setOpenConfirmDialog(true);
                            setSelectedRow(params.row);
                        }}
                        disabled={params.row.status === 'Finalizado'}
                    >
                        <IconTrash />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    function handleSelection(selectedItems) {
        const selectedRowsData = registredExams.filter((row) => selectedItems.includes(row.id));
        setSelectedItens(selectedRowsData);
    }

    async function getExamsLinked() {
        try {
            const { data } = await api.getLinkExamXray(currentRow.id);

            const row = data.map((item) => ({
                id: item?.id,
                description: exams.find((exam) => exam.id === item.tipo_raio_x)?.descricao,
                date: formatarData(item?.createdAt),
                status: item?.finalizado ? 'Finalizado' : 'Pendente',
                situacao: item?.status,
                liberacao: item?.status == 'LIBERADO' ? 'Liberado' : 'Pendente',
                conduta_id: item?.conduta_medicas[0]?.id,
                observacao: item?.conduta_medicas[0]?.observacao
            }));

            setRegistredExams(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        if (currentRow) {
            getExamsLinked();
        }
    }, [currentRow]);

    async function handleLinkExam(id) {
        try {
            await api.linkExamXray(currentRow.id, id);

            getExamsLinked();
            toast.success('Exame vinculado com sucesso!');
            setOpenAddExam(false);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleFinishLinkExam() {
        const payload = selectedItens.map((item) => ({ id: item.id, conduta_id: item.conduta_id }));
        try {
            await api.finishLinkExamXray(payload);
            toast.success('Exames finalizados com sucesso');
            getExamsLinked();
            getAllProntuario();
            handleClose();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleDeleteExam() {
        const toastId = toast.loading('Eliminando exame...');
        try {
            await api.deleteXrayInModal(selectedRow?.conduta_id);
            toast.update(toastId, { render: 'Exame eliminado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
            getExamsLinked();
            getAllProntuario();
            setOpenConfirmDialog(false);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
            toast.update(toastId, { render: 'Erro ao eliminar exame', type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    return (
        <>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box sx={style}>
                    <TitleArea>
                        <h2 id="modal-title">Raio X </h2>
                        <h2 id="modal-title">{`F.a.: ${currentRow?.id} - ${currentRow?.patient}`}</h2>
                    </TitleArea>

                    <Tooltip title="Adicionar">
                        <IconButton onClick={() => setOpenAddExam(true)}>
                            <PostAddIcon />
                        </IconButton>
                    </Tooltip>
                    {openAddExam && (
                        <InputArea>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={exams}
                                sx={{ width: 300 }}
                                getOptionLabel={(option) => `${option.descricao} - ${option.codigo}`}
                                onChange={(event, newValue) => {
                                    handleLinkExam(newValue?.id);
                                }}
                                renderInput={(params) => <TextField {...params} label="Exames de Raio-x" value={newExm} />}
                            />
                        </InputArea>
                    )}

                    <Box>
                        {registredExams.length === 0 ? (
                            <h3>Nenhum exame vinculado</h3>
                        ) : (
                            <>
                                <DataGrid
                                    rows={registredExams}
                                    columns={columns}
                                    hideFooter={true}
                                    checkboxSelection
                                    getRowHeight={() => 'auto'}
                                    sx={{ maxHeight: '400px' }}
                                    onRowSelectionModelChange={handleSelection}
                                    isRowSelectable={(params) => params.row.status !== 'Finalizado' && params.row?.situacao == 'LIBERADO'}
                                />
                                <BtnArea>
                                    <Button variant="contained" onClick={handleFinishLinkExam}>
                                        Finalizar
                                    </Button>
                                </BtnArea>
                            </>
                        )}
                    </Box>
                </Box>
            </Modal>

            <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <DialogTitle variant="h3">Confirmação de eliminação</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Typography variant="body2">Deseja realmente eliminar este exame?</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setOpenConfirmDialog(false);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={handleDeleteExam}>
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ExamModal;
