import { Box, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export function DateFilterComponent({ dateFilter, setDateFilter, handleDateFilter, typeViews, singleInput }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <DatePicker
                label="De"
                value={dateFilter.from}
                views={typeViews}
                onChange={(value) => setDateFilter((prev) => ({ ...prev, from: dayjs(value).format('YYYY-MM-DD') }))}
            />
            {!singleInput && (
                <DatePicker
                    label="Até"
                    value={dateFilter.to}
                    views={typeViews}
                    onChange={(value) => setDateFilter((prev) => ({ ...prev, to: dayjs(value).format('YYYY-MM-DD') }))}
                />
            )}
            <Button variant="contained" onClick={handleDateFilter}>
                Pesquisar
            </Button>
        </Box>
    );
}
