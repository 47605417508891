import { Box } from '@mui/material';
import PatientMonitoring from './patientMonitoring';
import LabMonitoring from './labMonitoring';
import MedMonitoring from './medMonitoring';
import NursingCards from './nursingpainel';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import Sound from '../../../assets/sound.mp3';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import XRayMonitoring from './xraymonitoring';
import ProcedimentosMonitoring from './procedimentosMonitoring';

const NursingPainel = () => {
    const [nurseData, setNurseData] = useState([]);
    const [medicamentoData, setMedicamentoData] = useState([]);
    const [laboratorioData, setLaboratorioData] = useState([]);
    const [previousData, setPreviousData] = useState([]);
    const [playSound, setPaySound] = useState(false);
    const [rayxData, setXRayData] = useState([]);
    const [procedimentosData, setProcedimentosData] = useState([]);
    const api = useApi();

    const [newLiberadoExists, setNewLiberadoExists] = useState(false);

    async function getMedicacao() {
        try {
            const { data } = await api.getMedicacaoPendentePainel();
            console.log('🚀 ~ getMedicacao ~ data:', data);

            const row = data?.map((item) => ({
                id: v4(),
                fa: item?.prontuarioId,
                name: item?.paciente,
                data: item?.hora_formatada?.split(' ')[0],
                hora: item?.hora_formatada?.split(' ')[1],
                situacao: item?.status,
                createdAt: item?.createdAt
            }));

            // Filtrar os itens com status "LIBERADO"
            const currentLiberados = row?.filter((item) => item.situacao === 'LIBERADO');

            // Verificar se existe um novo "LIBERADO" em relação ao estado anterior
            const novoLiberado = currentLiberados.some((item) => !previousData.some((element) => element.fa === item.fa));

            // Atualizar o estado de som com base no novo liberado
            if (novoLiberado) {
                setNewLiberadoExists(true);
            } else {
                setNewLiberadoExists(false);
            }

            // Atualizar o estado previousData apenas com itens que ainda não foram "APLICADO"
            const updatedPreviousData = previousData.filter(
                (item) => !row.some((element) => element.fa === item.fa && element.situacao === 'APLICADO')
            );

            // Adicionar os novos itens liberados ao updatedPreviousData
            const newLiberadosToAdd = currentLiberados.filter((item) => !updatedPreviousData.some((element) => element.fa === item.fa));

            setPreviousData([...updatedPreviousData, ...newLiberadosToAdd]);

            // Atualizar o estado com os novos dados
            setMedicamentoData(row);
        } catch (error) {
            console.log('🚀 ~ getMedicacao ~ error:', error);
            toast.error('Erro ao buscar dados de medicação');
        }
    }

    async function getLaboratorio() {
        try {
            const { data } = await api.getLaboratorioPendentePainel();
            console.log('🚀 ~ getLaboratorio ~ data:', data);
            const row = data?.map((item) => {
                return {
                    id: item?.prontuarioId,
                    fa: item?.prontuarioId,
                    name: item?.paciente,
                    data: item?.hora_formatada?.split(' ')[0],
                    hora: item?.hora_formatada?.split(' ')[1],
                    situacao: item?.status,
                    createdAt: item?.createdAt
                };
            });

            setLaboratorioData(row);
        } catch (error) {
            toast.error('Erro ao buscar dados de laboratorio');
        }
    }

    async function getProcedimentos() {
        try {
            const { data } = await api.getProcedimentosEcgAertos();
            const row = data.map((item) => {
                return {
                    ...item,
                    fa: item?.prontuario?.id,
                    name: item?.conduta_medica?.prontuario?.paciente?.nome_completo,
                    data: item?.hora_formatada?.split(' ')[0],
                    hora: item?.hora_formatada?.split(' ')[1],
                    situacao: item?.status,
                    createdAt: item?.createdAt
                };
            });

            setProcedimentosData(row);
        } catch (error) {
            console.log('🚀 ~ getProcedimentos ~ error:', error);
        }
    }

    async function getEnfermagem() {
        try {
            const { data } = await api.getAcompanhamentoEnfermagem();
            const row = data.map((item) => {
                return {
                    ...item,
                    fa: item?.id,
                    name: item?.paciente?.nome_completo,
                    data: item?.dia,
                    local: item?.salas_enfermagem?.descricao,
                    situacao: item?.status,
                    medStatus: item?.status_medicacao
                };
            });

            setNurseData(row);
        } catch (error) {
            console.log('🚀 ~ getEnfermagem ~ error:', error);
        }
    }

    async function getXRay() {
        try {
            const { data } = await api.getAllOpenExamsRaiox();

            const camposAgrupados = data.reduce((agrupados, item) => {
                const prontuarioId = item.prontuario?.id;

                if (prontuarioId) {
                    if (!agrupados[prontuarioId]) {
                        agrupados[prontuarioId] = [];
                    }
                    agrupados[prontuarioId].push(item);
                }
                return agrupados;
            }, {});

            const row = Object.values(camposAgrupados).map((items) => {
                console.log('🚀 ~ row ~ itemsraiox:', items);
                const primeiroItem = items[0];
                const prontuario = primeiroItem?.prontuario || {};
                const paciente = prontuario?.paciente || {};

                const horaFormatada = items[0]?.hora_formatada || '';
                const [data, hora] = horaFormatada.split(' ');

                return {
                    id: prontuario?.id,
                    name: paciente?.nome_completo || 'Nome não informado',
                    data: data || 'Data não disponível',
                    hora: hora || 'Hora não disponível',
                    situacao: prontuario?.status || 'Status indefinido',
                    createdAt: primeiroItem?.createdAt || null
                };
            });

            setXRayData(row);
        } catch (error) {
            console.log('🚀 ~ getXRay ~ error:', error);
        }
    }

    useEffect(() => {
        if (newLiberadoExists) {
            playNotificationSound();
        }
    }, [newLiberadoExists]);

    function playNotificationSound() {
        const audio = new Audio(Sound);
        audio.play().then(() => {
            setPaySound(false);
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getMedicacao();
            getLaboratorio();
            getEnfermagem();
            getXRay();
            getProcedimentos();
        }, 5000);

        return () => clearInterval(interval);
    }, [previousData]);

    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // Dois itens por linha
                    gap: '1rem',
                    marginTop: '2rem'
                }}
            >
                {/* PatientMonitoring ocupando toda a largura */}

                <PatientMonitoring nurseData={nurseData} />

                <MedMonitoring nurseData={medicamentoData} />

                {/* Outros componentes lado a lado */}
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)', // Dois itens por linha
                    gap: '5px',
                    marginTop: '1rem'
                }}
            >
                <LabMonitoring nurseData={laboratorioData} />
                <XRayMonitoring nurseData={rayxData} />
                <ProcedimentosMonitoring nurseData={procedimentosData} />
            </Box>

            {playSound && (
                <audio style={{ display: 'none' }} controls autoPlay>
                    <track kind="captions" src="#" srclang="en" label="Portuguese" />
                    <source src={Sound} type="audio/mp3" />
                    Seu navegador não suporta o elemento de áudio.
                </audio>
            )}
        </>
    );
};

export default NursingPainel;
