import { Button, Dialog, DialogActions, DialogContent, DialogTitle, OutlinedInput, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import { removeMask } from 'utils/removeMask';

const ConfirmIdentity = ({ openConfirmarIdentidade, setCpf, cpf, getUserBycpf, setOpenConfirmarIdentidade }) => {
    return (
        <Dialog open={openConfirmarIdentidade} onClose={() => setOpenConfirmarIdentidade(false)}>
            <DialogTitle>Confirmação de identidade</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Typography variant="h4">Informe seu CPF para confirmar sua identidade</Typography>
                    <ReactInputMask mask="999.999.999-99" value={cpf} onChange={(e) => setCpf(e.target.value)}>
                        {(inputProps) => (
                            <OutlinedInput id="outlined-adornment-cpf-register" type="text" name="cpf" inputProps={inputProps} />
                        )}
                    </ReactInputMask>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Button variant="contained" color="error" onClick={() => setOpenConfirmarIdentidade(false)}>
                        Voltar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            getUserBycpf(removeMask(cpf));
                            setOpenConfirmarIdentidade(false);
                        }}
                    >
                        Confirmar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmIdentity;
