import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function MedicalRecordModal({ paciente, prontuarioId, getRecord, serviceId, disableChanges }) {
    const api = useApi();
    const [motivoAtendimento, setMotivoAtendimento] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setMotivoAtendimento('');
    };

    async function handleSubmit(event) {
        event.preventDefault();
        const currentUser = localStorage.getItem('userInfos');

        const userId = JSON.parse(currentUser).usuario.id;
        const payload = {
            conteudo: motivoAtendimento,
            prontuario_id: Number(prontuarioId),
            responsavel_id: userId,
            atendimento_id: serviceId
        };

        try {
            await api.addMedicalRecordList(payload);
            toast.success('Dados salvos com sucesso!');
            getRecord();
            handleClose();
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.error('Erro ao salvar dados!');
        }
    }

    return (
        <div>
            <Button onClick={handleOpen} disabled={disableChanges} variant="contained">
                +
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3>FICHA MÉDICA - {paciente?.nome_completo}</h3>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            multiline
                            label="Motivo do atendimento"
                            rows={3}
                            value={motivoAtendimento}
                            onChange={(e) => {
                                setMotivoAtendimento(e.target.value);
                            }}
                        />
                        <Button variant="contained" type="submit" sx={{ maxWidth: '200px', marginTop: '20px' }}>
                            Salvar
                        </Button>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
