import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import { useContext } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import formateDate from 'utils/formatDate-1';
import { useState } from 'react';
import Logo from '../../../../assets/images/Logos/LOGO_DO_LAC.jpg';
import SantaCasaLogo from '../../../../assets/images/Logos/logo2.png';
import { borderTop, display, fontSize } from '@mui/system';
import BwLogo from '../../../../assets/logobw2.png';

const PdfAtestado = ({ data }) => {
    console.log('🚀 ~ PdfReceita ~ data:', data);
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 50,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '10px',
            marginLeft: '10px'
        }
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR'); // Formato DD/MM/YYYY
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }); // Formato HH:MM
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '.5em',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '20px'
                    }}
                >
                    <Image src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '8%', left: '250px' }} />
                    <View style={{ fontSize: 7, borderBottom: '1px solid #165875' }}>
                        <Text style={{ textAlign: 'center', fontSize: 10 }}>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                        <Text style={{ textAlign: 'center' }}>RUA FRANCISCO WOHLERS, 57 - CENTRO - JOANÓPOLIS</Text>
                        <Text style={{ textAlign: 'center', marginBottom: '10px' }}>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                    </View>
                </View>

                <View style={{ marginTop: '15px' }}>
                    <Text style={{ textAlign: 'center', marginBottom: '15px', fontSize: '20px', fontWeight: 'extrabold' }}>
                        ATESTADO MÉDICO
                    </Text>

                    <View style={{ display: 'flex', justifyContent: 'space-between', fontSize: 10 }}>
                        <View>
                            {/* <Text>DADOS DO PACIENTE</Text> */}
                            <View style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                                <Text style={{ fontWeight: 'extrabold', fontSize: 12 }}>PACIENTE:</Text>
                                <Text>{data?.nome}</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                                <Text style={{ fontWeight: 'extrabold', fontSize: 12 }}>NASCIMENTO:</Text>
                                <Text>{data?.nascimento}</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                                <Text style={{ fontWeight: 'extrabold', fontSize: 12 }}>ATENDIMENTO:</Text>
                                <Text>
                                    {date} {time}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ marginTop: '15px', border: '1px solid #165875', borderRadius: '5px', padding: '10px', height: '65%' }}>
                    <Text>
                        ATESTO PARA FINS DE TRABALHO QUE O(A) PACIENTE ACIMA ESTEVE EM CONSULTA MÉDICA NO DIA DE HOJE E NECESSITA PERMANECER
                        AFASTADO(A) DE SUAS ATIVIDADES LABORATIVAS PELO PERÍODO DE {data?.dias} DIAS A CONTAR DESTA DATA.
                    </Text>
                    <Text style={{ textTransform: 'uppercase', marginTop: '10px' }}>{data?.observation}</Text>
                </View>

                <View
                    style={{
                        borderBottom: '1px solid #165875',
                        marginTop: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        fontSize: 9
                    }}
                >
                    <View style={{ display: 'flex', flexDirection: 'column', gap: '5px', flexDirection: 'row' }}>
                        <Text>MÉDICO:</Text>
                        <Text style={{ fontSize: 9 }}>{String(data?.doctor?.name).toUpperCase()}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column', gap: '5px', flexDirection: 'row' }}>
                        <Text>CRM:</Text>
                        <Text style={{ fontSize: 9 }}>{String(data?.doctor?.crm).toUpperCase()}</Text>
                    </View>
                </View>

                <View
                    fixed
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        bottom: 10,
                        right: 50
                    }}
                >
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Text style={{ color: '#0c303f', fontWeight: 'extrabold', fontSize: 6 }}>
                            SISTEMA DE PRONTUÁRIO ELETRÔNICO DESENVOLVIDO POR{' '}
                        </Text>

                        <Image style={{ width: '75%' }} src={BwLogo} />
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PdfAtestado;
