import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import TabelaPainelTriagem from './components/Tabela';
import { useContext } from 'react';
import CardPrimary from './components/Cards/CardPrimary';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardHora from './components/Cards/CardHora';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { formatarData } from 'utils/formatarData';

const LabPainel = () => {
    const api = useApi();
    // --- STATES --- \\
    const [dataAtual, setDataAtual] = useState(new Date());
    const [waitTime, setWaitTime] = useState('');
    const [quantidade, setQuantidade] = useState(0);
    const [limiteTempo, setLimiteTempo] = useState(false);
    const [row, setRow] = useState([]);
    // --- FUNCTIONS --- \\
    const formatarNumero = (numero) => {
        return numero < 10 ? `0${numero}` : numero;
    };

    const dia = formatarNumero(dataAtual.getDate());
    const mes = formatarNumero(dataAtual.getMonth() + 1);
    const ano = dataAtual.getFullYear();

    // ----EFFECTS --- //

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (row.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(row[0]?.createdAt);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                const waitTimeMoreThan10Minutes = waitTimeInSeconds > 600; // 600 seconds = 10 minutes
                setLimiteTempo(waitTimeMoreThan10Minutes);

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);

                // Use a variável waitTimeMoreThan10Minutes conforme necessário
            };
            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }

        setQuantidade(row.length);
    }, [row]);

    async function getAllProntuario() {
        try {
            const { data } = await api.getAllPendindExamsLab();

            const row = data.map((item) => {
                const createdAt = new Date(item?.createdAt);
                createdAt.setHours(createdAt.getHours() - 3);
                return {
                    id: item?.prontuario_id,
                    patient: item?.paciente?.nome_completo,
                    age: item?.paciente.idade + ' anos',
                    date: formatarData(item?.createdAt.split('T')[0]),
                    hour: createdAt.toISOString().split('T')[1].split('.')[0],
                    situation: item?.status,
                    createdAt: item?.createdAt,
                    autismo: item?.paciente?.autismo
                };
            });
            setRow(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        let elemento = document.documentElement;
        elemento.requestFullscreen();
    }, []);

    useEffect(() => {
        const getData = () => {
            getAllProntuario();
        };
        getData();
        const interval = setInterval(getData, 10000);
        return () => clearInterval(interval);
    }, []);
    return (
        <Box sx={{ width: '100%', marginTop: '1rem' }}>
            <Box
                sx={{
                    width: '90%',
                    display: 'flex',
                    margin: '0 auto',
                    gap: '1rem',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h1" sx={{ textAlign: 'center' }}>
                    PAINEL LABORATÓRIO
                </Typography>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Typography variant="h3">
                        DATA: {dia}/{mes}/{ano}
                    </Typography>
                    <Typography variant="h3">
                        HORA: {formatarNumero(dataAtual.getHours())}:{formatarNumero(dataAtual.getMinutes())}:
                        {formatarNumero(dataAtual.getSeconds())}
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '3rem',
                    margin: '2rem auto'
                }}
            >
                <CardPrimary data={row.length} legenda={'Aguardando'} icon={<PersonIcon />} size={'6rem'} quantidade={quantidade} />
                <CardHora data={waitTime} legenda={'Tempo Espera'} icon={<AccessTimeIcon />} size={'3.8rem'} limiteTempo={limiteTempo} />
            </Box>

            <Divider sx={{ width: '90%', margin: '0 auto' }} />

            <Box sx={{ width: '90%', margin: '0 auto', height: '500px' }}>
                <TabelaPainelTriagem atendimentos={row} />
            </Box>
        </Box>
    );
};

export default LabPainel;
