import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import TempoMedio from 'components/Triagem/Cards/TempoMedio';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { TimerArea } from './styles';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useEffect, useState } from 'react';
import { AnalysesModal } from './components/AnalyseModal';
import { useApi } from 'Service/axios';
import { formatarData } from 'utils/formatarData';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

export function ConsultServices() {
    const api = useApi();
    const navigate = useNavigate();
    const [openAnalyse, setOpenAnalyse] = useState(false);
    const [rows, setRows] = useState([]);

    function navigateToItensDetails(id, nome) {
        navigate(`/main/estoque/farmacia/atendimentos/consulta/${id}/${nome}`);
    }

    async function getAllProntuario() {
        try {
            const { data } = await api.getAllPedidosMedicamentoFinalizados();

            const row = data.map((item) => ({
                id: item?.prontuario_id,
                patient: item?.pedido_medicacao?.prontuario?.paciente.nome_completo,
                age: item?.pedido_medicacao?.prontuario?.paciente.idade + ' anos',
                date: formatarData(item?.createdAt.split('T')[0]),
                hour: item?.createdAt.split('T')[1].split('.')[0],
                situation: item?.pedido_medicacao?.atendido ? 'SEPARADO' : 'AGUARDANDO'
            }));
            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        if (rows.length === 0) {
            getAllProntuario();
        }
    }, []);

    const columns = [
        { field: 'id', headerName: 'F.A', flex: 0.5 },
        { field: 'patient', headerName: 'PACIENTE', flex: 1 },
        { field: 'age', headerName: 'IDADE', flex: 0.5 },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'hour', headerName: 'HORA', flex: 0.5 },
        { field: 'situation', headerName: 'SITUAÇÃO', flex: 1 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Detalhes">
                    <IconButton
                        onClick={() => {
                            navigateToItensDetails(params.row.id, params.row.patient);
                        }}
                    >
                        <InventoryIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    return (
        <MainCard title="CONSULTAR ATENDIMENTOS">
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box style={{ width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} hideFooter={true} />
                </Box>
            </Grid>
        </MainCard>
    );
}
