import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    return (
        <MainCard>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '1em',
                        width: '100%',
                        height: '600px'
                    }}
                >
                    <iframe
                        frameborder="0"
                        allowFullScreen="true"
                        target="_blank"
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        title="cargaFabrica"
                        src="https://app.powerbi.com/view?r=eyJrIjoiYWFmOWVkZjQtNjE4OS00YTY2LWE5YjAtZmQ0ZTA0NWQ3ZjFhIiwidCI6IjkwZTZjYTgxLWM1ZTAtNDVmNC1iMzE3LWRlOTIxYzUyMTMzMyJ9 "
                    />
                </Box>
            </div>
        </MainCard>
    );
};

export default Dashboard;
