import { useApi } from 'Service/axios';
import useChamadas from 'hooks/useChamadas';
import { useState } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';

const ChamadaContext = createContext();

function ChamadasProvider({ children }) {
    const api = useApi();
    const { chamadas, setChamadas, getChamadas } = useChamadas();
    // ------------ PAINEL TRIAGEM ------------ \\
    // Pega atendimentos pra triagem
    const [atendimentos, setAtendimentos] = useState([]);
    const [quantidadeAguardando, setQuantidadeAguardando] = useState([]);
    const [chamadaAcionada, setChamadaAcionada] = useState(false);
    const [chamadasRealizadas, setChamadasRealizadas] = useState([]);
    const [atendimentosTemporarios, setAtendimentosTemporarios] = useState([]);
    const [playSound, setPlaySound] = useState(false);

    function arraysHaveDifference(arr1, arr2) {
        // Diferente tamanho, significa item novo
        if (arr1.length > arr2.length) return true;
        // Diferente tamanho, significa item novo
        if (arr1.length < arr2.length) {
            setAtendimentosTemporarios(arr2);
            return false;
        }
        return !arr1.every((item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]));
    }

    function verifyDuplicate() {
        if (arraysHaveDifference(atendimentos, atendimentosTemporarios)) {
            setPlaySound(true);
            setAtendimentosTemporarios(atendimentos);
        }
    }

    const getFichaTriagem = async () => {
        try {
            const response = await api.getTriagem();
            setQuantidadeAguardando(response.data.length);
            setAtendimentos(response.data);
            setChamadaAcionada(false);
        } catch (error) {
            console.log('🚀 ~ getFichaTriagem ~ error:', error);
        }
    };

    useEffect(() => {
        verifyDuplicate();
    }, [atendimentos]);

    useEffect(() => {
        const getAndSetFichaTriagem = () => {
            getFichaTriagem();
        };
        getAndSetFichaTriagem();
        const interval = setInterval(getAndSetFichaTriagem, 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (chamadaAcionada) {
            getFichaTriagem();
        }
    }, [chamadaAcionada]);

    return (
        <ChamadaContext.Provider
            value={{
                chamadas,
                setChamadas,
                getChamadas,
                atendimentos,
                quantidadeAguardando,
                chamadaAcionada,
                setChamadaAcionada,
                chamadasRealizadas,
                setChamadasRealizadas,
                setPlaySound,
                playSound
            }}
        >
            {children}
        </ChamadaContext.Provider>
    );
}

export { ChamadaContext, ChamadasProvider };
