import { Box, Autocomplete, TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export function PageOne({ setIsExpired }) {
    const api = useApi();
    const [row, setRow] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    async function get() {
        try {
            const { data } = await api.getAllClinic();
            setRow(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function linkDoctorToOffice() {
        const toastId = toast.loading('Vinculando consultório...');

        const payload = {
            usuarioId: currentUser?.id,
            consultorioId: selectedClinic?.id
        };
        try {
            await api.linkdoctor(payload);
            const selectedString = JSON.stringify(selectedClinic);

            localStorage.setItem('consultorio', selectedString);
            localStorage.setItem('login_expired', false);
            setIsExpired(false);
            toast.update(toastId, { render: 'Consultório vinculado com sucesso!', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao vincular consultório!', type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    useEffect(() => {
        if (selectedClinic) {
            linkDoctorToOffice();
        }
    }, [selectedClinic]);

    useEffect(() => {
        get();
        const user = localStorage.getItem('userInfos');
        const userObj = JSON.parse(user);
        setCurrentUser(userObj?.usuario);
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                height: 'calc(100vh - 260px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                background: '#fff'
            }}
        >
            <h1 style={{ maxWidth: '600px', textAlign: 'justify', lineHeight: 1.5 }}>
                OLÁ, {currentUser?.nome}, EM QUAL CONSULTÓRIO INICIARÁ OS ATENDIMENTOS?
            </h1>
            <Autocomplete
                options={row}
                sx={{ width: 500 }}
                getOptionLabel={(option) => option.descricao}
                onChange={(event, value) => setSelectedClinic(value)}
                renderInput={(params) => <TextField {...params} label="Consultório" />}
            />
        </Box>
    );
}
