import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DatePicker } from '@mui/x-date-pickers';
import { Autocomplete, Divider, IconButton, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconEdit, IconTrash } from '@tabler/icons';
import { AddItemDrawer } from './AddItemDrawer';
import { EditItemDrawer } from './EditeItemDrawer';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 4
};

const styleModalStatus = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 4
};

export default function CreateModal({ open, setOpen, selectedRow }) {
    const api = useApi();
    const handleOpen = () => setOpen(true);
    const [doctorsList, setDoctorsList] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [openEditeItemDrawer, setOpenEditeItemDrawer] = useState(false);
    const [selectedItemEdite, setSelectedItemEdite] = useState(null);
    const [rows, setRows] = useState([]);
    const [date, setDate] = useState(null);
    const [openModalStatus, setOpenModalStatus] = useState(false);
    const [finalizationStatus, setFinalizationStatus] = useState('');
    const restrictStatus = ['ALTA_MED_PROC', 'ALTA_CONSULTORIO', 'ALTA', 'ALTA_RET_ACESSO'];

    const columns = [
        { field: 'codgo', headerName: 'Procedimentos', flex: 1 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'quantidade', headerName: 'Quantidade', flex: 1, headerAlign: 'start' },
        { field: 'tipo', headerName: 'Tipo', flex: 1 },
        {
            field: 'acoes',
            headerName: 'Ações',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        color="primary"
                        disabled={restrictStatus.includes(selectedRow.status)}
                        onClick={() => {
                            setSelectedItemEdite(params.row);
                            setOpenEditeItemDrawer(true);
                        }}
                    >
                        <IconEdit size={20} />
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={() => handleDeleteItem(params.row.id)}
                        disabled={restrictStatus.includes(selectedRow.status)}
                    >
                        <IconTrash size={20} />
                    </IconButton>
                </Box>
            )
        }
    ];

    const handleClose = () => {
        setOpen(false);
        setSelectedDoctor(null);
        setRows([]);
    };

    function handleCloseAndSave(haveStatus) {
        if (haveStatus) {
            setFinalizationStatus('FINALIZADA-MANUAL');
        }
        setOpenModalStatus(false);
        handleSave();
    }

    async function getFaturamento(faturamentoId) {
        try {
            const { data } = await api.getFaturamentoById(faturamentoId);
            const newRows = data.itens_fat_fichas.map((item) => ({
                allinfos: item?.procedimentos_su,
                id: v4(),
                codgo: item?.procedimentos_su?.codigo,
                descricao: item?.procedimentos_su?.descricao,
                quantidade: item.quantidade,
                tipo: item?.procedimentos_su?.tipo,
                item_novo: false
            }));
            setRows((prev) => [...prev, ...newRows]);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getAllUsersData() {
        try {
            const { data } = await api.getAllUsers();

            setDoctorsList(data.usuarios);
        } catch (error) {
            console.log('🚀 ~ getAllPagesNumbers ~ error:', error);
        }
    }

    function handleDeleteItem(id) {
        setRows((prev) => prev.filter((item) => item.id !== id));
    }

    async function handleSave() {
        // obs: responsavel é o doctor
        if (!selectedDoctor) return toast.error('Selecione um responsável');
        if (!date) return toast.error('Selecione uma data de competência');
        if (rows.length === 0) return toast.error('Selecione os procedimentos');

        const faturamentoId = selectedRow.id;
        const payload = {
            competencia: date,
            responsavel_id: selectedDoctor.id,
            paciente_id: selectedRow?.id_paciente,
            prontuario_id: selectedRow?.codigoFA,
            tipo_atendimento: '02-urgencia',
            status: finalizationStatus,
            procedimentos: rows
                .filter((element) => element.item_novo)
                .map((item) => ({
                    procedimento_id: item.allinfos.id,
                    quantidade: item.quantidade
                }))
        };

        try {
            await api.editConsolidado(faturamentoId, payload);
            toast.success('Faturamento salvo com sucesso');
            handleClose();
        } catch (error) {
            console.log('🚀 ~ handleSave ~ error:', error);
        }
    }

    useEffect(() => {
        if (open) {
            getAllUsersData();
            getFaturamento(selectedRow.id);
        }
    }, [open]);

    return (
        <React.Fragment>
            {selectedRow && (
                <div>
                    <Box sx={{ display: 'none' }}>
                        <EditItemDrawer
                            open={openEditeItemDrawer}
                            setOpen={setOpenEditeItemDrawer}
                            setRows={setRows}
                            selectedItemEdite={selectedItemEdite}
                        />
                        <Button onClick={handleOpen}>Open modal</Button>
                    </Box>

                    {/* MODAL DE STATUS */}
                    <Modal open={openModalStatus} onClose={() => setOpenModalStatus(false)}>
                        <Box sx={styleModalStatus}>
                            <Typography variant="h3" sx={{ textAlign: 'center' }}>
                                Deseja alterar o status para Finalizada-Manualmente?
                            </Typography>
                            <Box sx={{ display: 'flex', gap: '1rem', marginTop: '1rem', justifyContent: 'center' }}>
                                <Button variant="contained" color="error" onClick={() => handleCloseAndSave(false)}>
                                    Não
                                </Button>
                                <Button variant="contained" onClick={() => handleCloseAndSave(true)}>
                                    Sim
                                </Button>
                            </Box>
                        </Box>
                    </Modal>

                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h2>FATURAMENTO</h2>
                                <DatePicker
                                    label="COMPETÊNCIA"
                                    // value={dateFilter.to}
                                    views={['month', 'year']}
                                    onChange={(value) => setDate(dayjs(value).format('YYYY-MM'))}
                                />
                            </Box>

                            <Divider sx={{ margin: '5px 0' }}>DADOS DO PACIENTE</Divider>

                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <p>PACIENTE: </p>
                                        <p style={{ fontWeight: 'bold' }}>{selectedRow?.pacienteData?.nome_completo}</p>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '-20px' }}>
                                        <p>CÓDIGO: </p>
                                        <p style={{ fontWeight: 'bold' }}>{selectedRow?.id_paciente}</p>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '-20px' }}>
                                        <p>ENDEREÇO: </p>
                                        <p style={{ fontWeight: 'bold' }}>{selectedRow?.pacienteData?.endereco || '-'}</p>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <p>SUS: </p>
                                        <p style={{ fontWeight: 'bold' }}>{selectedRow?.pacienteData?.cartao_sus || '-'}</p>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '-20px' }}>
                                        <p>NASCIMENTO: </p>
                                        <p style={{ fontWeight: 'bold' }}>{selectedRow?.pacienteData?.data_nascimento || '-'}</p>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '-20px' }}>
                                        <p>TELEFONE: </p>
                                        <p style={{ fontWeight: 'bold' }}>{selectedRow?.pacienteData?.telefone || '-'}</p>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <p>SEXO: </p>
                                        <p style={{ fontWeight: 'bold' }}>
                                            {selectedRow?.pacienteData?.sexo === 1
                                                ? 'MASCULINO'
                                                : selectedRow?.pacienteData?.sexo === 2
                                                ? 'FEMININO'
                                                : 'OUTRO'}
                                        </p>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '-20px' }}>
                                        <p>RAÇA: </p>
                                        <p style={{ fontWeight: 'bold' }}>{selectedRow?.pacienteData?.cor || '-'}</p>
                                    </Box>
                                </Box>
                            </Box>

                            <Divider sx={{ margin: '5px 0' }}>DADOS DO RESPONSÁVEL</Divider>

                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <Autocomplete
                                        disablePortal
                                        options={doctorsList}
                                        getOptionLabel={(option) => option.nome}
                                        sx={{ width: 400 }}
                                        value={selectedDoctor}
                                        onChange={(event, newValue) => {
                                            setSelectedDoctor(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="SELECIONE O RESPONSÁVEL" />}
                                    />
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ flex: 1 }}
                                        label="NOME COMPLETO"
                                        variant="standard"
                                        value={selectedDoctor?.nome}
                                        disabled
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px' }}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '200px' }}
                                        label="CNES"
                                        variant="standard"
                                        value={'2086719'}
                                        disabled
                                    />
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '200px' }}
                                        label="SUS"
                                        variant="standard"
                                        value={selectedDoctor?.cns}
                                        disabled
                                    />
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '200px' }}
                                        label="CBO"
                                        variant="standard"
                                        value={selectedDoctor?.cbo}
                                        disabled
                                    />
                                </Box>
                            </Box>

                            <Divider>LISTAGEM DE ATENDIMENTOS</Divider>

                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3>02 - URGÊNCIA</h3>
                                    <AddItemDrawer setRows={setRows} disableButton={restrictStatus.includes(selectedRow.status)} />
                                </Box>

                                <Box sx={{ width: '100%' }}>
                                    <DataGrid sx={{ maxHeight: '200px', overflowY: 'auto' }} rows={rows} columns={columns} hideFooter />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => setOpenModalStatus(true)}
                                        sx={{ margin: '10px 0', width: '200px' }}
                                    >
                                        Salvar
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                </div>
            )}
        </React.Fragment>
    );
}
