import {
    Button,
    Box,
    TextField,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions,
    Dialog,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
} from '@mui/material';
import { FormBox } from './style';
import { useState, useEffect, useRef } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { PinCodeModal } from 'components/PinCodeModal';
import ConjuntoLaudosPDF from 'views/Relatorios/PDF/ConjuntoLaudos';
import { pdf } from '@react-pdf/renderer';
import { removeMask } from 'utils/removeMask';
const vmWEBSOCKET = process.env.REACT_APP_websocket_vm;
const railwayWEBSOCKET = process.env.REACT_APP_websocket_railway;

const ResultadoExameForm = ({ exame2, prontuarioID, idExameProntuario, tipo }) => {
    // ============================= INSTÂNCIA =============================
    const api = useApi();
    // ============================= STATES =============================
    const [campos, setCampos] = useState([]);
    const [exameStatus, setExameStatus] = useState(null);
    const [fullExameData, setFullExameData] = useState([]);
    const [open, setOpen] = useState(false);
    const [dataFormatada, setDataFormatada] = useState('');
    const [horaFormatada, setHoraFormatada] = useState('');
    const [loading, setLoading] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [idObservacao, setIdObservacao] = useState('');
    const [observacaoSalva, setObservacaoSalva] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    const [openVerifyPinCode, setOpenVerifyPinCode] = useState(false);
    const pinCodeIsActive = JSON.parse(localStorage.getItem('pinCodeStatus'));
    const [setorUsuario, setSetorUsuario] = useState('');
    const ws = useRef(null);
    const [socketData, setSocketData] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const dataAserFormatada = exameStatus?.data_execucao;
    // ============================= FUNÇÕES =============================

    const getFullExameData = async (prontuarioID) => {
        if (tipo === 'especial') {
            try {
                const response = await api.getAssociatedExameEspecial(prontuarioID, idExameProntuario);
                setFullExameData(response.data);
            } catch (error) {
                toast.error(error.message);
                toast.error(error.response.message);
            }
        } else {
            try {
                const response = await api.getAssociatedExame(prontuarioID, idExameProntuario);
                setFullExameData(response.data);
            } catch (error) {
                toast.error(error.message);
                toast.error(error.response.message);
            }
        }
    };

    // pega o status (caso já tenha sido efetuado o laudo, bloqueia o edit com base no status)
    const getExameStatus = async (idExameProntuario) => {
        if (tipo === 'especial') {
            try {
                const response = await api.verificarStatusExameEspecialProntuario(idExameProntuario);

                setExameStatus(response.data);
            } catch (error) {
                toast.error(error.response.message);
            }
        } else {
            try {
                const response = await api.verificarStatusExameProntuario(idExameProntuario);
                setExameStatus(response.data);
            } catch (error) {
                toast.error(error.message);
                toast.error(error.response.message);
            }
        }
    };

    const getObservacao = async () => {
        if (tipo === 'especial') {
            try {
                const response = await api.getObservation(prontuarioID, null, exame2?.id, idExameProntuario);
                setObservacao(response?.data[0]?.observation);
                setIdObservacao(response?.data[0]?.id);
                setObservacaoSalva(response?.data[0]?.observacaoSalva);
            } catch (error) {
                //toast.error(error?.response?.data?.message ?? error?.message);
            }
        } else {
            try {
                const response = await api.getObservation(prontuarioID, exame2?.id, null, idExameProntuario);
                setObservacao(response?.data[0]?.observation);
                setIdObservacao(response?.data[0]?.id);
                setObservacaoSalva(response?.data[0]?.observacaoSalva);
            } catch (error) {
                //toast.error(error?.response?.data?.message ?? error?.message);
            }
        }
    };

    const atualizarCampoEspecifico = (indiceCampo, indiceCampoEspecifico, campo, valor) => {
        const novoValor = typeof valor === 'string' ? valor.replace(',', '.') : valor;

        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].campos_especificos[indiceCampoEspecifico][campo] = novoValor;

            return novosCampos;
        });
    };

    const fillData = (exame2) => {
        setCampos([exame2]);
    };

    //handle change para o campo de observação
    const handleChange = (event) => {
        const { value } = event.target;
        setObservacao(value);
    };

    const formatarData = (data) => {
        const dataFormatada = new Date(data);
        const dataFormatadaString = dataFormatada.toLocaleDateString();
        setDataFormatada(dataFormatadaString);
        const horaFormatadaString = dataFormatada.toLocaleTimeString();
        setHoraFormatada(horaFormatadaString);
        return dataFormatadaString + ' ' + horaFormatadaString;
    };

    async function getLaudoData(payload) {
        try {
            const response = await api.getResultsForLaudos(payload);
            const agruparDados = response?.data.map((el) => {
                const resultadoCampoEspecifico = el.resultado_campo_especifico;
                const data = formatarData(el?.medico?.data_execucao);

                // agrupar os campos por categoria
                const camposAgrupados = resultadoCampoEspecifico.reduce((agrupados, item) => {
                    const categoria = item.campos_especifico.categoria;

                    // Se a categoria já existir no objeto agrupados, adicione o item a ela
                    if (agrupados[categoria]) {
                        agrupados[categoria].push(item);
                    } else {
                        // Se a categoria ainda não existir, crie um novo array com o item
                        agrupados[categoria] = [item];
                    }

                    return agrupados;
                }, {});
                for (const categoria in camposAgrupados) {
                    camposAgrupados[categoria].sort((a, b) => a.id - b.id);
                }
                return {
                    ...el,
                    data,
                    camposAgrupados
                };
            });

            const ordenarExames = (a, b) => {
                const aNome = a?.exame?.nome?.toLowerCase();
                const bNome = b?.exame?.nome?.toLowerCase();

                if (aNome?.includes('hemograma') || aNome?.includes('urina')) {
                    return -1;
                } else if (bNome?.includes('hemograma') || bNome?.includes('urina')) {
                    return 1;
                }

                return 0;
            };

            response?.data?.sort(ordenarExames);

            const parsedData = payload?.map(async (item) => {
                let observacao = await api.getObservation(
                    item.prontuarioId,
                    item.especial ? null : item.exameId,
                    item.especial ? item.exameEspecialId : null,
                    item.idExameProntuario
                );
                return observacao;
            });

            agruparDados.map((item) => ({ ...item, parsedData }));
            agruparDados.map((item) => ({ ...item, parsedData }));
            setPdfData(response.data);
            toast.success('Laudos carregados com sucesso');
            return response.data;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleClickCertificate() {
        // Caso não seja médico, sempre vai pedir o CPF
        if (setorUsuario !== 'médico' || !pinCodeIsActive) {
            setOpenVerifyPinCode(true);
        } else {
            await handleConfirmPinCode();
        }
    }

    async function handleConfirmPinCode() {
        setOpenVerifyPinCode(false);
        certificateExam();
    }

    // ============================= SUBMIT =============================
    const handleSaveObservacao = async () => {
        try {
            if (tipo === 'especial') {
                await api.saveObservationEspecial(prontuarioID, exame2?.id, idExameProntuario, observacao, true);
            } else {
                await api.saveObservation(prontuarioID, exame2?.id, idExameProntuario, observacao, true);
            }
            toast.success('Observação salva com sucesso!');
            getObservacao();
        } catch (error) {
            //toast.error('Erro ao salvar observação: ' + error.message);
        }
    };

    async function submitRegistroLaudo(responsavel_id, prontuario_id, stored_blob_name, tipo, idExameProntuario) {
        try {
            const payload = { responsavel_id, prontuario_id, stored_blob_name, tipo, exame_prontuario_id: idExameProntuario };
            await api.createRegistroLaudoEnfermagem(payload);
            setSocketData(null);
            toast.success('Registro de laudo gerado com sucesso');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function certificateExam() {
        try {
            setLoading(true);
            localStorage.setItem('option', 'laudo');
            const isEspecial = tipo == 'especial' ? true : false;
            const payload = [
                {
                    idExameProntuario: idExameProntuario,
                    exameId: isEspecial ? null : exame2.id,
                    exameEspecialId: isEspecial ? exame2.id : null,
                    prontuarioId: prontuarioID,
                    especial: isEspecial
                }
            ];

            await generateLabPdfBlob(payload);
            setLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function generateLabPdfBlob(laudoPayload) {
        try {
            const dataToPdf = await getLaudoData(laudoPayload);
            const document = <ConjuntoLaudosPDF data={dataToPdf} />;
            const asPdf = pdf(document);
            const pdfBlob = await asPdf.toBlob();
            // Iniciar transação no CESS
            const payload = {
                identificacao: removeMask(String(localStorage.getItem('userIdentity'))),
                paciente: fullExameData[0]?.paciente?.nome_completo,
                tipo: 'Laudo',
                prontuario_id: prontuarioID,
                exame_prontuario_id: laudoPayload[0].idExameProntuario
            };

            const responseCess = await api.initTransactionCess(payload);
            // Enviar o arquivo para assinatura
            if (responseCess.status == 200 && responseCess.data) {
                const tcn = responseCess.data.tcn;
                localStorage.setItem('tcn', tcn);
                const formData = new FormData();
                formData.append('pdf', pdfBlob);
                formData.append('transactionTcn', tcn);
                const responseUpload = await api.uploadPdfCess(formData);
                if (responseUpload.status == 200) {
                    localStorage.removeItem('tcn');
                    const userInfo = JSON.parse(localStorage.getItem('userInfos'));
                    if (userInfo.usuario.setor != 'médico') {
                        localStorage.removeItem('userIdentity');
                    }
                    setOpenVerifyPinCode(false);
                    await api.certificarLaudo(idExameProntuario);
                    window.location.reload();
                }
            }
        } catch (error) {
            toast.error(`Erro ao gerar certificação de laudo: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    const handleSaveAll = async () => {
        try {
            setOpen(false);
            setLoading(true);

            await handleSaveObservacao();
            await api.saveExameResult(prontuarioID, idExameProntuario, campos[0]);
            await api.finalizarExame(idExameProntuario);
            window.location.reload();
            toast.success('Resultados salvos com sucesso!');
        } catch (error) {
            setLoading(false);
            toast.error(`Erro: ${error.response.data.mensagem}`);
            toast.error(`Erro: ${error.message}`);
        }
    };

    const handleEditObservacao = async () => {
        try {
            toast.success('Observação atualizada com sucesso!');
            getObservacao();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    useEffect(() => {
        formatarData(dataAserFormatada);
    }, [dataAserFormatada]);

    useEffect(() => {
        const dadosUsuario = JSON.parse(localStorage.getItem('userInfos'));
        setSetorUsuario(dadosUsuario.usuario.setor);
        fillData(exame2);
        getFullExameData(prontuarioID);
        getExameStatus(idExameProntuario);
        getObservacao(prontuarioID, exame2?.id, idExameProntuario);
    }, [exame2]);

    // SOCKET
    useEffect(() => {
        // !!! PRODUÇÃO !!!
        const socket = new WebSocket(vmWEBSOCKET);
        // DEV
        //const socket = new WebSocket(railwayWEBSOCKET);
        socket.onopen = () => setIsReady(true);
        socket.onclose = () => setIsReady(false);
        socket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if (parsedData.type == 'CESS-SIGNATURE') {
                setSocketData(parsedData);
            }
        };
        ws.current = socket;
        return () => {
            socket.close();
        };
    }, []);

    useEffect(() => {
        if (socketData && socketData.data.document_type == 'Laudo') {
            const option = localStorage.getItem('option');
            const tipo = option.toUpperCase();
            submitRegistroLaudo(socketData?.data?.user_id, prontuarioID, socketData?.data?.pdfStoredName, tipo, idExameProntuario);
        }
    }, [socketData]);

    function sendPing(ws) {
        if (ws?.current?.readyState === WebSocket.OPEN) {
            ws.current.send('ping socket');
        }
    }

    useEffect(() => {
        const pingInterval = setInterval(() => sendPing(ws), 60000);
        return () => {
            clearInterval(pingInterval);
        };
    }, []);

    console.log(fullExameData[0]);

    return (
        <>
            {exameStatus === null ? (
                <Typography>Carregando...</Typography>
            ) : (
                <FormBox>
                    <PinCodeModal open={openVerifyPinCode} setOpen={setOpenVerifyPinCode} confirmPin={handleConfirmPinCode} />
                    <Dialog open={open} onClose={() => setOpen(false)}>
                        <DialogTitle>Atenção!</DialogTitle>
                        <DialogContent>
                            <Typography>Ao salvar os resultados não será mais possível editar ou inserir valores neste exame.</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="error" onClick={() => setOpen(false)}>
                                Cancelar
                            </Button>
                            <Button variant="contained" onClick={handleSaveAll}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {campos[0]?.campos_especificos &&
                        campos.map((campo, indiceCampo) => (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        height: '100%',
                                        padding: '20px',
                                        boxSizing: 'border-box'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '15px'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography variant="h4">Paciente: </Typography>
                                            <Typography>{fullExameData[0]?.paciente?.nome_completo}</Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography variant="h4">N° Ficha: </Typography>
                                            <Typography>{prontuarioID}</Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography variant="h4">Médico Solicitante:</Typography>
                                            <Typography>{exameStatus?.usuario?.nome}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography variant="h4">CRM:</Typography>
                                            <Typography>{exameStatus?.usuario?.info_medico?.crm_coren}</Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px'
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Typography variant="h4">Exame:</Typography>
                                            <Typography>
                                                {campo.nome} ({campo.sigla})
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Typography variant="h4">Material:</Typography>
                                            <Typography>{campo.material}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Typography variant="h4">Método:</Typography>
                                            <Typography>{campo.metodo}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                            <Typography variant="h4">Data: </Typography>
                                            <Typography>
                                                {dataFormatada} - {horaFormatada}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '40%',
                                            display: 'flex',
                                            gap: '10px',
                                            marginRight: '55px'
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', minWidth: '100%' }}>
                                            <TextField
                                                id="outlined-adornment-observacao-register"
                                                disabled={loading || exameStatus?.finalizado}
                                                label="Observação"
                                                value={observacao}
                                                name="budgetObservation"
                                                onChange={handleChange}
                                                multiline
                                                variant="outlined"
                                                inputProps={{
                                                    style: { height: '100px', overflow: 'auto', minWidth: '500px', maxWidth: '500px' }
                                                }}
                                            />
                                        </Box>
                                        {!observacaoSalva ? (
                                            <Button
                                                variant="contained"
                                                disabled={loading || exameStatus?.finalizado}
                                                sx={{
                                                    display: 'flex',
                                                    height: '40px',
                                                    margin: 'auto 0'
                                                }}
                                                onClick={() => handleSaveObservacao()}
                                            >
                                                Salvar
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                disabled={loading || exameStatus?.finalizado}
                                                color="warning"
                                                sx={{
                                                    display: 'flex',
                                                    height: '40px',
                                                    margin: 'auto 0'
                                                }}
                                                onClick={() => handleEditObservacao()}
                                            >
                                                Editar
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                                <Box>
                                    <TableContainer
                                        component={Paper}
                                        style={{
                                            width: '100%',
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor: 'rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3)'
                                        }}
                                    >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <b>Nome Campo</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>Unidade</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>Categoria</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>Valores Referencia</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>Valores Obtidos</b>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {campos[indiceCampo].campos_especificos.map((campoEspecifico, indiceCampoEspecifico) => (
                                                    <TableRow key={indiceCampoEspecifico}>
                                                        <TableCell>{campoEspecifico.campo_nome}</TableCell>
                                                        <TableCell align="center">{campoEspecifico.unidade}</TableCell>
                                                        <TableCell align="center">{campoEspecifico.categoria}</TableCell>
                                                        <TableCell align="center">{campoEspecifico.valores}</TableCell>
                                                        <TableCell align="center">
                                                            <form autocomplete="off">
                                                                <TextField
                                                                    autocomplete="off"
                                                                    label="Inserir Resultado"
                                                                    type="text"
                                                                    disabled={exameStatus?.finalizado}
                                                                    defaultValue={
                                                                        campoEspecifico.resultados &&
                                                                        campoEspecifico.resultados[0] &&
                                                                        campoEspecifico.resultados[0].valor
                                                                            ? campoEspecifico.resultados[0].valor
                                                                            : ''
                                                                    }
                                                                    onChange={(e) => {
                                                                        atualizarCampoEspecifico(
                                                                            indiceCampo,
                                                                            indiceCampoEspecifico,
                                                                            campos[0].especial ? 'porcentagem' : 'resultado',
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    inputProps={{ style: { fontSize: 13, textAlign: 'center' } }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </form>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '1rem' }}>
                                    <Button variant="contained" disabled={loading || exameStatus?.finalizado} onClick={handleSaveAll}>
                                        Salvar resultados
                                    </Button>
                                    {/* <Button
                                        variant="contained"
                                        disabled={
                                            loading ||
                                            !exameStatus?.finalizado ||
                                            (fullExameData &&
                                                fullExameData.length > 0 &&
                                                fullExameData[0]?.exame_prontuarios.find(
                                                    (item) => parseInt(item.id) == parseInt(idExameProntuario)
                                                )?.laudo_certificado)
                                        }
                                        onClick={handleClickCertificate}
                                    >
                                        Certificar Laudo
                                    </Button> */}
                                </Box>
                            </>
                        ))}
                </FormBox>
            )}
        </>
    );
};

export default ResultadoExameForm;
