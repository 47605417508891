import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Typography, TextField, MenuItem, Autocomplete } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { TableBox } from './style';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';

const SaldoGeral = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [itens, setItens] = useState([]);
    const [data, setData] = useState([]);
    const [unmutableData, setUnmatableData] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [itemSelecionado, setItemSelecionado] = useState({});
    const [saldoTotal, setSaldoTotal] = useState(0);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'item', headerName: 'Descrição', flex: 2, valueGetter: (params) => params.row.item.descricao },
        { field: 'unidade', headerName: 'UN', flex: 1, valueGetter: (params) => params.row.item.unidade.descricao },
        { field: 'almoxarifado', headerName: 'Almoxarifado', flex: 2, valueGetter: (params) => params.row.almoxarifado.descricao },
        { field: 'localizacao', headerName: 'Localização', flex: 1, valueGetter: (params) => params.row.localizacao.descricao },
        { field: 'lote', headerName: 'Lote', flex: 1, valueGetter: (params) => (params.row.lote === '0' ? 'N/A' : params.row.lote) },
        { field: 'saldo', headerName: 'Saldo', flex: 2, valueGetter: (params) => params.row.quantidade_disponivel }
    ];
    // ============================= FUNÇÕES =============================

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };
    const handleSelectItem = (itemId) => {
        if (itemId !== null) {
            const selectedItem = itens.find((item) => item.id === itemId);
            if (selectedItem) {
                setItemSelecionado({
                    ...itemSelecionado,
                    item_id: selectedItem.id
                });
            } else {
                setItemSelecionado({});
            }
        } else {
            setItemSelecionado({});
        }
    };

    const handleFilterItem = (item) => {
        const itemData = unmutableData.filter((el) => el.item_id === item).map((item, index) => ({ ...item, id: index + 1 }));
        let saldoTotal = 0;
        itemData.forEach((item) => {
            saldoTotal += item.quantidade_disponivel;
        });
        setSaldoTotal(saldoTotal);

        const itemWithoutSaldo = itemData?.filter((item) => item?.quantidade_disponivel != 0);
        console.log('🚀 ~ handleFilterItem ~ itemData:', itemData);
        setData(itemWithoutSaldo);
    };

    const handleResetFilter = () => {
        setData([]);
        setSelectedItem('');
        setSaldoTotal(0);
    };

    const getItens = async () => {
        try {
            const response = await api.getAllItems();
            setItens(response.data);
        } catch (error) {
            toast.error(`Erro ao buscar itens: ${error.response.data.message}`);
        }
    };

    const getMovSum = async () => {
        try {
            const response = await api.getMovSumarizados();
            const formatedData = await response.data.map((item, index) => ({
                ...item,
                id: index + 1
            }));
            setUnmatableData(formatedData);
        } catch (error) {
            toast.error(`Erro ao buscas movimentos: ${error.response.data.message}`);
        }
    };

    useEffect(() => {
        getItens();
        getMovSum();
    }, []);

    return (
        <MainCard title="SALDO GERAL">
            <Grid container spacing={gridSpacing}>
                <TableBox>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '1em', marginBottom: '1em' }}>
                        <Autocomplete
                            sx={{ width: '60%' }}
                            id={itemSelecionado?.item_id}
                            options={itens}
                            getOptionLabel={(itens) => `${itens?.id} - ${itens?.descricao}`}
                            value={itens.find((el) => el.id === itemSelecionado?.item_id) || null}
                            onChange={(e, newValue) => handleSelectItem(newValue?.id)}
                            renderInput={(params) => <TextField {...params} label="Item" />}
                        />

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                            <Button variant="contained" color="error" onClick={handleResetFilter} sx={{ height: '40px' }}>
                                Limpar
                            </Button>
                            <Button variant="contained" onClick={() => handleFilterItem(itemSelecionado?.item_id)} sx={{ height: '40px' }}>
                                Pesquisar
                            </Button>
                        </Box>
                    </Box>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        hideFooter={false}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
                        <TextField disabled label="Saldo Total" value={saldoTotal} InputLabelProps={{ shrink: true }} />
                    </Box>
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default SaldoGeral;
