import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import SantaCasaLogo from '../../../../src/assets/images/Logos/logo2.png';
import BwLogo from '../../../../src/assets/logobw2.png';
import formater from 'utils/DateDDMMYYY';

const ProcedimentosPdf = ({ data }) => {
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 50,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 },
        fixed: {
            bottom: 0,
            right: 10,
            width: '100%',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderTop: '1px solid #165875',
            marginTop: '10px',
            marginLeft: '10px'
        }
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR'); // Formato DD/MM/YYYY
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }); // Formato HH:MM
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();
    // data?.controle_especial;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '10px',
                        gap: '10px'
                    }}
                >
                    <Image src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '7%' }} />
                    <View
                        style={{
                            fontSize: 7,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Text style={{ textAlign: 'center', fontSize: 10 }}>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                        <Text style={{ textAlign: 'center' }}>RUA FRANCISCO WOHLERS, 57 - CENTRO - JOANÓPOLIS</Text>
                        <Text style={{ textAlign: 'center', marginBottom: '10px' }}>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                    </View>
                </View>
                <View style={{ borderBottom: '1px solid #165875', width: '100%' }}></View>
                <View style={{ marginTop: '15px' }}>
                    <Text style={{ textAlign: 'center', marginBottom: '15px', fontSize: '20px', fontWeight: 'extrabold' }}>
                        PROCEDIMENTO
                    </Text>

                    <View style={{ display: 'flex', justifyContent: 'space-between', fontSize: 9 }}>
                        <View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                                <Text style={{ fontWeight: 'extrabold', fontSize: 12 }}>PACIENTE:</Text>
                                <Text style={{ fontSize: 12 }}>{data?.paciente?.nome_completo}</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                                <Text style={{ fontWeight: 'extrabold', fontSize: 12 }}>NASCIMENTO:</Text>
                                <Text style={{ fontSize: 12 }}>{formater(data?.paciente?.data_nascimento)}</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                                    <Text style={{ fontWeight: 'extrabold', fontSize: 12 }}>ATENDIMENTO:</Text>
                                    <Text style={{ fontSize: 12 }}>
                                        {date} {time}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                {data?.procedimentos.length > 0 &&
                    data?.procedimentos.map((item, index) => (
                        <View
                            key={index}
                            style={{ marginTop: '15px', border: '1px solid #165875', borderRadius: '5px', padding: '10px', height: '70%' }}
                        >
                            <Text
                                style={{ fontSize: 12 }}
                            >{`Procedimento de ${item?.descricao} realizado em ${item?.hora_formatada}`}</Text>
                        </View>
                    ))}
                <View
                    fixed
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        bottom: 10,
                        right: 50
                    }}
                >
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Text style={{ color: '#0c303f', fontWeight: 'extrabold', fontSize: 4 }}>
                            SISTEMA DE PRONTUÁRIO ELETRÔNICO DESENVOLVIDO POR{' '}
                        </Text>

                        <Image style={{ width: '65%' }} src={BwLogo} />
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default ProcedimentosPdf;
