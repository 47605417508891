import recepcao from './lobby';
import laboratorio from './laboratorio';
import estoque from './estoque';
import triagem from './triagem';
import chamadas from './chamadas';
import xray from './xray';
import procedimentos from './procedimentos';
import doctorsOffice from './doctorsOffice';
import enfermagem from './enfermagem';
import faturamento from './faturamento';
import dashboard from './dashboard';
// ==============================|| MENU ITEMS ||============================== //

const role = localStorage.getItem('role');
const setorString = localStorage.getItem('userInfos');
const setorParse = JSON.parse(setorString);

let menuItems;

if (role === 'root') {
    menuItems = {
        items: [dashboard, recepcao, chamadas, triagem, laboratorio, estoque, xray, procedimentos, faturamento, doctorsOffice, enfermagem]
    };
} else if (role === 'administrador') {
    menuItems = {
        items: [recepcao, chamadas, triagem, laboratorio, estoque, xray, procedimentos, doctorsOffice, enfermagem]
    };
} else if (role === 'Laboratorio') {
    menuItems = {
        items: [laboratorio]
    };
} else if (setorParse?.usuario?.setor === 'médico') {
    menuItems = {
        items: [doctorsOffice]
    };
} else if (role === 'Recepcao') {
    menuItems = {
        items: [recepcao]
    };
} else if (role === 'farmacia') {
    menuItems = {
        items: [estoque]
    };
} else if (role === 'Farm / Lab') {
    menuItems = {
        items: [estoque, laboratorio]
    };
} else if (role === 'Raio-x') {
    menuItems = {
        items: [xray]
    };
} else if (role == 'Farm/Escuta/Lab') {
    menuItems = {
        items: [estoque, laboratorio, triagem]
    };
} else if (role === 'salaEscuta') {
    menuItems = {
        items: [triagem, enfermagem]
    };
} else if (role === 'chamadas') {
    menuItems = {
        items: [chamadas]
    };
}

export default menuItems;
