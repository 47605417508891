import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TabelaPainelChamadas from './components/TabelaPainelChamadas';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { IconClock, IconClockHour10 } from '@tabler/icons';
import { useContext } from 'react';
import { ChamadaContext } from 'context/ChamadasContext';
const azureRegion = process.env.REACT_APP_azureRegion;
const azureKey = process.env.REACT_APP_azureKey;
import { urlSocketConnection } from '../../../connection';

const ChamadasPainel = () => {
    const api = useApi();
    const { atendimentos } = useContext(ChamadaContext);
    const [averageWaitTime, setAverageWaitTime] = useState('');
    const [isReady, setIsReady] = useState(false);
    const [val, setVal] = useState(null);
    const ws = useRef(null);
    const [data, setData] = useState([]);
    const [dataAtual, setDataAtual] = useState(new Date());
    const [pacienteAtual, setPacienteAtual] = useState(null);
    const [statusWindow, setStatusWindow] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const [callInitiated, setCallInitiated] = useState(false);

    const formatarNumero = (numero) => {
        return numero < 10 ? `0${numero}` : numero;
    };
    const dia = formatarNumero(dataAtual.getDate());
    const mes = formatarNumero(dataAtual.getMonth() + 1);
    const ano = dataAtual.getFullYear();

    const fetchAccessToken = async (azureRegion, azureKey) => {
        try {
            const response = await api.getAzureAccessKey(azureRegion, azureKey);
            return response.data;
        } catch (error) {
            toast.error('Erro ao obter token de acesso azure:', error);
        }
    };

    function sendPing(ws) {
        if (ws.current.readyState === WebSocket.OPEN) {
            ws.current.send('ping');
        }
    }

    // Chamada api Speech da azure
    const handleConvertTextToSpeech = async (azureRegion, azureKey) => {
        try {
            // oAuth do token AZURE
            const token = await fetchAccessToken(azureRegion, azureKey);
            // XML com o conteúdo da chamada
            const ssmlContent = `<speak version='1.0' xml:lang='pt-BR'><voice xml:lang='pt-BR' xml:gender='Female'
            name='pt-BR-FranciscaNeural'>
            ${pacienteAtual?.paciente}, ${pacienteAtual?.setor}
             </voice></speak>`;
            // Endpoint
            const ttsEndpoint = `https://${azureRegion}.tts.speech.microsoft.com/cognitiveservices/v1`;
            // Headers obrigatórios
            const response = await fetch(ttsEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/ssml+xml',
                    Authorization: `Bearer ${token}`,
                    'X-Microsoft-OutputFormat': 'audio-16khz-32kbitrate-mono-mp3',
                    'User-Agent': 'telaChamadas'
                },
                body: ssmlContent
            });
            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            setCallInitiated(true);
        } catch (error) {
            toast.error('Erro ao converter texto em fala:', error);
        }
    };
    // Controle de data e hora
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // Conexão socket
    useEffect(() => {
        const socket = new WebSocket(urlSocketConnection);

        socket.onopen = () => setIsReady(true);
        socket.onclose = () => setIsReady(false);
        socket.onmessage = (event) => setVal(event.data);

        ws.current = socket;

        return () => {
            socket.close();
        };
    }, []);

    // Ping na conexão do socket a cada 30 segundos (em teoria para não suspender a conexão após minutos sem uso)
    useEffect(() => {
        const pingInterval = setInterval(() => sendPing(ws), 30000);

        return () => {
            clearInterval(pingInterval);
        };
    }, []);

    // Controle dos objetos que chegam do websocket
    useEffect(() => {
        if (val) {
            try {
                const novoPacienteChamada = JSON.parse(val);
                const { ficha, senha, setor, paciente, data, hora, hora_chamada_completa } = novoPacienteChamada;

                setData((prev) => {
                    const novosCampos = [...prev];

                    const pacienteRepetido = novosCampos.find((item) => item.ficha === ficha && item.setor === setor);

                    // Caso seja uma segunda chamada do paciente
                    // apenas realiza a chamada, mas não acrescenta na tabela
                    if (pacienteRepetido) {
                        setPacienteAtual({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });
                    } else {
                        // Adiciona o novo item
                        if (novosCampos.length >= 5) {
                            novosCampos.shift();
                        }
                        novosCampos.push({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });
                        setPacienteAtual({ ficha, senha, setor, paciente, data, hora, hora_chamada_completa });
                    }

                    return novosCampos;
                });
            } catch (error) {
                toast.error('Erro ao realizar chamada:', error);
            }
        }
    }, [val]);

    // Chamada do paciente usando azure
    useEffect(() => {
        if (pacienteAtual && pacienteAtual.hora) {
            handleConvertTextToSpeech(azureRegion, azureKey);
        }
    }, [pacienteAtual]);

    // Reproduzir o audio da chamada que chega pela api da azure
    useEffect(() => {
        if (audioUrl && callInitiated) {
            const audio = new Audio(audioUrl);
            audio.play().then(() => {
                setCallInitiated(false);
            });
        }
    }, [audioUrl, callInitiated]);

    // Tela cheia ao entrar no componente
    useEffect(() => {
        let elemento = document.documentElement;
        elemento.requestFullscreen();
    }, []);

    useEffect(() => {
        if (atendimentos.length > 0) {
            const calculateAverageWaitTime = () => {
                const currentDate = new Date();
                const totalWaitTimeInSeconds = atendimentos.reduce((accumulator, record) => {
                    const recordDate = new Date(record.data_atendimento);
                    const timeDifference = currentDate.getTime() - recordDate.getTime();
                    return accumulator + Math.floor(timeDifference / 1000);
                }, 0);

                const averageWaitTimeInSeconds = totalWaitTimeInSeconds / atendimentos.length;
                const hours = Math.floor(averageWaitTimeInSeconds / 3600);
                const minutes = Math.floor((averageWaitTimeInSeconds % 3600) / 60);
                const seconds = Math.floor(averageWaitTimeInSeconds % 60);

                setAverageWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateAverageWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [atendimentos]);

    return (
        <Box sx={{ width: '100vw', padding: 0 }}>
            <Box
                sx={{
                    width: '100%',
                    height: '30px',
                    padding: '20px 40px'
                }}
            >
                <Box sx={{ display: 'flex', gap: '1rem', position: 'absolute', right: 40, top: '30px', alignItems: 'center' }}>
                    <IconClock size={60} color="#fff" />
                    <Typography variant="h4" sx={{ color: '#fff', fontSize: '2rem' }}>
                        {dia}/{mes}/{ano} {statusWindow ? '-' : ''}
                    </Typography>

                    <Typography variant="h4" sx={{ color: '#fff', fontSize: '2rem' }}>
                        {formatarNumero(dataAtual.getHours())}:{formatarNumero(dataAtual.getMinutes())}:
                        {formatarNumero(dataAtual.getSeconds())}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', maxWidth: '1400px', margin: '0 auto', position: 'relative' }}>
                <Box
                    sx={{
                        flex: 1,
                        minHeight: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        alignContent: 'space-between',
                        backgroundColor: '#02a6e5',
                        borderRadius: '6px',
                        padding: '1rem',
                        maxWidth: '880px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '1rem'
                        }}
                    >
                        <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                            PACIENTE:
                        </Typography>
                        <Typography variant="h1" sx={{ fontSize: '3.4rem', textAlign: 'center' }}>
                            {pacienteAtual?.paciente}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '550px',
                        minHeight: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#0f4c64',
                        borderRadius: '6px',
                        padding: '1rem',
                        position: 'absolute',
                        right: '10px',
                        top: '40px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '0.5rem',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1rem'
                        }}
                    >
                        <Typography sx={{ color: '#fff' }} variant="h2">
                            LOCAL:
                        </Typography>
                        <Typography variant="h1" sx={{ fontSize: '3.4rem', color: '#fff' }}>
                            {pacienteAtual?.setor}
                        </Typography>
                        {callInitiated && (
                            <audio style={{ display: 'none' }} controls autoPlay>
                                <track kind="captions" src="#" srclang="en" label="Portuguese" />
                                <source src={audioUrl} type="audio/mpeg" />
                                Seu navegador não suporta o elemento de áudio.
                            </audio>
                        )}
                    </Box>
                </Box>
            </Box>

            <Divider sx={{ width: '100%', margin: '1rem auto' }} />
            <Box sx={{ width: '90%', margin: '2rem auto' }}>
                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <IconClock size={30} />
                    <Typography variant="h3">ÚLTIMAS CHAMADAS</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                    <TabelaPainelChamadas data={data} />
                    <Box sx={{ width: '800px', display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
                        <Typography variant="h1" sx={{ fontSize: '3.4rem' }}>
                            JOANÓPOLIS - SP
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                            <h1 style={{ color: '#0f4c64', fontWeight: 'bold' }}>ESCUTA INICIAL</h1>
                            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '-25px' }}>
                                <IconClockHour10 color="#0f4c64" size={40} />
                                <h1 style={{ fontWeight: 'bold', fontSize: '40px' }}>{averageWaitTime}</h1>
                            </Box>
                            <h3 style={{ color: '#0f4c64', fontWeight: 'bold', marginTop: '-15px' }}>TEMPO MÉDIO DE ATENDIMENTO</h3>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ChamadasPainel;
