import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import PdfReceita from './PdfReceita';
import { toast } from 'react-toastify';
import { DoctorsSignatureConfirmationModal } from 'components/DoctorsSignatureConfirmationModal';
import { useParams } from 'react-router';
import { removeMask } from 'utils/removeMask';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    overflow: 'auto'
};

export default function MedicalPrescription({ open, setOpen, paciente }) {
    const stylePdf = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        height: '90vh'
    };

    const { prontuarioId, serviceId } = useParams();
    const api = useApi();

    const [prescription, setPrescription] = useState('');
    const [specialControl, setSpecialControl] = useState(false);
    const [openModalPdf, setOpenModalPdf] = useState(false);
    const [pdfData, setPdfData] = useState({});
    const [assinaturaId, setAssinaturaId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [doctorInfos, setDoctorInfos] = useState({});

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR'); // Formato DD/MM/YYYY
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }); // Formato HH:MM
        return { date, time };
    };
    const { date, time } = getCurrentDateTime();

    async function getcurrentUser() {
        const userString = localStorage.getItem('userInfos');
        const userParse = JSON.parse(userString);

        try {
            const { data } = await api.getUserById(userParse.usuario.id);
            console.log('🚀 ~ getcurrentUser ~ data:', data);
            const { crm_coren, nome_completo } = data?.usuario?.info_medico;
            setDoctorInfos({ crm: crm_coren, name: nome_completo });
        } catch (error) {
            console.log('🚀 ~ currentUser ~ error:', error);
        }
    }

    async function generatePdfBlob(paciente, prescricao, specialControl) {
        try {
            setLoading(true);
            // Gerar o blob do PDF
            const content = { paciente, prescricao: prescription, controle_especial: specialControl, doctor: doctorInfos };

            const document = <PdfReceita data={content} />;
            const asPdf = pdf(document);
            const pdfBlob = await asPdf.toBlob();
            // Iniciar transação no CESS
            const payload = {
                identificacao: removeMask(String(localStorage.getItem('userIdentity'))),
                paciente: paciente?.nome_completo,
                tipo: 'Receita',
                prescription,
                specialControl,
                paciente_id: paciente.id,
                prontuario_id: prontuarioId,
                serviceId: serviceId
            };
            const responseCess = await api.initTransactionCess(payload);
            // Enviar o arquivo para assinatura
            if (responseCess.status == 200 && responseCess.data) {
                const tcn = responseCess.data.tcn;
                localStorage.setItem('tcn', tcn);
                const formData = new FormData();
                formData.append('pdf', pdfBlob);
                formData.append('transactionTcn', tcn);
                const responseUpload = await api.uploadPdfCess(formData);
                if (responseUpload.status == 200) {
                    localStorage.removeItem('tcn');
                    const userInfo = JSON.parse(localStorage.getItem('userInfos'));
                    if (userInfo.usuario.setor != 'médico') {
                        localStorage.removeItem('userIdentity');
                    }
                }
            }
            window.location.reload();
        } catch (error) {
            setLoading(false);
            toast.error(`Erro ao gerar receita: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    useEffect(() => {
        getcurrentUser();
    }, []);

    return (
        <div>
            <Modal open={open}>
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>RECEITA MÉDICA</h3>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <p>{paciente?.nome_completo}</p>
                            <p>{`DATA/HORA DE ATENDIMENTO: ${date} ${time}`}</p>
                            <p>
                                {`
                          ${paciente?.endereco} 
                          ${paciente?.numero ? ', ' + paciente.numero : ''}  
                          ${paciente?.bairro ? ', ' + paciente.bairro : ''}  
                          ${paciente?.cidade ? ', ' + paciente.cidade : ''} 
                          ${paciente?.estado ? ', ' + paciente.estado : ''} 
                          ${paciente?.cep ? ', ' + paciente.cep : ''}
                          `}
                            </p>
                        </Box>
                        <Box>
                            <FormControlLabel
                                control={<Switch onChange={(e) => setSpecialControl(e.target.checked)} />}
                                label="Controle especial"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center', marginTop: '10px' }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="PRESCREVA"
                            value={prescription}
                            multiline
                            rows={4}
                            onChange={(e) => {
                                // const newPrescriptions = [...prescription];
                                // newPrescriptions[index].description = e.target.value;
                                setPrescription(e.target.value);
                            }}
                        />
                    </Box>

                    <Modal
                        open={openModalPdf}
                        onClose={() => {
                            setOpenModalPdf(false);
                            handleClose();
                        }}
                    >
                        <Box sx={stylePdf}>
                            <PDFViewer width={'100%'} height={'100%'}>
                                <PdfReceita data={pdfData} />
                            </PDFViewer>
                        </Box>
                    </Modal>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button
                            variant="contained"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                setOpen(false);
                                window.location.reload();
                            }}
                            sx={{ marginRight: '10px' }}
                        >
                            Cancelar Receita
                        </Button>
                        {loading && (
                            <Button variant="contained" disabled>
                                Aguarde...
                            </Button>
                        )}
                        {!loading && (
                            <DoctorsSignatureConfirmationModal
                                buttonText="Prescrever"
                                confirmFunction={() => {
                                    generatePdfBlob(paciente, prescription, specialControl);
                                }}
                                setAssinaturaId={setAssinaturaId}
                            />
                        )}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
