import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import SantaCasaLogo from '../../../assets/images/Logos/logo2.png';
import BwLogo from '../../../assets/BW TECNOLOGIA.png';

const RelatorioFichaMedica = ({ data }) => {
    const styles = {
        page: {
            paddingTop: 35,
            paddingBottom: 90,
            paddingHorizontal: 15,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            padding: 10,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        table: {
            display: 'table',
            width: 'auto',
            margin: '10px 0 0 0'
        },
        tableRow: {
            flexDirection: 'row'
        },
        tableCol: {
            width: '25%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#000',
            padding: 5
        },
        tableCell: {
            fontSize: 9
        }
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR'); // Formato DD/MM/YYYY
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }); // Formato HH:MM
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();
    console.log(data);
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '.5em',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '20px',
                        borderTop: '1px solid #000'
                    }}
                >
                    <Image src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '8%', margin: '0 auto' }} />
                    <View style={{ textAlign: 'center', fontSize: 7 }}>
                        <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                    </View>
                </View>
                <View style={{ marginTop: '15px' }}>
                    <Text style={{ textAlign: 'center', marginBottom: '15px' }}>RELATÓRIO DE FICHA MÉDICA</Text>

                    <View style={{ display: 'flex', justifyContent: 'space-between', fontSize: 9 }}>
                        <View>
                            <Text>{data.paciente?.nome_completo}</Text>
                            <Text>{`DATA/HORA DE ATENDIMENTO: ${date} ${time}`}</Text>
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <Text>{data.paciente?.endereco} </Text>
                                <Text>{data.paciente?.numero ? ', ' + data.paciente.numero : ''}</Text>
                                <Text>{data.paciente?.bairro ? ', ' + data.paciente.bairro : ''}</Text>
                                <Text>{data.paciente?.cidade ? ', ' + data.paciente.cidade : ''}</Text>
                                <Text>{data.paciente?.estado ? ', ' + data.paciente.estado : ''}</Text>
                                <Text>{data.paciente?.cep ? ', ' + data.paciente.cep : ''}</Text>
                            </View>
                            <Text>STATUS F.A: {data?.prontuario?.status}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={{ textAlign: 'center', marginTop: '8px' }}>ESCUTA INICIAL</Text>
                </View>
                <View>
                    <Text>QUEIXA: {data?.triagem?.dados.queixa ?? 'NÃO INFORMADA'}</Text>
                </View>
                <View>
                    <Text>
                        DATA/HORA: {data?.triagem?.dados?.data} - {data?.triagem?.dados?.hora}
                    </Text>
                </View>
                <View>
                    <Text>RESPONSÁVEL: {data?.triagem?.responsavel}</Text>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>SIS</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>DIA</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>FC</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>FR</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>DEX</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>PESO</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>ALT</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>IMC</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>TEMP</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>SAT</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.pa_1}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.pa_2}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.fc}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.fr}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.dextro}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.peso}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.altura}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.imc}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.temperatura}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data?.triagem?.dados.saturacao}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={{ textAlign: 'center', marginTop: '8px' }}>MEDICAMENTOS | APLICAÇÃO</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>ENF ID</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>DESCRIÇÃO</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>UNIDADE</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>QUANTIDADE</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>VIA APLICAÇÃO</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>DATA / HORA</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>RESPONSÁVEL</Text>
                        </View>
                    </View>
                    {data?.medicamentos?.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.id}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.descricao}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.unidade}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.qtd}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.aplicacao}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.hora_aplicacao_formatada ?? 'N/A'}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.usuario?.nome}</Text>
                            </View>
                        </View>
                    ))}
                </View>
                <View>
                    <Text style={{ textAlign: 'center', marginTop: '8px' }}>LABORATÓRIO | COLETA</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>ENF ID</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>DESCRIÇÃO</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>FINALIZADO</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>DATA / HORA</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>RESPONSÁVEL</Text>
                        </View>
                    </View>
                    {data?.laboratorio?.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.id}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.descricao}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {item?.tipo === 'LABORATÓRIO' &&
                                    item.atendimentos_enfermagems.every((atendimento) => atendimento.status === 'REALIZADO')
                                        ? 'SIM'
                                        : 'NÃO'}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.hora_coleta_formatada ?? 'N/A'}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.usuario?.nome}</Text>
                            </View>
                        </View>
                    ))}
                </View>
                <View>
                    <Text style={{ textAlign: 'center', marginTop: '8px' }}>ATESTADOS | RECEITAS</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>ID</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>TIPO</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>DATA / HORA</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>RESPONSÁVEL</Text>
                        </View>
                    </View>
                    {data?.atestado_receita?.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.id}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.tipo}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>'-'</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.medico ?? 'N/A'}</Text>
                            </View>
                        </View>
                    ))}
                </View>

                <View>
                    <Text style={{ textAlign: 'center', marginTop: '8px' }}>PROCEDIMENTOS ENFERMAGEM CERTIFICADOS</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>ID</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>TIPO</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>DATA / HORA</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>RESPONSÁVEL</Text>
                        </View>
                    </View>
                    {data?.procedimentos_enfermagem?.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.id}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.type}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.date ?? 'N/A'}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.user}</Text>
                            </View>
                        </View>
                    ))}
                </View>
                <View>
                    <Text style={{ textAlign: 'center', marginTop: '8px' }}>RELATÓRIO DE ACOMPANHAMENTO</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={{ width: '10%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                            <Text style={styles.tableCell}>DATA</Text>
                        </View>
                        <View style={{ width: '10%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                            <Text style={styles.tableCell}>HORA</Text>
                        </View>
                        <View style={{ width: '20%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                            <Text style={styles.tableCell}>TIPO</Text>
                        </View>
                        <View style={{ width: '15%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                            <Text style={styles.tableCell}>RESPONSÁVEL</Text>
                        </View>
                        <View style={{ width: '45%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                            <Text style={styles.tableCell}>CONTEÚDO</Text>
                        </View>
                    </View>
                    {data?.relatorioAcompanhamento?.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={{ width: '10%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                                <Text style={styles.tableCell}>{item.data}</Text>
                            </View>
                            <View style={{ width: '10%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                                <Text style={styles.tableCell}>{item?.hora}</Text>
                            </View>

                            <View style={{ width: '20%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                                <Text style={styles.tableCell}>{item?.tipo}</Text>
                            </View>
                            <View style={{ width: '15%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                                <Text style={styles.tableCell}>{item?.user}</Text>
                            </View>
                            <View style={{ width: '45%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                                <Text style={styles.tableCell}>{item?.conteudo}</Text>
                            </View>
                        </View>
                    ))}
                </View>

                <View>
                    <Text style={{ textAlign: 'center', marginTop: '8px' }}>RAIO-X / POCEDIMENTOS</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={{ width: '10%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                            <Text style={styles.tableCell}>CÓDIGO</Text>
                        </View>
                        <View style={{ width: '50%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                            <Text style={styles.tableCell}>DESCRIÇÃO</Text>
                        </View>
                        <View style={{ width: '20%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                            <Text style={styles.tableCell}>TIPO</Text>
                        </View>
                        <View style={{ width: '20%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                            <Text style={styles.tableCell}>STATUS</Text>
                        </View>
                    </View>
                    {data?.raiox_procedimentos?.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={{ width: '10%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                                <Text style={styles.tableCell}>{item.cod}</Text>
                            </View>
                            <View style={{ width: '50%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                                <Text style={styles.tableCell}>{item?.descricao}</Text>
                            </View>

                            <View style={{ width: '20%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                                <Text style={styles.tableCell}>{item?.tipo}</Text>
                            </View>
                            <View style={{ width: '20%', borderStyle: 'solid', borderWidth: 1, borderColor: '#000', padding: 5 }}>
                                <Text style={styles.tableCell}>{item?.item_conduta?.finalizado ? 'FINALIZADO' : 'AGUARDANDO'}</Text>
                            </View>
                        </View>
                    ))}
                </View>
                <View>
                    <Text style={{ textAlign: 'center', marginTop: '8px' }}>PRINCIPAIS DADOS DE ANAMNESE EXAME FÍSICO</Text>
                </View>
                <View
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        minHeight: '120px'
                    }}
                >
                    <Text>{data?.medicalRecordObs}</Text>
                </View>
                <View>
                    <Text style={{ textAlign: 'center', marginTop: '8px' }}>PRINCIPAIS PRESCRIÇÃO MÉDICA E ANOTAÇÕES DE ENFERMAGEM</Text>
                </View>
                <View
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        minHeight: '120px'
                    }}
                ></View>
                <View fixed style={styles.fixed}>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Image fixed src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '30px' }} />
                        <View
                            fixed
                            style={{
                                padding: '20px',
                                gap: '5px',
                                fontSize: 5
                            }}
                        >
                            <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                            <Text>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Text>
                            <Text>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                        </View>
                    </View>
                    <Image style={{ display: 'flex', width: '15%', justifyContent: 'flex-end', alignItems: 'center' }} src={BwLogo} />
                </View>
            </Page>
        </Document>
    );
};

export default RelatorioFichaMedica;
