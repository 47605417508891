import { Autocomplete, Box, Button, Divider, Drawer, TextField } from '@mui/material';
import { IconDeviceFloppy, IconPlus } from '@tabler/icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { v4 } from 'uuid';

export function AddItemDrawer({ setRows, disableButton }) {
    const [open, setOpen] = useState(false);
    const api = useApi();
    const [selectedProcedimento, setSelectedProcedimento] = useState(null);
    const [procedimentosList, setProcedimentosList] = useState([]);
    const [qtd, setQtd] = useState('');

    async function handleAddItem() {
        const newRow = {
            allinfos: selectedProcedimento,
            id: v4(),
            codgo: selectedProcedimento?.codigo,
            descricao: selectedProcedimento?.descricao,
            quantidade: qtd,
            tipo: selectedProcedimento?.tipo,
            item_novo: true
        };
        setRows((prev) => [...prev, newRow]);
        setOpen(false);
        toast.success('Item adicionado com sucesso');
        setSelectedProcedimento(null);
        setQtd('');
    }

    async function getAllProcedimentosData() {
        try {
            const { data } = await api.getInvoicing();
            const uniqueProcedimentos = data.reduce((acc, item) => {
                if (!acc.some((proc) => proc.descricao === item.descricao)) {
                    acc.push(item);
                }
                return acc;
            }, []);

            setProcedimentosList(uniqueProcedimentos);
        } catch (error) {
            console.log('🚀 ~ getAllProcedimentos ~ error:', error);
        }
    }

    useEffect(() => {
        if (open) getAllProcedimentosData();
    }, [open]);

    return (
        <>
            <Button onClick={() => setOpen(true)} variant="contained" disabled={disableButton}>
                <IconPlus size={20} />
            </Button>
            <Drawer open={open} onClose={() => setOpen(false)} anchor="right" sx={{ zIndex: 10000 }}>
                <Box sx={{ width: 400, padding: 2 }}>
                    <h3>Procedimento</h3>
                    <Divider />

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, margin: '20px 0' }}>
                        <Autocomplete
                            disablePortal
                            options={procedimentosList}
                            getOptionLabel={(option) => option.descricao}
                            fullWidth
                            value={selectedProcedimento}
                            onChange={(event, newValue) => {
                                setSelectedProcedimento(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="SELECIONE O PROCEDIMENTO" />}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            sx={{ flex: 1 }}
                            label="QUANTIDADE"
                            fullWidth
                            type="number"
                            onChange={(e) => setQtd(e.target.value)}
                            value={qtd}
                        />

                        <Button variant="contained" fullWidth onClick={handleAddItem}>
                            <IconDeviceFloppy />
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
}
