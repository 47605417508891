import { Box, Grid, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import TempoMedio from 'components/Triagem/Cards/TempoMedio';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { TimerArea } from './styles';
import { DataGrid } from '@mui/x-data-grid';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useEffect, useState } from 'react';
import { AnalysesModal } from './components/AnalyseModal';
import { useApi } from 'Service/axios';
import { formatarData } from 'utils/formatarData';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Laco from '../../../../assets/images/Logos/laco.jpg';

export function CustomerServiceFarmaciaAtendimentos() {
    const api = useApi();
    const navigate = useNavigate();
    const [openAnalyse, setOpenAnalyse] = useState(false);
    const [rows, setRows] = useState([]);
    const [defaultRows, setDefaultRows] = useState([]);
    const [averageWaitTime, setAverageWaitTime] = useState('');
    const [waitTime, setWaitTime] = useState('');
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    function navigateToItensDetails(id, nome) {
        navigate(`/main/estoque/farmacia/atendimentos/baixa/${id}/${nome}`);
    }

    async function getAllProntuario() {
        try {
            const { data } = await api.getAllPedidosMedicamentoAbertos();

            const row = data.map((item) => ({
                ...item,
                id: item?.prontuario_id,
                patient: item?.paciente?.nome_completo,
                age: item?.paciente?.idade + ' anos',
                date: item?.dia,
                hour: item?.hora,
                //situation: item?.atendimentos_enfermagems.every((item) => item.status == 'SEPARACAO') ? 'ESPERA SEPARACAO' : 'LIBERADO',
                createdAt: item?.createdAt,
                autismo: item?.paciente?.autismo
            }));

            // Ordenar os dados por data e hora
            // const sortedRows = row.sort((a, b) => {
            //     // Combinar data e hora em um único string
            //     const dateA = new Date(a.date.split('/').reverse().join('-') + 'T' + a.hour);
            //     const dateB = new Date(b.date.split('/').reverse().join('-') + 'T' + b.hour);
            //     return dateA - dateB; // Ordem crescente (mais antigo para mais novo)
            // });

            setRows(row);
            setDefaultRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    // CALCULO DE TEMPO DE ESPERA
    useEffect(() => {
        if (rows.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(rows[0]?.createdAt);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [rows]);

    // CALCULO DE TEMPO MEDIO
    useEffect(() => {
        if (rows.length > 0) {
            const calculateAverageWaitTime = () => {
                const currentDate = new Date();
                const totalWaitTimeInSeconds = rows.reduce((accumulator, record) => {
                    const recordDate = new Date(record.createdAt);
                    const timeDifference = currentDate.getTime() - recordDate.getTime();
                    return accumulator + Math.floor(timeDifference / 1000);
                }, 0);

                const averageWaitTimeInSeconds = totalWaitTimeInSeconds / rows.length;
                const hours = Math.floor(averageWaitTimeInSeconds / 3600);
                const minutes = Math.floor((averageWaitTimeInSeconds % 3600) / 60);
                const seconds = Math.floor(averageWaitTimeInSeconds % 60);

                setAverageWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateAverageWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [rows]);

    useEffect(() => {
        if (rows.length === 0) {
            getAllProntuario();
        }
    }, []);

    useEffect(() => {
        if (checked) {
            setRows(defaultRows.filter((i) => i.pedido_medicacao?.prontuario?.interno));
        } else {
            setRows(defaultRows.filter((i) => !i.pedido_medicacao?.prontuario?.interno));
        }
    }, [checked]);

    const columns = [
        { field: 'id', headerName: 'F.A', flex: 0.5 },
        {
            field: 'patient',
            headerName: 'PACIENTE',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {params.row.patient}
                    {params.row.autismo && <img src={Laco} alt="laco" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />}
                </Box>
            )
        },
        { field: 'age', headerName: 'IDADE', flex: 0.5 },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'hour', headerName: 'HORA', flex: 0.5 },
        //{ field: 'situation', headerName: 'Situação', flex: 1 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title="Iniciar Baixa">
                    <IconButton
                        onClick={() => {
                            navigateToItensDetails(params.row.id, params.row.patient);
                        }}
                    >
                        <InventoryIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    return (
        <MainCard title="ATENDIMENTOS">
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <AnalysesModal open={openAnalyse} setOpen={setOpenAnalyse} />
                <TimerArea>
                    <TempoMedio tempoMedio={averageWaitTime} />
                    <TempoEspera tempoEspera={waitTime} />
                    <Aguardando aguardandoQTD={rows.length} />
                </TimerArea>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Box sx={{ display: 'flex', gap: '.4rem', marginLeft: 'auto', alignItems: 'center' }}>
                        <Typography>Interno</Typography>
                        <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                    </Box>
                    <DataGrid rows={rows} columns={columns} hideFooter={true} />
                </Box>
            </Grid>
        </MainCard>
    );
}
