import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import SantaCasaLogo from '../../../assets/images/Logos/logo2.png';
import BwLogo from '../../../assets/BW TECNOLOGIA.png';

const MedicineApplication = ({ data }) => {
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 15,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            padding: 10,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        table: {
            display: 'table',
            width: 'auto',
            margin: '10px 0 0 0'
        },
        tableRow: {
            flexDirection: 'row'
        },
        tableCol: {
            width: '25%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#000',
            padding: 5
        },
        tableCell: {
            fontSize: 9
        }
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR'); // Formato DD/MM/YYYY
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }); // Formato HH:MM
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '.5em',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '20px',
                        borderTop: '1px solid #000'
                    }}
                >
                    <Image src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '8%', left: '250px' }} />
                    <View style={{ left: '180px', fontSize: 7 }}>
                        <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                    </View>
                </View>
                <View style={{ marginTop: '15px' }}>
                    <Text style={{ textAlign: 'center', marginBottom: '15px' }}>RELATÓRIO DE APLICAÇÃO</Text>

                    <View style={{ display: 'flex', justifyContent: 'space-between', fontSize: 9 }}>
                        <View>
                            <Text>{data.paciente?.nome_completo}</Text>
                            <Text>{`DATA/HORA DE ATENDIMENTO: ${date} ${time}`}</Text>
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <Text>{data.paciente?.endereco} </Text>
                                <Text>{data.paciente?.numero ? ', ' + data.paciente.numero : ''}</Text>
                                <Text>{data.paciente?.bairro ? ', ' + data.paciente.bairro : ''}</Text>
                                <Text>{data.paciente?.cidade ? ', ' + data.paciente.cidade : ''}</Text>
                                <Text>{data.paciente?.estado ? ', ' + data.paciente.estado : ''}</Text>
                                <Text>{data.paciente?.cep ? ', ' + data.paciente.cep : ''}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Enf ID</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Descrição</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Unidade</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Quantidade</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Via Aplicação</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Data / Hora</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Responsável</Text>
                        </View>
                    </View>
                    {data?.medicamentos?.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.id}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.conduta_medica?.item_conduta?.item_pedido?.descricao}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.conduta_medica?.item_conduta?.item_pedido?.unidade?.descricao}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.conduta_medica?.item_conduta?.quantidade_item}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {item?.conduta_medica?.item_conduta?.via_aplicacao?.length > 0
                                        ? item.conduta_medica.item_conduta.via_aplicacao
                                        : 'N/A'}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.hora_aplicacao_formatada ?? 'N/A'}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item?.usuario?.nome}</Text>
                            </View>
                        </View>
                    ))}
                </View>

                <View fixed style={styles.fixed}>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Image fixed src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '30px' }} />
                        <View
                            fixed
                            style={{
                                padding: '20px',
                                gap: '5px',
                                fontSize: 5
                            }}
                        >
                            <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                            <Text>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Text>
                            <Text>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                        </View>
                    </View>
                    <Image style={{ display: 'flex', width: '15%', justifyContent: 'flex-end', alignItems: 'center' }} src={BwLogo} />
                </View>
            </Page>
        </Document>
    );
};

export default MedicineApplication;
