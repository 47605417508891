import {
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Modal,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const MedicalFinalizationModal = ({ open, setOpenFinalizationModal, prontuarioId, rows, generateMedicinePdfBlob }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: 2
    };
    const { serviceId } = useParams();
    const navigate = useNavigate();
    const api = useApi();
    const [value, setValue] = useState('');
    const [observation, setObservation] = useState('');
    const [serviceOpen, setServiceOpen] = useState(false);

    function handleChange(event) {
        setValue(event.target.value);
    }

    function handleClose() {
        setValue('');
        setOpenFinalizationModal(false);
    }

    function checkIfServiceIsNotFinished() {
        const statusServices = !rows.every((element) => element?.finalizado);
        setServiceOpen(statusServices);
    }

    // FUNÇÃO RESPONSÁVEL POR MAPEAR O VALUE RECEBIDO COMO STRING DO INPUT RADIO E MONTAR O PAYLOAD TRUE/FALSE
    function getTypeFinalization(value, observacao) {
        const defaultPayload = {
            alta: false,
            alta_consultorio: false,
            alta_medicacao_procedimento_cuidado: false,
            alta_retirar_acesso: false,
            retorno_medicacao_exame: false,
            encaminhar_internacao: false,
            obito: false,
            transferencia: false,
            observacao: observacao || ''
        };

        const valueMapping = {
            alta: 'alta',
            retorno_medicacao_exame: 'retorno_medicacao_exame',
            alta_medicacao_procedimento_cuidado: 'alta_medicacao_procedimento_cuidado',
            encaminhar_internacao: 'encaminhar_internacao',
            obito: 'obito',
            transferencia: 'transferencia',
            alta_consultorio: 'alta_consultorio',
            alta_retirar_acesso: 'alta_retirar_acesso'
        };

        if (valueMapping[value]) {
            return {
                ...defaultPayload,
                [valueMapping[value]]: true
            };
        }

        return defaultPayload;
    }

    async function submitFinalization(prontuarioId, payload, finalizationStatus) {
        try {
            const { status } = await api.finalizarConduta(prontuarioId, payload);
            localStorage.setItem('option', 'finalizacao-conduta');
            await endingService();
            if (status == 200 && finalizationStatus) {
                const payloadPopularFaturamento = { prontuario_id: prontuarioId, condutas: rows };
                await api.popularFaturamento(payloadPopularFaturamento);
                const criarRelatorio = await generateMedicinePdfBlob();

                if (criarRelatorio) {
                    handleClose();
                    navigate('/main/consultorio/atendimento');
                }
            }
            setOpenFinalizationModal(true);
            // window.location.reload();
            navigate('/main/consultorio/atendimento');
            setLoading(false);
            toast.success('Sucesso ao finalizar conduta');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }
    const [loading, setLoading] = useState(false);
    async function handleFinalization() {
        setLoading(true);
        // Extrair o payload true/false
        const dadosFinalizacao = getTypeFinalization(value, observation);
        const statusNecessidadeCertificado = !dadosFinalizacao?.retorno_medicacao_exame;
        await submitFinalization(prontuarioId, dadosFinalizacao, statusNecessidadeCertificado);
    }

    useEffect(() => {
        // Verifica se algum atendimento da enfermagem está em aberto
        // if TRUE -> não libera a opção de alta
        checkIfServiceIsNotFinished();
    }, [open]);

    async function endingService() {
        const userId = JSON.parse(localStorage.getItem('id'));
        const payload = {
            atendimento_id: serviceId,
            prontuario_id: prontuarioId,
            responsavel_id: userId,
            status_finalizacao_conduta: value
        };
        try {
            await api.endingService(payload);
        } catch (error) {
            console.log('🚀 ~ endingService ~ error:', error);
        }
    }

    return (
        <Box>
            <Modal open={open}>
                <Box sx={style}>
                    <Typography variant="h2" sx={{ textAlign: 'center' }}>
                        TIPO DE FINALIZAÇÃO DA CONDUTA
                    </Typography>
                    <Box
                        sx={{
                            padding: '10px',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '.7rem',
                            borderRadius: '7px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'start' }}>
                            <FormControl>
                                <FormLabel id="Alta">Alta</FormLabel>
                                <RadioGroup aria-labelledby="Alta" value={value} onChange={handleChange}>
                                    <FormControlLabel value="alta" control={<Radio />} label="Alta" disabled={serviceOpen} />
                                    <FormControlLabel value="alta_consultorio" control={<Radio />} label="ALTA / CONSULTÓRIO" />
                                    <FormControlLabel value="alta_retirar_acesso" control={<Radio />} label="ALTA / RETIRADA DE ACESSO" />
                                    <FormControlLabel
                                        value="alta_medicacao_procedimento_cuidado"
                                        control={<Radio />}
                                        label="ALTA APÓS MEDICAÇÃO / PROCEDIMENTO / CUIDADO"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <Divider orientation="vertical" flexItem sx={{ marginRight: '1rem' }} />
                            <Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                    <FormControl>
                                        <FormLabel id="OBITO">ÓBITO</FormLabel>
                                        <RadioGroup aria-labelledby="OBITO" value={value} onChange={handleChange}>
                                            <FormControlLabel value="obito" control={<Radio />} label="ÓBITO" />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel id="TRANSFERENCIA">TRANSFERÊNCIA</FormLabel>
                                        <RadioGroup aria-labelledby="TRANSFERENCIA" value={value} onChange={handleChange}>
                                            <FormControlLabel value="transferencia" control={<Radio />} label="TRANSFERÊNCIA" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>

                                <FormControl>
                                    <FormLabel id="RETORNO_ENCAMINHAMENTO">RETORNO / ENCAMINHAMENTO</FormLabel>
                                    <RadioGroup aria-labelledby="RETORNO_ENCAMINHAMENTO" value={value} onChange={handleChange}>
                                        <FormControlLabel
                                            value="retorno_medicacao_exame"
                                            control={<Radio />}
                                            label="RETORNO APÓS MEDICAÇÃO / EXAME"
                                        />
                                        <FormControlLabel
                                            value="encaminhar_internacao"
                                            control={<Radio />}
                                            label="ENCAMINHAR PARA INTERNAÇÃO"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>

                        <TextField
                            multiline
                            label="Observação"
                            minRows={4}
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '1rem' }}>
                        <Button variant="contained" color="error" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={handleFinalization} disabled={loading}>
                            {loading ? <CircularProgress size={16} /> : 'Confirmar'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default MedicalFinalizationModal;
