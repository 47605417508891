import { Box, TextField } from '@mui/material';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import CalculateIcon from '@mui/icons-material/Calculate';
import { IconLungs, IconArrowAutofitHeight, IconThermometer, IconFileAnalytics, IconPercentage } from '@tabler/icons';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { formatarDataIdade } from 'utils/dataIdade';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

export function ProntuarioInfos() {
    const { prontuarioId, pacienteId, name } = useParams();
    const api = useApi();
    const [medicine, setMedicine] = useState({});
    const [triagem, setTriagem] = useState({});
    const [paciente, setPaciente] = useState({});
    const [prontuario, setProntuario] = useState({});

    async function getRecord() {
        try {
            const { data } = await api.getMedicalRecord(prontuarioId, pacienteId);
            setMedicine(data?.medicacao);
            setTriagem(data?.triagem);
            const res = await api.getPacienteById(pacienteId);
            setPaciente(res.data);
            // handleMedicalRecord();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }
    useEffect(() => {
        getRecord();
    }, []);

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', position: 'absolute', right: 0, top: '-60px' }}>
                <p>
                    PRONTUÁRIO: <b>{triagem?.prontuario_id}</b>
                </p>
                <p>
                    IDADE: <b> {formatarDataIdade(paciente?.data_nascimento)}</b>
                </p>
            </Box>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h4>ESCUTA INICIAL</h4>

                    <h4>
                        RESPONSÁVEL: <b>{triagem?.usuario?.nome}</b>
                    </h4>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <MonitorHeartIcon />
                        <TextField
                            label="SIS"
                            type="number"
                            value={triagem?.pa_1}
                            name="pa_sis"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '130px', marginLeft: '.5rem' }}
                        />
                        <CloseIcon sx={{ margin: '0 5px' }} />
                        <TextField
                            label="DIA"
                            type="number"
                            placeholder="DIA"
                            value={triagem?.pa_2}
                            name="pa_dia"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '130px' }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <FavoriteIcon />
                        <TextField
                            label="FC"
                            type="number"
                            value={triagem?.fc}
                            name="fc"
                            InputLabelProps={{ shrink: true }}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <IconLungs />
                        <TextField
                            label="FR"
                            type="number"
                            value={triagem?.fr}
                            name="fc"
                            InputLabelProps={{ shrink: true }}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <VaccinesIcon />
                        <TextField
                            label="DEXTRO"
                            type="number"
                            value={triagem?.dextro}
                            name="fc"
                            InputLabelProps={{ shrink: true }}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <MonitorWeightIcon />
                        <TextField
                            disabled
                            label="Peso"
                            name="peso"
                            type="text"
                            value={triagem.peso}
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '130px', marginLeft: '.5rem' }}
                        />
                        <IconArrowAutofitHeight sx={{ margin: '0 5px' }} />
                        <TextField
                            disabled
                            label="Altura"
                            name="altura"
                            type="text"
                            value={triagem.altura}
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '130px', marginLeft: '.5rem' }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CalculateIcon />
                        <TextField
                            label="IMC"
                            name="IMC"
                            type="number"
                            value={triagem.imc}
                            disabled
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <IconThermometer />
                        <TextField
                            label="Temperatura"
                            name="temperatura"
                            type="text"
                            value={triagem?.temperatura}
                            disabled
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <IconPercentage />
                        <TextField
                            label="Saturação"
                            name="saturacao"
                            type="number"
                            value={triagem?.saturacao}
                            disabled
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                </Box>
                <Box sx={{ marginTop: '10px' }}>
                    QUEIXA: <b>{triagem?.queixa ? triagem?.queixa : 'QUEIXA NÃO INFORMADA'}</b>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p>
                        MÉDICAÇÃO EM USO:{' '}
                        {medicine?.medicacao_em_uso?.length === 0
                            ? 'NEGA'
                            : medicine?.medicacao_em_uso?.map((item, index, array) => (
                                  <span key={index}>{array.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                              ))}
                    </p>
                    <p>
                        ALERGIA:{' '}
                        {medicine?.alergia?.length === 0
                            ? 'NEGA'
                            : medicine?.alergia?.map((item, index, array) => (
                                  <span key={index}>{array.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                              ))}
                    </p>
                </Box>
            </Box>
        </Box>
    );
}
