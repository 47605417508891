import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const TabelaPainelChamadas = ({ data }) => {
    return (
        <TableContainer component={Paper} sx={{ borderRadius: '5px', maxHeight: '370px', width: '100%' }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>PACIENTE</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>HORA</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>LOCAL</b>
                        </TableCell>
                        {/* <TableCell align="center">
                            <b>DATA</b>
                        </TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data
                            .slice()
                            .reverse()
                            .map((item) => (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                                            {item?.paciente}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                                            {item?.hora}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                                            {item?.setor}
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                                            {item?.data}
                                        </Typography>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabelaPainelChamadas;
