import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import Logo from '../../../../../assets/images/Logos/logo2.png';

const ReportPdf = ({ data, name, id }) => {
    console.log('🚀 ~ ReportPdf ~ data:', data);

    // Use StyleSheet do react-pdf para definir estilos
    const styles = StyleSheet.create({
        page: {
            fontSize: 10,
            padding: 5
        },
        header: {
            flexDirection: 'column',
            alignItems: 'center'
        },
        image: {
            width: 70, // Largura do logo em pt
            height: 'auto', // Ajuste automático da altura
            marginBottom: 5
        },
        text: {
            textAlign: 'center',
            color: '#ffffff' // Texto branco para contraste
        }
    });

    return (
        <Document>
            <Page size={[226.77, undefined]} style={styles.page}>
                <View style={styles.header}>
                    <Image src={Logo} style={styles.image} />
                    <Text style={styles.text}>Relatório de Farmácia</Text>
                </View>
                <View>
                    <Text>
                        Paciente: <Text style={{ color: '#000', fontWeight: 'extrabold', fontSize: '11px' }}>{name}</Text>
                    </Text>
                    <Text>
                        F.A:<Text style={{ color: '#000', fontWeight: 'extrabold', fontSize: '11px' }}>{id}</Text>
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>
                            Data:
                            <Text style={{ color: '#000', fontWeight: 'extrabold', fontSize: '11px' }}>
                                {new Date().toLocaleDateString()}
                            </Text>
                        </Text>
                        <Text>
                            Hora:{' '}
                            <Text style={{ color: '#000', fontWeight: 'extrabold', fontSize: '11px' }}>
                                {new Date().toLocaleTimeString()}
                            </Text>
                        </Text>
                    </View>

                    <Text style={{ textAlign: 'center' }}>------------------------------------------------------------</Text>
                </View>

                <View>
                    {data.map((item, index, array) => (
                        <View>
                            <Text>Medicamento: {item?.item}</Text>
                            <Text>Quantidade: {item?.qtd}</Text>
                            <Text>Aplicação: {item?.viaAplicacao ? item?.viaAplicacao : '-'}</Text>
                            {array.length - 1 !== index && (
                                <Text style={{ textAlign: 'center' }}>------------------------------------------------------------</Text>
                            )}
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );
};

export default ReportPdf;
