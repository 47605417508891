import { IconClipboardPlus, IconAdjustments, IconFolderPlus } from '@tabler/icons';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';

const icons = {
    IconClipboardPlus,
    IconAdjustments,
    TroubleshootIcon,
    IconFolderPlus,
    PersonalVideoIcon
};

const procedimentos = {
    id: 'procedimentos',
    title: 'Procedimentos',
    type: 'group',
    children: [
        {
            id: 'pedidosProcedimentos',
            title: 'Pedidos',
            type: 'collapse',
            icon: icons.IconFolderPlus,
            children: [
                // {
                //     id: 'atendimento-procedimentos',
                //     title: 'Atendimentos Pendentes',
                //     type: 'item',
                //     url: '/main/atendimento/procedimentos',
                //     icon: icons.IconClipboardPlus,
                //     breadcrumbs: false
                // },
                {
                    id: 'consultaProcedimentos',
                    title: 'Consulta',
                    type: 'item',
                    url: '/main/atendimento/procedimentos/consulta',
                    icon: icons.TroubleshootIcon,
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'register-procedimentos',
            title: 'Cadastro',
            type: 'item',
            url: '/main/cadastro/procedimentos',
            icon: icons.IconAdjustments,
            breadcrumbs: false
        }
    ]
};

export default procedimentos;
