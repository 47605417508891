import { Autocomplete, Box, Button, Grid, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import NewItemModel from './components/NewItemModel';
import SaveIcon from '@mui/icons-material/Save';
import { v4 as uuidv4 } from 'uuid';
import DeleteItemModal from './components/DeleteItemModal/DeleteItemModal';
import { LoadBw } from 'components/LoadBw';
import ModalPdf from './components/ModalPdf';
import { IconPrinter } from '@tabler/icons';

export function WriteOffItensFromStock() {
    const { id, nome } = useParams();
    const api = useApi();
    const navigate = useNavigate();
    const responsavelId = parseInt(localStorage.getItem('id'));
    const [openModalNewItem, setOpenModalNewItem] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [observationDeleteItem, setObservationDeleteItem] = useState('');
    const [deleteItemUUID, setDeleteItemUUID] = useState(null);
    const [rows, setRows] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [itemEdit, setItemEdit] = useState(null);
    const [allCentros, setAllCentros] = useState([]);
    const [selectedCentro, setSelectedCentro] = useState(null);

    const [loading, setLoading] = useState(false);
    const [openModalPdf, setOpenModalPdf] = useState(false);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.2 },
        { field: 'item', headerName: 'ITEM', flex: 1 },
        { field: 'qtd', headerName: 'QUANTIDADE', flex: 1 },
        { field: 'unidade', headerName: 'UNIDADE', flex: 1 },
        { field: 'observacao', headerName: 'OBSERVACAO', flex: 1 },
        { field: 'viaAplicacao', headerName: 'Via de aplicação', flex: 0.5 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.5,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Baixar item individualmente">
                        <IconButton
                            disabled={params.row.conduta_inativada}
                            onClick={() => {
                                handleSendOneItem(params.row);
                            }}
                        >
                            <ArrowCircleDownIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Dados Adicionais">
                        <IconButton
                            disabled={params.row.conduta_inativada}
                            onClick={() => {
                                setIsEdit(true);
                                setItemEdit(params.row);
                                handleOpenNewItemModal();
                            }}
                        >
                            <FormatListBulletedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remover Item">
                        <IconButton
                            disabled={params.row.conduta_inativada}
                            onClick={() => {
                                setDeleteItemUUID(params.row.uuid);
                                setOpenDeleteModal(true);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    ];

    async function getAllData() {
        await getAllItens(id);
        await getAllCentros();
    }

    function handleOpenNewItemModal() {
        setOpenModalNewItem(true);
    }

    function handleCloseNewItemModal() {
        setIsEdit(false);
        setItemEdit(null);
        setOpenModalNewItem(false);
    }

    function generateKitItemId(kitItensArr, index) {
        const ultimoItem = kitItensArr[index - 1];
        return ultimoItem.id + 1;
    }

    function handleAddNewItem(newItem) {
        if (newItem.kit_itens && newItem.kit_itens.length > 0) {
            const ultimoItem = rows[rows.length - 1];
            const kitItensArr = [];
            newItem.kit_itens.forEach((itemDoKit, index) => {
                const novoItem = {
                    item: itemDoKit?.iten?.descricao,
                    item_id: itemDoKit?.iten?.id,
                    lote: null,
                    almoxarifado_id: itemDoKit?.almoxarifado_id,
                    localizacao_id: itemDoKit?.localizacao_id,
                    unidade: itemDoKit?.iten?.unidade?.descricao,
                    unidade_id: itemDoKit?.iten?.unidade?.id,
                    uuid: uuidv4(),
                    id: index == 0 ? ultimoItem.id + 1 : generateKitItemId(kitItensArr, index),
                    qtd: itemDoKit.quantidade * newItem.qtd,
                    conduta_id: null,
                    item_novo: true,
                    medico_id: null,
                    observacao: null,
                    psicotropico: itemDoKit?.iten?.psicotropico
                };
                kitItensArr.push(novoItem);
            });
            setRows((prevItens) => [...prevItens, ...kitItensArr]);
        } else {
            setRows((prevRows) => [...prevRows, newItem]);
        }
    }

    function handleEditItem(newItem) {
        setRows((prevItens) => {
            const indiceItem = prevItens.findIndex((item) => item.uuid === newItem.uuid);
            if (indiceItem !== -1) {
                const novosItens = [...prevItens];
                novosItens[indiceItem] = newItem;
                return novosItens;
            }
            return prevItens;
        });
    }

    async function handleRemoveItem(uuid) {
        const toastId = toast.loading('Removendo item...');
        try {
            const itemParaRemover = rows.find((item) => item.uuid == uuid);

            if (itemParaRemover.item_novo) {
                setRows(rows.filter((item) => item.uuid != uuid));
                setDeleteItemUUID(null);
                setOpenDeleteModal(false);
                return toast.update(toastId, { render: 'Item removido com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
            }

            const payload = {
                conduta_id: itemParaRemover.conduta_id,
                responsavel_id: responsavelId,
                observacao: observationDeleteItem
            };
            await api.deletarMedicamentoDaConduta(payload);
            setObservationDeleteItem('');
            setDeleteItemUUID(null);
            setOpenDeleteModal(false);
            toast.update(toastId, { render: 'Item removido com sucesso', type: 'success', isLoading: false, autoClose: 3000 });

            getAllData();
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao remover item', type: 'error', isLoading: false, autoClose: 3000 });
            toast.error(error?.reponse?.data?.message ?? error?.message);
        }
    }

    async function getAllCentros() {
        try {
            const { data } = await api.getAllCentroCusto();
            setAllCentros(data);
            setSelectedCentro(data.find((item) => item.descricao === 'PRONTO SOCORRO'));
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getAllItens(id) {
        try {
            const { data } = await api.getAllItensPedidosMedicamentoByProntuario(id);
            const row = data.map((item) => ({
                ...item,
                id: item?.pedido_medicacao?.id,
                conduta_id: item.id,
                item_id: item?.pedido_medicacao?.item_pedido?.id,
                medico_id: item.responsavel_id,
                uuid: uuidv4(),
                item: item?.pedido_medicacao?.item_pedido?.descricao,
                qtd: item?.pedido_medicacao?.quantidade_item,
                unidade: item?.pedido_medicacao?.item_pedido?.unidade?.descricao,
                unidade_id: item?.pedido_medicacao?.item_pedido?.unidade?.id,
                observacao: item?.observacao ? item?.observacao : '-',
                psicotropico: item?.pedido_medicacao?.item_pedido?.psicotropico,
                almoxarifado_id: item?.pedido_medicacao?.item_pedido?.almoxarifado_id,
                localizacao_id: item?.pedido_medicacao?.item_pedido?.localizacao_id,
                lote: null,
                kit_itens: null,
                viaAplicacao: item?.pedido_medicacao?.via_aplicacao
            }));

            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    function verifyConditionForOneItem(item) {
        if (!selectedCentro) {
            toast.error('É necessário informar o centro de custo');
            return false;
        }
        if (item.psicotropico && item.lote == null) {
            toast.error('É necessário informar lote');
            return false;
        }
        return true;
    }

    function verifyConditionsBeforeSubmit(rows, selectedCentro) {
        const verificarLocalizacaoInformada = rows.some((item) => item.localizacao_id == null && !item.conduta_inativada);
        if (verificarLocalizacaoInformada) {
            toast.error('É necessário informar almoxarifado e localização para todos os itens');
            return false;
        }
        const verificarPsicotropicoLote = rows.find((item) => item.psicotropico && item.lote == null && !item.conduta_inativada);
        if (verificarPsicotropicoLote) {
            toast.error(`Item - ${verificarPsicotropicoLote.item} faltando informar lote`);
            return false;
        }
        if (!selectedCentro) {
            toast.error('É necessário informar o centro de custo');
            return false;
        }
        return true;
    }

    async function handleSendOneItem(itemData) {
        setLoading(true);
        try {
            if (!verifyConditionForOneItem(itemData)) return;
            const payload = {
                responsavelId,
                item_id: itemData.item_id,
                unidade_id: itemData.unidade_id,
                quantidade: itemData.qtd,
                almoxarifado_id: itemData.almoxarifado_id,
                localizacao_id: itemData.localizacao_id,
                observacao: itemData.observacao,
                prontuario_id: id,
                centro_id: selectedCentro.id,
                lote_id: itemData.lote,
                medico_id: itemData.medico_id ?? null,
                conduta_id: itemData.conduta_id ?? null,
                item_novo: itemData.item_novo
            };
            await api.baixaItemUnicoConduta(payload);
            getAllData();
            setSelectedCentro(null);
            toast.success('Baixa realizada com sucesso');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        } finally {
            setLoading(false);
        }
    }

    async function handleSendAllItems(rows) {
        setLoading(true);
        try {
            if (!verifyConditionsBeforeSubmit(rows, selectedCentro)) return;
            const payload = rows
                .filter((item) => !item.conduta_inativada)
                .map((item) => ({
                    responsavelId,
                    item_id: item.item_id,
                    unidade_id: item.unidade_id,
                    quantidade: item.qtd,
                    almoxarifado_id: item.almoxarifado_id,
                    localizacao_id: item.localizacao_id,
                    observacao: item.observacao,
                    prontuario_id: id,
                    centro_id: selectedCentro.id,
                    lote_id: item.lote,
                    medico_id: item.medico_id ?? null,
                    conduta_id: item.conduta_id ?? null,
                    item_novo: item.item_novo
                }));
            await api.baixaConjuntoItensConduta(payload);
            setSelectedCentro(null);
            navigate('/main/estoque/farmacia/atendimentos');
            toast.success('Baixa realizada com sucesso');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAllData();
    }, []);

    return (
        <MainCard title={`FA ${id} - ${nome}`}>
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <LoadBw loading={loading} />
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                    <Autocomplete
                        options={allCentros}
                        getOptionLabel={(item) => `${item.id} - ${item.descricao}`}
                        value={selectedCentro}
                        onChange={(e, newValue) => {
                            setSelectedCentro(newValue);
                        }}
                        sx={{ width: '250px' }}
                        renderInput={(params) => <TextField {...params} label="Centro de custo" />}
                    />
                    <Tooltip title="Adicionar Item">
                        <Button variant="contained" onClick={handleOpenNewItemModal}>
                            <AddIcon />
                        </Button>
                    </Tooltip>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} hideFooter={true} />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', gap: '1rem' }}>
                    <Tooltip title="Imprimir lista de medicamentos">
                        <Button variant="contained" onClick={() => setOpenModalPdf(true)}>
                            <IconPrinter />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Baixar todos os itens">
                        <Button color="success" variant="contained" onClick={() => handleSendAllItems(rows)}>
                            <SaveIcon />
                        </Button>
                    </Tooltip>
                </Box>

                {/* MODAL */}
                <NewItemModel
                    open={openModalNewItem}
                    handleClose={handleCloseNewItemModal}
                    handleAddNewItem={handleAddNewItem}
                    handleEditItem={handleEditItem}
                    rows={rows}
                    isEdit={isEdit}
                    itemEdit={itemEdit}
                />
                <DeleteItemModal
                    open={openDeleteModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                    observation={observationDeleteItem}
                    setObservation={setObservationDeleteItem}
                    handleRemoveItem={handleRemoveItem}
                    deleteItemUUID={deleteItemUUID}
                />
                <ModalPdf data={rows} open={openModalPdf} setOpen={setOpenModalPdf} name={nome} id={id} />
            </Grid>
        </MainCard>
    );
}
