import { Box, Button, Dialog, Divider, Grid, IconButton, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { CreateInvoicingModal } from './components/CreateModal';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconCheck, IconEdit, IconTrash, IconX } from '@tabler/icons';
import { toast } from 'react-toastify';

export function CreateInvoicing() {
    const api = useApi();
    const [invoicing, setInvoicing] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedInvoicing, setSelectedInvoicing] = useState(null);

    async function getAllInvoicing() {
        try {
            const { data } = await api.getInvoicing();
            setInvoicing(data);
        } catch (error) {
            console.log('🚀 ~ getAllInvoicing ~ error:', error);
        }
    }

    useEffect(() => {
        getAllInvoicing();
    }, []);

    const columns = [
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'codigo', align: 'center', headerAlign: 'center', headerName: 'Código', flex: 0.5 },
        { field: 'tipo', align: 'center', headerAlign: 'center', headerName: 'Tipo', flex: 0.5 },
        {
            field: 'valor',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Valor',
            flex: 1,
            valueGetter: (params) => (params.row.valor ? `R$ ${params.row.valor}` : '-')
        },
        {
            field: 'fat_individual',
            headerName: 'Faturamento Individual',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (params.row.fat_individual ? <IconCheck /> : <IconX />)
        },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setSelectedInvoicing(params.row);
                                setOpen(true);
                            }}
                        >
                            <IconEdit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setOpenDeleteDialog(true);
                                setSelectedInvoicing(params.row);
                            }}
                        >
                            <IconTrash />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    async function handleDeleteInvoicing() {
        const toastId = toast.loading('Excluindo...');
        try {
            await api.deleteInvoicing(selectedInvoicing.id);
            getAllInvoicing();
            setOpenDeleteDialog(false);
            setSelectedInvoicing(null);
            toast.update(toastId, {
                render: 'Faturamento excluído com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            console.log('🚀 ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao excluir o faturamento',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }
    return (
        <MainCard title="Cadastro de Faturamento" sx={{ position: 'relative' }}>
            <Grid container>
                <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
                    <CreateInvoicingModal
                        open={open}
                        setOpen={setOpen}
                        selectedInvoicing={selectedInvoicing}
                        setSelectedInvoicing={setSelectedInvoicing}
                        getAllInvoicing={getAllInvoicing}
                    />
                </Box>

                <Box sx={{ width: '100%' }}>
                    <DataGrid rows={invoicing} columns={columns} sx={{ height: 'calc(100vh - 260px)' }} />
                </Box>
            </Grid>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedInvoicing(null);
                }}
            >
                <Box sx={{ padding: 2 }}>
                    <h3>Tem certeza que deseja excluir o faturamento?</h3>
                    <Divider sx={{ margin: 2 }} />
                    <p>Esta ação não pode ser desfeita.</p>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setOpenDeleteDialog(false);
                                setSelectedInvoicing(null);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={handleDeleteInvoicing}>
                            Excluir
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </MainCard>
    );
}
