import { useState } from 'react';

const useChamadas = () => {
    const [chamadas, setChamadas] = useState([]);

    const getChamadas = async () => {};

    return { chamadas, setChamadas, getChamadas };
};

export default useChamadas;
