// assets
import { IconCategory2 } from '@tabler/icons';

const faturamento = {
    id: 'fatu',
    title: 'Faturamento',
    type: 'group',
    children: [
        {
            id: 'cadastro-faturamento',
            title: 'Cadastro',
            type: 'item',
            url: '/main/cadastro-faturamento',
            icon: IconCategory2,
            breadcrumbs: false
        },
        {
            id: 'lanc-faturamento-consolidade',
            title: 'Lanc. Consolidado',
            type: 'item',
            url: '/main/lancamento-consolidado',
            icon: IconCategory2,
            breadcrumbs: false
        },
        {
            id: 'arquivo-faturamento-consolidade',
            title: 'Arquivo Consolidado',
            type: 'item',
            url: '/main/arquivo-consolidado',
            icon: IconCategory2,
            breadcrumbs: false
        }
    ]
};

export default faturamento;
