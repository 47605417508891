import { Button, Grid, IconButton, Switch, Tab, Tooltip, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { PageOne } from './components/PageOne';
import { useState, useEffect, useContext } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import { ArchiveOutlined, Send, Check } from '@mui/icons-material';
import TempoMedio from 'components/Triagem/Cards/TempoMedio';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { IconCheck, IconClock, IconReplace } from '@tabler/icons';
import Laco from '../../../assets/images/Logos/laco.jpg';
import { ChamadaContext } from 'context/ChamadasContext';
import { Box } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export function MedicalAppointment() {
    const api = useApi();
    const navigate = useNavigate();
    const { setChamadaAcionada, setChamadasRealizadas } = useContext(ChamadaContext);
    const [cooldown, setCooldown] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [clinicName, setClinicName] = useState('');
    const [rows, setRows] = useState([]);
    const [rowsRetorno, setRowsRetorno] = useState([]);
    const [defaultRows, setDefaultRows] = useState([]);
    const [waitTime, setWaitTime] = useState('');
    const [averageWaitTime, setAverageWaitTime] = useState('');
    const [checked, setChecked] = useState(false);

    const columns = [
        { field: 'ficha', headerName: 'F.A', flex: 0.4 },
        {
            field: 'paciente',
            headerName: 'PACIENTE',
            flex: 1.2,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {params.row.paciente}
                    {params.row.autismo && <img src={Laco} alt="Laco" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />}
                </div>
            )
        },
        { field: 'idade', headerName: 'IDADE', flex: 0.5 },
        {
            field: 'data',
            headerName: 'DATA',
            flex: 0.8,
            renderCell: (params) => {
                return `${params.row.data} ${params.row.hora}`;
            }
        },
        // { field: 'hora', headerName: 'Hora', flex: 0.5 },
        { field: 'status', headerName: 'STATUS', flex: 0.5 },
        { field: 'sala', headerName: 'SALA', flex: 0.5 },
        {
            field: 'enfermagem',
            headerName: 'ENFERMAGEM',
            flex: 0.6,
            renderCell: (params) => (
                <>
                    {params.row.statusMedicacao === 'ENF-LIBERADO' ? (
                        <Check />
                    ) : params.row.statusMedicacao === 'SEPARACAO' ||
                      params.row.statusMedicacao === 'PARCIAL' ||
                      params.row.statusMedicacao === 'APLICADO' ? (
                        <IconClock />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'lab',
            headerName: 'LABORATÓRIO',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    {params.row.statusLaboratorio === 'COLETA' || params.row.statusLaboratorio === 'PARCIAL' ? (
                        <IconClock />
                    ) : params.row.statusLaboratorio === 'REALIZADO' ? (
                        <Check />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'raiox',
            headerName: 'RAIO-X',
            flex: 0.5,
            renderCell: (params) => <>{params.row.statusRaiox == null ? '-' : params.row.statusRaiox ? <Check /> : <IconClock />} </>
        },
        {
            field: 'cont',
            headerName: 'EM ATENDIMENTO',
            flex: 0.5,
            renderCell: (params) => <>{params.row.em_atendimento ? <IconCheck /> : <IconClock />}</>
        },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Chamar">
                            <IconButton onClick={() => handleCreateChamada(params.row)}>
                                <Send />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Ficha">
                            <IconButton
                                onClick={() => {
                                    // handleLinkProntuario(params.row);
                                    // ${row.ficha}/${row.fa}/${row.paciente}
                                    navigate(`/main/consultorio/atendimento/${params.row.ficha}/${params.row.fa}/${params.row.paciente}`);
                                }}
                            >
                                <ArchiveOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    const columnsRetorno = [
        { field: 'ficha', headerName: 'F.A', flex: 0.5 },
        {
            field: 'paciente',
            headerName: 'PACIENTE',
            flex: 1.2,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {params.row.paciente}
                    {params.row.autismo && <img src={Laco} alt="Laco" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />}
                </div>
            )
        },
        { field: 'idade', headerName: 'IDADE', flex: 0.5 },
        {
            field: 'data',
            headerName: 'Data',
            flex: 0.5,
            renderCell: (params) => {
                return `${params.row.data} ${params.row.hora}`;
            }
        },
        { field: 'status', headerName: 'STATUS', flex: 0.5 },
        { field: 'sala', headerName: 'SALA', flex: 0.5 },
        {
            field: 'enfermagem',
            headerName: 'ENFERMAGEM',
            flex: 0.6,
            renderCell: (params) => (
                <>
                    {params.row.statusMedicacao === 'ENF-LIBERADO' ? (
                        <Check />
                    ) : params.row.statusMedicacao === 'SEPARACAO' ||
                      params.row.statusMedicacao === 'PARCIAL' ||
                      params.row.statusMedicacao === 'APLICADO' ? (
                        <IconClock />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'lab',
            headerName: 'LABORATÓRIO',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    {params.row.statusLaboratorio === 'COLETA' || params.row.statusLaboratorio === 'PARCIAL' ? (
                        <IconClock />
                    ) : params.row.statusLaboratorio === 'REALIZADO' ? (
                        <Check />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'raiox',
            headerName: 'RAIO-X',
            flex: 0.5,
            renderCell: (params) => <>{params.row.statusRaiox == null ? '-' : params.row.statusRaiox ? <Check /> : <IconClock />} </>
        },
        {
            field: 'cont',
            headerName: 'EM ATENDIMENTO',
            flex: 0.5,
            renderCell: (params) => <>{params.row.em_atendimento ? <IconCheck /> : <IconClock />}</>
        },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Chamar">
                            <IconButton onClick={() => handleCreateChamada(params.row)}>
                                <Send />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Ficha">
                            <IconButton
                                onClick={() => {
                                    // handleLinkProntuario(params.row);
                                    // ${row.ficha}/${row.fa}/${row.paciente}
                                    navigate(`/main/consultorio/atendimento/${params.row.ficha}/${params.row.fa}/${params.row.paciente}`);
                                }}
                            >
                                <ArchiveOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    // Realiza o cadastro da chamada
    // e recebe o objeto pelo socket no contexto automaticamente
    const handleCreateChamada = async (item) => {
        try {
            // Controla o disabled dos botões
            setCooldown(true);
            // Cadastra a chamada no banco
            const payloadChamada = { setor: clinicName?.descricao, prontuario_id: item?.ficha, message: 'TESTE' };
            const criarChamada = await api.createChamadaPainel(payloadChamada);

            if (criarChamada.status == 201) {
                setTimeout(() => {
                    setCooldown(false);
                }, 3000);
                // State do context
                // Se true ele chama o get para atualizar os atendimentos pendentes
                setChamadaAcionada(true);
                toast.success('Paciente Chamado com sucesso!');
            }
        } catch (error) {
            toast.error(`Erro ao chamar paciente: ${error.message}`);
        }
    };

    async function getTable() {
        try {
            const { data } = await api.getPatientsByClinic();

            const row = data
                .filter((element) => element.status == 'CONSULTORIO' || element.status == 'TRIAGEM')
                .map((item) => ({
                    ...item,
                    ficha: item?.id ?? '-',
                    fa: item?.paciente?.id ?? '-',
                    idade: item?.paciente?.idade ?? '-',
                    data: item?.data ?? '-',
                    data_atendimento: item?.data_atendimento ?? '-',
                    hora: item?.hora_atendimento ? item.hora_atendimento.slice(0, 5) : '-',
                    status: item?.status ?? '-',
                    paciente: item?.paciente?.nome_completo ?? '-',
                    sala: item?.sala ?? '-',
                    enfermagem: ' - ',
                    lab:
                        item?.todasCondutasFinalizadasPorTipo?.laboratorio !== undefined
                            ? item.todasCondutasFinalizadasPorTipo.laboratorio
                                ? 'Finalizado'
                                : 'Pendente'
                            : '-',
                    raiox:
                        item?.todasCondutasFinalizadasPorTipo?.raiox !== undefined
                            ? item.todasCondutasFinalizadasPorTipo.raiox
                                ? 'Finalizado'
                                : 'Pendente'
                            : '-',
                    autismo: item?.paciente?.autismo ?? '-'
                }));

            const rowRetorno = data
                .filter((element) => element.status == 'MEDICACAO')
                .map((item) => ({
                    ...item,
                    ficha: item?.id ?? '-',
                    fa: item?.paciente?.id ?? '-',
                    idade: item?.paciente?.idade ?? '-',
                    data: item?.data ?? '-',
                    data_atendimento: item?.data_atendimento ?? '-',
                    hora: item?.hora_atendimento ? item.hora_atendimento.slice(0, 5) : '-',
                    status: item?.status ?? '-',
                    paciente: item?.paciente?.nome_completo ?? '-',
                    sala: item?.sala ?? '-',
                    enfermagem: ' - ',
                    lab:
                        item?.todasCondutasFinalizadasPorTipo?.laboratorio !== undefined
                            ? item.todasCondutasFinalizadasPorTipo.laboratorio
                                ? 'Finalizado'
                                : 'Pendente'
                            : '-',
                    raiox:
                        item?.todasCondutasFinalizadasPorTipo?.raiox !== undefined
                            ? item.todasCondutasFinalizadasPorTipo.raiox
                                ? 'Finalizado'
                                : 'Pendente'
                            : '-',
                    autismo: item?.paciente?.autismo ?? '-'
                }));

            setRows(row);
            setRowsRetorno(rowRetorno);
            setDefaultRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    function handleNavigateMedicalRecord(row) {
        navigate(`/main/consultorio/ficha/${row.ficha}/${row.fa}/${row.paciente}`);
    }

    async function handleLinkProntuario(row) {
        const userId = localStorage.getItem('id');
        const payload = { prontuario_id: row.ficha, consultorio_id: clinicName?.id, usuario_id: userId };

        try {
            await api.linkProntuarioToPatient(payload);
            handleNavigateMedicalRecord(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        const isExpired = localStorage.getItem('login_expired');
        const boolenExpired = JSON.parse(isExpired);
        const clinicObj = JSON.parse(localStorage.getItem('consultorio'));
        setClinicName(clinicObj);
        setIsExpired(boolenExpired);
    }, [isExpired]);

    // CALCULO DE TEMPO DE ESPERA
    useEffect(() => {
        if (rows.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(rows[0]?.data_atendimento);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [rows]);

    // CALCULO DE TEMPO MEDIO
    useEffect(() => {
        if (rows.length > 0) {
            const calculateAverageWaitTime = () => {
                const currentDate = new Date();
                const totalWaitTimeInSeconds = rows.reduce((accumulator, record) => {
                    const recordDate = new Date(record.data_atendimento);
                    const timeDifference = currentDate.getTime() - recordDate.getTime();
                    return accumulator + Math.floor(timeDifference / 1000);
                }, 0);

                const averageWaitTimeInSeconds = totalWaitTimeInSeconds / rows.length;
                const hours = Math.floor(averageWaitTimeInSeconds / 3600);
                const minutes = Math.floor((averageWaitTimeInSeconds % 3600) / 60);
                const seconds = Math.floor(averageWaitTimeInSeconds % 60);

                setAverageWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateAverageWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [rows]);

    useEffect(() => {
        const getData = () => {
            getTable();
        };
        getData();
        const interval = setInterval(getData, 10000);
        return () => clearInterval(interval);
    }, []);

    function resetIsExpiredAndLogoff() {
        localStorage.setItem('login_expired', true);
        setIsExpired(true);
    }

    useEffect(() => {
        if (checked) {
            setRows(defaultRows.filter((i) => i?.interno));
        } else {
            setRows(defaultRows.filter((i) => !i?.interno));
        }
    }, [checked]);

    const [tabValue, setTabValue] = useState('1');

    const hjandleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <MainCard title={isExpired ? 'Atendimento' : clinicName?.descricao} sx={{ height: '100%' }}>
            <Grid sx={{ position: 'relative' }}>
                {isExpired && <PageOne setIsExpired={setIsExpired} />}
                {!isExpired && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                position: 'absolute',
                                top: '-93px',
                                right: 0
                            }}
                        >
                            <TempoMedio tempoMedio={averageWaitTime} />
                            <TempoEspera tempoEspera={waitTime} />
                            <Aguardando aguardandoQTD={rows.length} />
                        </div>
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '.4rem',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    top: '-80px',
                                    left: '200px'
                                }}
                            >
                                <Typography>Interno</Typography>
                                <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                                <Tooltip title="Trocar consultório">
                                    <IconButton variant="contained" onClick={resetIsExpiredAndLogoff}>
                                        <IconReplace />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                            <TabContext value={tabValue} s>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '100%' }}>
                                    <TabList onChange={hjandleChangeTab} aria-label="lab API tabs example">
                                        <Tab label="ESCUTA INICIAL" value="1" />
                                        <Tab label="RETORNO" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    {!!rows.length ? (
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            disableSelectionOnClick
                                            sx={{ minHeight: '100%', maxHeight: 'calc(100vh - 350px)' }}
                                        />
                                    ) : (
                                        <p>Nenhum paciente na escuta inicial</p>
                                    )}
                                </TabPanel>
                                <TabPanel value="2" sx={{ height: '100%' }}>
                                    {!!rowsRetorno.length ? (
                                        <DataGrid
                                            rows={rowsRetorno}
                                            columns={columnsRetorno}
                                            sx={{ minHeight: '100%', maxHeight: 'calc(100vh - 350px)' }}
                                            pageSize={5}
                                            disableSelectionOnClick
                                        />
                                    ) : (
                                        <p>Nenhum paciente na retorno</p>
                                    )}
                                </TabPanel>
                            </TabContext>
                        </div>
                    </>
                )}
            </Grid>
        </MainCard>
    );
}
