import { Divider, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { v4 } from 'uuid';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 5
};

export function ModalForViewingResults({ open, setOpen, currentRow }) {
    const api = useApi();
    const [results, setResults] = useState([]);

    async function getResults() {
        let examId = currentRow?.conduta_medica?.exame_prontuario?.id;

        try {
            const { data } = await api.getExameAndResultById(currentRow?.prontuarioId, currentRow?.exameId, examId);
            const rows = data?.campos_especificos?.map((item) => {
                return {
                    ...item,
                    res: item?.resultados[0]?.valor,
                    name: item?.campo_nome,
                    un: item?.unidade || '-',
                    id: v4()
                };
            });

            setResults(rows);
        } catch (error) {
            console.log('🚀 ~ getResults ~ error:', error);
        }
    }

    useEffect(() => {
        if (open) {
            getResults();
        }
    }, [currentRow, open]);

    const column = [
        { field: 'name', headerName: 'EXAME', flex: 2 },
        { field: 'un', headerName: 'UNIDADE', flex: 2 },
        { field: 'res', headerName: 'RESULTADO', flex: 1 }
    ];

    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ textAlign: 'center' }}>RESULTADO DO EXAME</h3>
                <Divider sx={{ margin: '10px 0' }} />
                <DataGrid rows={results} columns={column} hideFooter={true} getRowId={(row) => row.id} />
            </Box>
        </Modal>
    );
}
