import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Outlet } from 'react-router-dom';

import Logo from '../../assets/images/Logos/logo.png';
import Logo2 from '../../assets/logobw.png';

const ChamadasLayout = () => {
    return (
        <Box sx={{ padding: '0', width: '100%' }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#0f4c64',
                    height: '100px'
                }}
            >
                <img src={Logo2} alt="logo bw" width={300} style={{ marginLeft: '15px' }} />

                <img
                    src={Logo}
                    alt="logo Santa Casa"
                    style={{
                        width: '150px',
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        padding: '15px',
                        marginTop: '20px',
                        marginLeft: '-330px'
                    }}
                />

                <div style={{ visibility: 'hidden' }}>x</div>
            </Box>

            <Outlet />
        </Box>
    );
};

export default ChamadasLayout;
