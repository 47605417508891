import { Button, CircularProgress, Divider, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import formater from 'utils/DateDDMMYYY';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import PdfAtestado from './component/PdfAtestado';
import { pdf } from '@react-pdf/renderer';
import { useParams } from 'react-router';
import { DoctorsSignatureConfirmationModal } from 'components/DoctorsSignatureConfirmationModal';
import { useEffect } from 'react';
import { removeMask } from 'utils/removeMask';

export function Atestado({ open, handleClose, paciente, data, setTriggerReload }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: 2
    };
    const api = useApi();
    const [diasAtestado, setDiasAtestado] = useState(1);
    const [observation, setObservation] = useState('');
    const { prontuarioId, serviceId } = useParams();
    const [assinaturaId, setAssinaturaId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [doctorInfos, setDoctorInfos] = useState({});

    async function generateAtestado(blob_name) {
        const toastId = toast.loading('Gerando atestado...');
        try {
            const { status } = await api.createAtestado({
                dias: diasAtestado,
                pacienteId: paciente.id,
                prontuarioId: prontuarioId,
                observacao: observation,
                blob_name: blob_name,
                atendimento_id: serviceId
            });

            toast.update(toastId, { render: 'Atestado gerado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
            if (status == 201) {
                setDiasAtestado(null);
                setObservation('');
                setLoading(false);
                window.location.reload();
            }
        } catch (error) {
            toast.update(toastId, {
                render: `Erro ao gerar atestado: ${error?.response?.data?.message ?? error?.message}`,
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        } finally {
            setSocketData(null);
            setTriggerReload(true);
            handleClose();
        }
    }

    async function getcurrentUser() {
        const userString = localStorage.getItem('userInfos');
        const userParse = JSON.parse(userString);

        try {
            const { data } = await api.getUserById(userParse.usuario.id);
            console.log('🚀 ~ getcurrentUser ~ data:', data);
            const { crm_coren, nome_completo } = data?.usuario?.info_medico;
            console.log('🚀 ~ getcurrentUser ~ nome_completo:', nome_completo);
            console.log('🚀 ~ getcurrentUser ~ crm_coren:', crm_coren);
            setDoctorInfos({ crm: crm_coren, name: nome_completo });
        } catch (error) {
            console.log('🚀 ~ currentUser ~ error:', error);
        }
    }

    useEffect(() => {
        getcurrentUser();
    }, []);

    async function generatePdfBlob(nome, nascimento, data, dias) {
        try {
            setLoading(true);
            // Gerar o blob do PDF
            const content = { nome, nascimento, data, dias, doctor: doctorInfos };
            const document = <PdfAtestado data={content} />;
            const asPdf = pdf(document);
            const pdfBlob = await asPdf.toBlob();
            // Iniciar transação no CESS
            const payload = {
                identificacao: removeMask(String(localStorage.getItem('userIdentity'))),
                paciente: nome,
                tipo: 'Atestado',
                dias: dias,
                prontuario_id: prontuarioId,
                serviceId: serviceId,
                paciente_id: paciente.id
            };
            const responseCess = await api.initTransactionCess(payload);
            // Enviar o arquivo para assinatura
            if (responseCess.status == 200 && responseCess.data) {
                const tcn = responseCess.data.tcn;
                localStorage.setItem('tcn', tcn);
                const formData = new FormData();
                formData.append('pdf', pdfBlob);
                formData.append('transactionTcn', tcn);
                const responseUpload = await api.uploadPdfCess(formData);
                console.log('🚀 ~ generatePdfBlob ~ responseUpload:', responseUpload);
                if (responseUpload.status == 200) {
                    localStorage.removeItem('tcn');
                    const userInfo = JSON.parse(localStorage.getItem('userInfos'));
                    if (userInfo.usuario.setor != 'médico') {
                        localStorage.removeItem('userIdentity');
                    }
                }
                window.location.reload();
            }
        } catch (error) {
            setLoading(false);
            toast.error(`Erro ao gerar atestado: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    return (
        <Box>
            <Modal open={open}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h3">
                            <b>ATESTADO - {paciente?.nome_completo}</b>
                        </Typography>
                        <TextField
                            label="Dias atestado"
                            value={diasAtestado}
                            onChange={(e) => {
                                const value = Number(e.target.value);
                                if (value < 1 || value > 100) {
                                    return;
                                }
                                setDiasAtestado(value);
                            }}
                            type="number"
                            min="1"
                            max="100"
                            sx={{ width: '150px' }}
                            inputProps={{
                                min: 1, // Define o mínimo no HTML, mas a lógica controla a validação
                                max: 100
                            }}
                        />
                    </Box>
                    <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', marginTop: '1rem', padding: '1rem' }}>
                        <Typography variant="h2" sx={{ textAlign: 'center' }}>
                            ATESTADO MÉDICO
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.4rem', marginTop: '1rem' }}>
                            <Typography variant="h4">DADOS DO PACIENTE</Typography>
                            <Typography variant="h5">NOME: {paciente?.nome_completo}</Typography>
                            <Typography variant="h5">NASCIMENTO: {formater(paciente?.data_nascimento)}</Typography>
                            <Typography variant="h5">ATENDIMENTO: {data}</Typography>
                        </Box>
                        <Divider sx={{ width: '100%', margin: '2rem 0 2rem 0' }} />
                        <Box sx={{ textAlign: 'center' }}>
                            ATESTO PARA FINS DE TRABALHO QUE O(A) PACIENTE ACIMA ESTEVE EM CONSULTA MÉDICA NO DIA DE HOJE E NECESSITA
                            PERMANECER AFASTADO(A) DE SUAS ATIVIDADES LABORATIVAS PELO PERÍODO DE
                            {diasAtestado > 0 ? ` ${diasAtestado}` : ` " #### "`} DIAS A CONTAR DESTA DATA.
                        </Box>
                        <Box>
                            <TextField
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                                label="Observação adicional"
                                variant="standard"
                                fullWidth
                                sx={{ marginTop: '10px' }}
                            />
                        </Box>
                    </Box>
                    {!loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '1rem' }}>
                            <Button
                                variant="contained"
                                color="error"
                                disabled={loading}
                                onClick={() => {
                                    setDiasAtestado(null);
                                    handleClose();
                                    window.location.reload();
                                }}
                            >
                                CANCELAR
                            </Button>

                            <DoctorsSignatureConfirmationModal
                                buttonText="GERAR"
                                confirmFunction={() =>
                                    generatePdfBlob(paciente?.nome_completo, formater(paciente?.data_nascimento), data, diasAtestado)
                                }
                                setAssinaturaId={setAssinaturaId}
                            />
                        </Box>
                    )}
                    {loading && (
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </Modal>
        </Box>
    );
}
