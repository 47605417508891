import { Box, Button, Fab, Grid, IconButton, Modal, Tooltip } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { ProntuarioInfos } from 'ui-component/prontuario-infos';
import { useApi } from 'Service/axios';
import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconArrowLeft, IconFileAnalytics, IconPill, IconStethoscope, IconTrash } from '@tabler/icons';

export function Listofmedicalappointments() {
    const api = useApi();
    const { prontuarioId, pacienteId, name } = useParams();
    const navigate = useNavigate();
    const userId = localStorage.getItem('id');
    const [servicesByPatient, setServicesByPatient] = useState([]);
    const [selectedServiceId, setSelectedServiceId] = useState(null);

    async function getPatients() {
        try {
            const { data } = await api.getServicesByProntuario(prontuarioId);

            const row = data.map((el) => {
                let deletIsDisabled;

                if (el.atendimentos_enfermagem.length > 0 || el.condutas_procedimento.length > 0 || el.relatorios_clinicos.length > 0) {
                    deletIsDisabled = true;
                } else {
                    deletIsDisabled = false;
                }

                return {
                    ...el,
                    doctor: el?.usuario?.nome,
                    dateTime: el?.data_hora_formatada,
                    status: el?.status_conduta,
                    isPossibleDelete: deletIsDisabled
                };
            });
            setServicesByPatient(row);
        } catch (error) {
            console.log('🚀 ~ getPatients ~ error:', error);
        }
    }

    useEffect(() => {
        getPatients();
    }, []);

    const columns = [
        { field: 'doctor', headerName: 'MÉDICO', flex: 1 },
        { field: 'dateTime', headerName: 'DATA E HORA', flex: 1 },
        { field: 'status', headerName: 'STATUS', flex: 1 },
        {
            field: 'action',
            headerName: 'AÇÃO',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box>
                        <Tooltip title="Deletar Atendimento">
                            <IconButton disabled={params?.row?.isPossibleDelete} onClick={() => deleteService(params.row.id)}>
                                <IconTrash />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Ver Atendimento">
                            <IconButton
                                onClick={() => {
                                    setSelectedServiceId(params.row.id);
                                }}
                            >
                                <IconStethoscope />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            }
        }
    ];

    useEffect(() => {
        if (selectedServiceId) {
            handleNavigateMedicalRecord();
        }
    }, [selectedServiceId]);

    async function deleteService(id) {
        const toastId = toast.loading('Deletando atendimento ...');
        try {
            await api.deleteServiceById({ atendimento_id: id });
            toast.update(toastId, {
                render: 'Atendimento excluído com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getPatients();
        } catch (error) {
            console.log('🚀 ~ deleteService ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao excluir atendimento',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function linkService() {
        const toastId = toast.loading('Iniciando atendimento...');
        const payload = {
            prontuario_id: prontuarioId,
            responsavel_id: userId
        };
        try {
            await api.createService(payload);

            getPatients();
            toast.update(toastId, {
                render: 'Atendimento vinculado com o paciente',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
        } catch (error) {
            console.log('🚀 ~ linkService ~ error:', error);
            toast.update(toastId, {
                render: 'Ficha ou médico ainda possui um atendimento em aberto',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    function handleNavigateMedicalRecord() {
        navigate(`/main/consultorio/ficha/${prontuarioId}/${pacienteId}/${name}/${selectedServiceId}`);
    }
    async function handleLinkProntuario() {
        const toastId = toast.loading('Linkando Prontuario...');
        const consultorioString = localStorage.getItem('consultorio');
        const consultorio = JSON.parse(consultorioString);
        const payload = {
            prontuario_id: prontuarioId,
            consultorio_id: consultorio.id,
            usuario_id: userId
        };

        try {
            await api.linkProntuarioToPatient(payload);

            toast.update(toastId, {
                render: 'Prontuario vinculado com o paciente',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            handleNavigateMedicalRecord();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
            toast.update(toastId, {
                render: error?.response?.data?.message ?? error?.message,
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    return (
        <MainCard title="Lista de Atendimentos" sx={{ position: 'relative' }}>
            <Grid>
                <ProntuarioInfos />
                <Box sx={{ border: '1px solid #ccc', padding: '15px 15px', borderRadius: '7px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h3>Atendimentos</h3>
                        <Button variant="contained" onClick={linkService}>
                            Novo Atendimento
                        </Button>
                    </Box>

                    <Box>
                        {servicesByPatient.length > 0 ? (
                            <DataGrid columns={columns} rows={servicesByPatient} hideFooter sx={{ margin: '15px ' }} />
                        ) : (
                            <p>Nenhum atendimento encontrado</p>
                        )}
                    </Box>
                </Box>

                <Fab variant="extended" sx={{ position: 'fixed', left: '15px', bottom: '10px' }} onClick={() => navigate(-1)}>
                    <IconArrowLeft /> Voltar
                </Fab>
            </Grid>
        </MainCard>
    );
}
