import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconPlus } from '@tabler/icons';
import { Autocomplete, Checkbox, Divider, FormControl, FormControlLabel, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { NumericFormat } from 'react-number-format';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
};

export function CreateInvoicingModal({ open, setOpen, selectedInvoicing, setSelectedInvoicing, getAllInvoicing }) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSelectedInvoicing(null);
    };

    const api = useApi();

    async function handleSubmit(data) {
        const toastId = toast.loading('Enviando...');
        const payload = {
            tipo: data.type,
            descricao: data.descricao,
            valor: data.value ? data.value : null,
            codigo: data.codgo,
            fat_individual: data.fat_individual
        };

        try {
            await api.createInvoicing(payload);
            toast.update(toastId, {
                render: 'Faturamento cadastrado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            handleClose();
            getAllInvoicing();
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.update(toastId, {
                render: 'Falha ao cadastrar faturamento',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function handleUpdate(data) {
        const toastId = toast.loading('Enviando...');
        const payload = {
            tipo: data.type,
            descricao: data.descricao,
            valor: data.value,
            codigo: data.codgo,
            fat_individual: data.fat_individual
        };

        try {
            await api.editInvoicing(payload, selectedInvoicing?.id);
            toast.update(toastId, {
                render: 'Faturamento editado com sucesso',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            handleClose();
            getAllInvoicing();
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.update(toastId, {
                render: 'Falha ao editar faturamento',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                <IconPlus />
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Cadastro de Faturamento</h3>
                    <Divider sx={{ margin: 2 }} />

                    <Formik
                        initialValues={{
                            descricao: selectedInvoicing ? selectedInvoicing?.descricao : '',
                            codgo: selectedInvoicing ? selectedInvoicing?.codigo : '',
                            type: selectedInvoicing ? selectedInvoicing?.tipo : '',
                            value: selectedInvoicing ? selectedInvoicing?.valor : '',
                            fat_individual: selectedInvoicing ? selectedInvoicing?.fat_individual : false
                        }}
                        validationSchema={Yup.object().shape({
                            descricao: Yup.string().required('Descrição é obrigatorio'),
                            codgo: Yup.string().required('Código é obrigatorio'),
                            type: Yup.string().required('Tipo é obrigatorio')
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            if (selectedInvoicing) {
                                await handleUpdate(values);
                            } else {
                                await handleSubmit(values);
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
                            >
                                <Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            error={Boolean(touched.descricao && errors.descricao)}
                                            fullWidth
                                            helperText={touched.descricao && errors.descricao}
                                            label="Descrição"
                                            name="descricao"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.descricao}
                                            variant="outlined"
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mt: 2 }}>
                                        <TextField
                                            error={Boolean(touched.codgo && errors.codgo)}
                                            fullWidth
                                            helperText={touched.codgo && errors.codgo}
                                            label="Código"
                                            name="codgo"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.codgo}
                                            variant="outlined"
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mt: 2 }}>
                                        <Autocomplete
                                            id="type"
                                            options={['Médico', 'Enfermagem', 'Laboratório', 'Raio-X']}
                                            value={values.type}
                                            onChange={(event, newValue) => {
                                                setFieldValue('type', newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Tipos" variant="outlined" />}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mt: 2 }}>
                                        <NumericFormat
                                            customInput={TextField}
                                            error={Boolean(touched.value && errors.value)}
                                            fullWidth
                                            helperText={touched.value && errors.value}
                                            label="Valor"
                                            name="value"
                                            value={values.value}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix="R$ "
                                            allowNegative={false}
                                            decimalScale={2}
                                            onBlur={handleBlur}
                                            onValueChange={(values) => {
                                                setFieldValue('value', values.value); // Salva apenas o número puro no Formik
                                            }}
                                            variant="outlined"
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mt: 2 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.fat_individual}
                                                    onChange={(event) => {
                                                        setFieldValue('fat_individual', event.target.checked);
                                                    }}
                                                />
                                            }
                                            label="Faturamento Individual"
                                        />
                                    </FormControl>
                                </Box>

                                <Button variant="contained" type="submit" sx={{ width: '300px', margin: '10px auto' }}>
                                    {selectedInvoicing ? 'Editar' : 'Cadastrar'}
                                </Button>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </div>
    );
}
