import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Modal,
    OutlinedInput,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useApi } from 'Service/axios';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { formatarDataIdade } from 'utils/dataIdade';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconLungs, IconArrowAutofitHeight, IconThermometer, IconPercentage, IconPrinter, IconArticle } from '@tabler/icons';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CloseIcon from '@mui/icons-material/Close';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import CalculateIcon from '@mui/icons-material/Calculate';
import { Check, Print } from '@mui/icons-material';
import { IconClock } from '@tabler/icons-react';
import { DataGrid } from '@mui/x-data-grid';
import MedicalFinalizationModal from '../FinalizationModal';
import { PDFViewer } from '@react-pdf/renderer';
import { v4 } from 'uuid';
import ConjuntoLaudosPDF from './components/ConjuntoLaudos';
import { formatarData } from 'utils/formatarData';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { ModalRoom } from 'views/enfermagem/GeneralNursing/component/ModalRoom';
import RelatorioFichaMedica from '../pdf/RelatorioFichaMedica';
import ReactInputMask from 'react-input-mask';
import { removeMask } from 'utils/removeMask';
import { MonitoringReportModal } from '../MedicalAppointment/components/MonitoringReportModal';

export function MaintenanceMedicalRecord() {
    // PDF MODAL VIEWER PLUGINS
    const getFilePluginInstance = getFilePlugin();
    const { DownloadButton } = getFilePluginInstance;
    const printPluginInstance = printPlugin();
    const { PrintButton } = printPluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    // APPLICATION
    const { prontuarioId, pacienteId, nome, atendimentoId } = useParams();
    const api = useApi();
    const navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(false);
    const [medicine, setMedicine] = useState({});
    const [triagem, setTriagem] = useState({});
    const [paciente, setPaciente] = useState({});
    const [prontuario, setProntuario] = useState({});
    const [medicalRecordObs, setMedicalRecordObs] = useState([]);
    const [openFinalizationModal, setOpenFinalizationModal] = useState(false);
    const [openModalPdfViewer, setOpenModalPdfViewer] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowsAtestadoReceita, setRowsAtestadoReceita] = useState([]);
    const [rowsNursing, setRowsNursing] = useState([]);
    const [openModalLaudo, setOpenModalLaudo] = useState(false);
    const [openModalFicha, setOpenModalFicha] = useState(false);
    const [data, setData] = useState({});
    const [pdfBlob, setPdfBlob] = useState(null);
    const [nurseReport, setNurseReport] = useState([]);

    const stylePdf = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        height: '80vh'
    };

    const columns = [
        { field: 'cod', headerName: 'Código', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'qtd', headerName: 'Quantidade', flex: 0.5 },
        { field: 'unidade', headerName: 'Unidade', flex: 0.5 },
        {
            field: 'aplicacao',
            headerName: 'Aplicação',
            flex: 0.5,
            renderCell: (params) => <p style={{ textTransform: 'uppercase' }}>{params.row.aplicacao}</p>
        },
        { field: 'tipo', headerName: 'Tipo', flex: 0.5 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => verificarStatus(params.row)
        }
    ];

    const columnsLabRaioProcedimentos = [
        { field: 'cod', headerName: 'Código', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'tipo', headerName: 'Tipo', flex: 0.5 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => verificarStatus(params.row)
        }
    ];

    const columnsLab = [
        { field: 'cod', headerName: 'Código', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'tipo', headerName: 'Tipo', flex: 0.5 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => verificarStatus(params.row)
        },
        {
            field: 'laudo',
            headerName: 'Laudo',
            flex: 0.4,
            renderCell: (params) => {
                return (
                    <>
                        {/* <Tooltip title="Acompanhamento">
                            <IconButton
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    setOpenConfirmIdentity(true);
                                }}
                            >
                                <IconArticle />
                            </IconButton>
                        </Tooltip> */}
                        <Tooltip title="Visualizar laudo">
                            <IconButton onClick={() => handleReport(params)}>
                                <Print />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    const columnsAtestadoReceita = [
        { field: 'id', headerName: 'ID DOC', flex: 1 },
        { field: 'tipo', headerName: 'TIPO', flex: 1 },
        { field: 'medico', headerName: 'MEDICO', flex: 1 },
        {
            field: 'acoes',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Imprimir">
                    <Button
                        onClick={() => {
                            getPdfBlob(params?.row?.stored_blob_name);
                            setOpenModalPdfViewer(true);
                        }}
                    >
                        <Print />
                    </Button>
                </Tooltip>
            )
        }
    ];

    function translateStatusMedical(status) {
        switch (status) {
            case 'MEDICACAO':
                return 'RETORNO';
            default:
                return status;
        }
    }

    const columnsNursing = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'type', headerName: 'TIPO', flex: 1, valueGetter: (params) => translateNursingStatus(params.row.type) },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'user', headerName: 'RESPONSAVEL', flex: 1 },
        {
            field: 'acoes',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Imprimir">
                    <Button
                        onClick={() => {
                            getPdfBlob(params?.row?.blob_name);
                            setOpenModalPdfViewer(true);
                        }}
                    >
                        <Print />
                    </Button>
                </Tooltip>
            )
        }
    ];

    function handleReport(params) {
        const isSpecialExam = params.row?.item_conduta?.exame === null;

        // Definindo os ids com base na condição
        const exameId = !isSpecialExam ? params.row?.item_conduta?.exame?.id : null;
        const exameEspecialId = isSpecialExam ? params.row?.item_conduta?.exames_especiai?.id : null;
        const itemConduta = params?.row?.item_conduta;
        const prontuarioId = params?.row?.prontuario?.id;

        if (itemConduta?.registro_laudo_enf?.stored_blob_name) {
            // Abre o visualizador de PDF com o blob
            getPdfBlob(itemConduta.registro_laudo_enf.stored_blob_name);
            setOpenModalPdfViewer(true);
            return;
        }

        // Caso não haja stored_blob_name, verifica os IDs para buscar ou abrir o laudo
        if (exameId !== null) {
            getLaudoData([
                {
                    idExameProntuario: itemConduta?.id,
                    exameId,
                    exameEspecialId,
                    prontuarioId,
                    especial: isSpecialExam
                }
            ]);
        }
    }

    function translateNursingStatus(status) {
        switch (status) {
            case 'LABORATORIO':
                return 'COLETA';
            case 'MEDICACAO':
                return 'APL. MEDICACAO';
            default:
                return status;
        }
    }

    function verificarStatus(item) {
        switch (item.tipo) {
            case 'MEDICAMENTO':
                if (item.status === 'ENF-LIBERADO') {
                    return (
                        <Tooltip title="Finalizado">
                            <Check />
                        </Tooltip>
                    );
                } else if (item?.conduta_inativada) {
                    return (
                        <Tooltip title="Cancelado">
                            <CancelIcon />
                        </Tooltip>
                    );
                }
                break;

            case 'LABORATÓRIO':
            case 'LABORATORIO':
                if (item.status === 'REALIZADO') {
                    return (
                        <Tooltip title="Finalizado">
                            <Check />
                        </Tooltip>
                    );
                }
                break;

            case 'RAIO-X':
            case 'RAIOX':
                if (item.status == 'R-FINALIZADO') {
                    return (
                        <Tooltip title="Finalizado">
                            <Check />
                        </Tooltip>
                    );
                }
                break;

            case 'PROCEDIMENTO':
                if (item.status == 'FINALIZADO') {
                    return (
                        <Tooltip title="Finalizado">
                            <Check />
                        </Tooltip>
                    );
                }
                break;

            default:
                break;
        }

        return (
            <Tooltip title="Aguardando">
                <IconClock />
            </Tooltip>
        );
    }

    async function getRecord() {
        try {
            const { data } = await api.getMedicalRecord(prontuarioId, pacienteId);

            setMedicine(data?.medicacao);
            setTriagem(data?.triagem);
            const res = await api.getPacienteById(pacienteId);
            setPaciente(res.data);
            handleMedicalRecord();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getConduct() {
        console.log('1');
        try {
            const { data } = await api.getMedicalConduct(prontuarioId, atendimentoId);
            const row = data?.map((item) => {
                let descricao;
                let qtd = '-';
                let unidade = '-';
                let aplicacao = '-';

                if (item?.tipo === 'LABORATÓRIO') {
                    if (item?.item_conduta?.exame) {
                        descricao = item?.item_conduta.exame?.nome;
                    } else {
                        descricao = item?.item_conduta.exames_especiai?.nome;
                    }
                } else if (item?.tipo === 'RAIO-X') {
                    descricao = item?.item_conduta.tipos_raio_x?.descricao;
                } else if (item?.tipo === 'MEDICAMENTO') {
                    let medInfo = item?.item_conduta?.item_pedido;

                    descricao = medInfo?.descricao;
                    qtd = item?.item_conduta?.quantidade_item;
                    unidade = medInfo?.unidade?.sigla;
                    aplicacao = item?.item_conduta?.via_aplicacao;
                } else if (item?.tipo === 'PROCEDIMENTO') {
                    descricao = item?.item_conduta?.procedimento?.descricao;
                }

                return {
                    ...item,
                    hora_aplicacao_formatada: item?.hora_aplicacao,
                    hora_coleta_formatada: item?.hora_coleta,
                    cod: item?.id,
                    descricao: descricao,
                    qtd,
                    unidade,
                    aplicacao
                };
            });
            console.log('2');
            setRows(row);
            console.log('🚀 ~ getConduct ~ row:', row);
        } catch (error) {
            console.log('🚀 ~ getConduct ~ error:', error);
            toast.error(error?.response?.data?.message ?? error?.message);
            console.log('3');
        }
    }

    async function handleMedicalRecord() {
        try {
            const { data } = await api.getMedicalRecordObservation(prontuarioId, atendimentoId);
            const rows = data.map((item) => ({ ...item, responsavel: item?.usuario?.nome }));
            setMedicalRecordObs(rows);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    const columnsMedicalRecord = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'dia', headerName: 'DIA', flex: 1 },
        { field: 'hora', headerName: 'HORA', flex: 1 },
        { field: 'conteudo', headerName: 'CONTEÚDO', flex: 1 },
        { field: 'responsavel', headerName: 'RESPONSÁVEL', flex: 1 }
    ];

    function handleBackPage() {
        navigate('/main/manutencao-ficha');
    }

    async function getAtestadoReceita() {
        try {
            const { data } = await api.getAtestadoReceitaByProntuario(prontuarioId, atendimentoId);
            const rows = data.map((item) => ({
                ...item,
                uuid: v4(),
                tipo: item?.dias ? 'ATESTADO' : 'RECEITA',
                medico: item?.usuario?.nome
            }));
            setRowsAtestadoReceita(rows);
        } catch (error) {
            toast.error('Ocorreu um erro ao buscar os dados.');
        }
    }

    async function getLaudoData(laudoPayload) {
        const toastId = toast.loading('Buscando laudos...');
        try {
            const response = await api?.getResultsForLaudos(laudoPayload);

            const agruparDados = response?.data.map((el) => {
                const resultadoCampoEspecifico = el.resultado_campo_especifico;
                const data = formatarData(el?.medico?.data_execucao);

                // Use o método reduce para agrupar os campos por categoria
                const camposAgrupados = resultadoCampoEspecifico.reduce((agrupados, item) => {
                    const categoria = item.campos_especifico.categoria;

                    // Se a categoria já existir no objeto agrupados, adicione o item a ela
                    if (agrupados[categoria]) {
                        agrupados[categoria].push(item);
                    } else {
                        // Se a categoria ainda não existir, crie um novo array com o item
                        agrupados[categoria] = [item];
                    }

                    return agrupados;
                }, {});
                for (const categoria in camposAgrupados) {
                    camposAgrupados[categoria].sort((a, b) => a.id - b.id);
                }
                return {
                    ...el,
                    data,
                    camposAgrupados
                };
            });

            const ordenarExames = (a, b) => {
                const aNome = a?.exame?.nome?.toLowerCase();
                const bNome = b?.exame?.nome?.toLowerCase();

                if (aNome?.includes('hemograma') || aNome?.includes('urina')) {
                    return -1;
                } else if (bNome?.includes('hemograma') || bNome?.includes('urina')) {
                    return 1;
                }

                return 0;
            };

            response?.data?.sort(ordenarExames);

            const parsedData = laudoPayload?.map(async (item) => {
                let observacao = await api.getObservation(
                    item.prontuarioId,
                    item.especial ? null : item.exameId,
                    item.especial ? item.exameId : null,
                    item.idExameProntuario
                );
                return observacao;
            });

            agruparDados.map((item) => ({ ...item, parsedData }));
            setOpenModalLaudo(true);
            setData(response.data);
            toast.update(toastId, { render: 'Laudos carregados com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao buscar laudos', type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    async function getNursingCertifiedRecords(prontuarioId) {
        try {
            const { data } = await api.buscarRegistrosProcedimentosProntuarioId(prontuarioId);
            const rowsFormated = data.map((element) => ({
                id: element.id,
                type: element.tipo,
                blob_name: element.stored_blob_name,
                user: element.usuario.nome,
                date: element.formated_date
            }));
            setRowsNursing(rowsFormated);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getPdfBlob(blobName) {
        try {
            const { data } = await api.getBlob(blobName);
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            setPdfBlob(url);
        } catch (error) {
            toast.error(`Erro ao regatar documento: ${error?.response?.data?.message ?? error?.message}`);
        }
    }

    async function getProntuario() {
        const toastId = toast.loading('Buscando laudos...');
        try {
            const { data } = await api.getProntuarioById(prontuarioId);
            setProntuario(data);
            toast.update(toastId, { render: 'Ficha carregada com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao buscar ficha', type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    async function fetchData() {
        try {
            setLoadingData(true);
            await Promise.all([
                getRecord(),
                getConduct(),
                getAtestadoReceita(),
                getNursingCertifiedRecords(prontuarioId),
                getProntuario(prontuarioId)
            ]);
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            toast.error('Erro ao buscar dados da ficha:', error);
        }
    }

    // nurseReport

    async function getNurseReport() {
        try {
            const { data } = await api.getAllNurseReport(prontuarioId);
            const rows = data?.map((item) => {
                return {
                    ...item,
                    data: item?.dia,
                    hora: item?.hora,
                    tipo: item?.tipo,
                    user: allusers?.find((user) => user?.id === item?.responsavel_id)?.nome
                };
            });
            setNurseReport(rows);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }
    const [allusers, setAllUsers] = useState([]);
    async function users() {
        try {
            const { data } = await api.getAllUsers();
            setAllUsers(data.usuarios);
        } catch (error) {
            console.log('🚀 ~ users ~ error:', error);
        }
    }

    const columnsReport = [
        { field: 'data', headerName: 'DATA', flex: 1 },
        { field: 'hora', headerName: 'HORA', flex: 1 },
        { field: 'tipo', headerName: 'TIPO', flex: 0.8 },
        { field: 'user', headerName: 'RESPONSÁVEL', flex: 1 },
        { field: 'conteudo', headerName: 'CONTEÚDO', flex: 2 }
    ];

    useEffect(() => {
        users();
    }, []);

    useEffect(() => {
        if (allusers.length > 0) {
            getNurseReport();
        }
    }, [allusers]);

    useEffect(() => {
        users();
        fetchData();
    }, []);

    // relatorio de acompanhamento
    const [cpf, setCpf] = useState('');
    const [openConfirmIdentity, setOpenConfirmIdentity] = useState(false);
    const [activeUserId, setActiveUserId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    async function getUserBycpf(cpf) {
        try {
            const { data } = await api.getUserByCpf(cpf);
            if (!data?.id) return toast.error('Cpf informado não encontrado nos registros do sistema');
            setActiveUserId(data?.id);
            setOpenConfirmIdentity(false);
            setOpenModal(true);
            return data?.id;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    function handleCloseReportModal(value) {
        if (value === false) {
            setOpenModal(false);
            window.location.reload();
        }
    }

    return (
        <MainCard title={`F.A ${prontuarioId} - ${nome}`}>
            {loadingData && (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
            <MonitoringReportModal
                open={openModal}
                setOpen={handleCloseReportModal}
                patient={{ id: prontuarioId, paciente: nome }}
                activeUserId={activeUserId}
            />
            <Dialog open={openConfirmIdentity}>
                <DialogTitle>Confirmação de identidade</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Typography variant="h4">Informe seu CPF para confirmar sua identidade</Typography>
                        <ReactInputMask mask="999.999.999-99" value={cpf} onChange={(e) => setCpf(e.target.value)}>
                            {(inputProps) => (
                                <OutlinedInput
                                    autoComplete="off"
                                    id="outlined-adornment-cpf-register"
                                    type="text"
                                    name="cpf"
                                    inputProps={inputProps}
                                />
                            )}
                        </ReactInputMask>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setCpf('');
                                setOpenConfirmIdentity(false);
                            }}
                        >
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                getUserBycpf(removeMask(cpf));
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            {!loadingData && (
                <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                    {pdfBlob && (
                        <Modal
                            open={openModalPdfViewer}
                            onClose={() => {
                                setPdfBlob(null);
                                setOpenModalPdfViewer(false);
                            }}
                        >
                            <Box sx={stylePdf}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '1rem',
                                        padding: '4px',
                                        alignItems: 'center',
                                        backgroundColor: '#eeeeee',
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
                                    }}
                                >
                                    <DownloadButton />
                                    <PrintButton />
                                    <ZoomOutButton />
                                    <ZoomPopover />
                                    <ZoomInButton />
                                </Box>
                                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                                    <Viewer fileUrl={pdfBlob} plugins={[getFilePluginInstance, printPluginInstance, zoomPluginInstance]} />
                                </Worker>
                            </Box>
                        </Modal>
                    )}
                    <Modal open={openModalLaudo} onClose={() => setOpenModalLaudo(false)}>
                        <Box sx={stylePdf}>
                            <PDFViewer width={'100%'} height={'100%'}>
                                <ConjuntoLaudosPDF data={data} />
                            </PDFViewer>
                        </Box>
                    </Modal>
                    <Modal open={openModalFicha} onClose={() => setOpenModalFicha(false)}>
                        <Box sx={stylePdf}>
                            <PDFViewer width={'100%'} height={'100%'}>
                                <RelatorioFichaMedica
                                    data={{
                                        ficha: {
                                            id: triagem?.prontuario_id,
                                            nome: nome,
                                            data: triagem?.data,
                                            idade: formatarDataIdade(paciente?.data_nascimento)
                                        },
                                        paciente: paciente,
                                        prontuario: prontuario,
                                        triagem: { hora: triagem?.hora, responsavel: triagem?.usuario?.nome, dados: triagem },
                                        sala: prontuario?.salas_enfermagem?.descricao ?? 'N/A',
                                        medicamentos: rows.filter((item) => item.tipo === 'MEDICAMENTO'),
                                        laboratorio: rows.filter((item) => item.tipo === 'LABORATÓRIO'),
                                        raiox_procedimentos: rows.filter((item) => item.tipo === 'RAIO-X' || item.tipo === 'PROCEDIMENTO'),
                                        atestado_receita: rowsAtestadoReceita,
                                        procedimentos_enfermagem: rowsNursing,
                                        relatorioAcompanhamento: nurseReport,
                                        medicalRecordObs: medicalRecordObs?.conteudo
                                    }}
                                />
                            </PDFViewer>
                        </Box>
                    </Modal>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', position: 'absolute', right: 0, top: '-60px' }}>
                            <p>
                                PRONTUÁRIO: <b>{triagem?.prontuario_id}</b>
                            </p>
                            <p>
                                FICHA DE ATENDIMENTO: <b> {medicine?.paciente_id}</b>
                            </p>
                            <p>
                                DATA: <b> {triagem?.data}</b>
                            </p>
                            <p>
                                IDADE: <b> {formatarDataIdade(paciente?.data_nascimento)}</b>
                            </p>
                            <p>
                                SITUAÇÃO MÉDICA: <b>{translateStatusMedical(prontuario.status)}</b>
                            </p>
                        </Box>
                        <Box sx={{ padding: '10px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h4>ESCUTA INICIAL</h4>
                                <p>
                                    HORA: <b>{triagem?.hora}</b>
                                </p>

                                <h4>
                                    RESPONSÁVEL: <b>{triagem?.usuario?.nome}</b>
                                </h4>
                            </Box>
                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <MonitorHeartIcon />
                                    <TextField
                                        label="SIS"
                                        type="number"
                                        value={triagem?.pa_1}
                                        name="pa_sis"
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px', marginLeft: '.5rem' }}
                                    />
                                    <CloseIcon sx={{ margin: '0 5px' }} />
                                    <TextField
                                        label="DIA"
                                        type="number"
                                        placeholder="DIA"
                                        value={triagem?.pa_2}
                                        name="pa_dia"
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FavoriteIcon />
                                    <TextField
                                        label="FC"
                                        type="number"
                                        value={triagem?.fc}
                                        name="fc"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconLungs />
                                    <TextField
                                        label="FR"
                                        type="number"
                                        value={triagem?.fr}
                                        name="fc"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <VaccinesIcon />
                                    <TextField
                                        label="DEXTRO"
                                        type="number"
                                        value={triagem?.dextro}
                                        name="fc"
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <MonitorWeightIcon />
                                    <TextField
                                        disabled
                                        label="Peso"
                                        name="peso"
                                        type="text"
                                        value={triagem.peso}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px', marginLeft: '.5rem' }}
                                    />
                                    <IconArrowAutofitHeight sx={{ margin: '0 5px' }} />
                                    <TextField
                                        disabled
                                        label="Altura"
                                        name="altura"
                                        type="text"
                                        value={triagem.altura}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '130px', marginLeft: '.5rem' }}
                                    />
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CalculateIcon />
                                    <TextField
                                        label="IMC"
                                        name="IMC"
                                        type="number"
                                        value={triagem.imc}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconThermometer />
                                    <TextField
                                        label="Temperatura"
                                        name="temperatura"
                                        type="text"
                                        value={triagem?.temperatura}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconPercentage />
                                    <TextField
                                        label="Saturação"
                                        name="saturacao"
                                        type="number"
                                        value={triagem?.saturacao}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '120px', marginLeft: '.5rem' }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '10px' }}>
                                QUEIXA: <b>{triagem?.queixa ? triagem?.queixa : 'QUEIXA NÃO INFORMADA'}</b>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <p>
                                    MÉDICAÇÃO EM USO:
                                    {medicine?.medicacao_em_uso?.length === 0
                                        ? 'NEGA'
                                        : medicine?.medicacao_em_uso?.map((item, index, array) => (
                                              <span key={index}>{array.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                                          ))}
                                </p>
                                <p>
                                    ALERGIA:
                                    {medicine?.alergia?.length === 0
                                        ? 'NEGA'
                                        : medicine?.alergia?.map((item, index, array) => (
                                              <span key={index}>{array.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                                          ))}
                                </p>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ padding: '10px', marginTop: '10px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <h3>Local de espera: {prontuario?.salas_enfermagem?.descricao ?? 'N/A'}</h3>
                            {/* <ModalRoom getProntuario={getProntuario} /> */}
                        </Box>
                    </Box>
                    {!!medicalRecordObs?.length && (
                        <Box sx={{ borderRadius: '7px', border: '1px solid #ccc', padding: '10px', marginTop: '10px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h3>Ficha médica</h3>
                            </Box>

                            <DataGrid
                                rows={medicalRecordObs}
                                columns={columnsMedicalRecord}
                                hideFooter
                                getRowHeight={() => 'auto'}
                                sx={{
                                    maxHeight: '300px',
                                    overflow: 'auto',
                                    '& .MuiDataGrid-row': {
                                        padding: '10px'
                                    }
                                }}
                            />
                        </Box>
                    )}
                    <Box sx={{ borderRadius: '7px', border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h3>CONDUTA MÉDICA</h3>
                        </Box>
                        <Box sx={{ marginTop: '20px' }}>
                            {rows.length === 0 ? (
                                <p>Nenhum dado encontrado</p>
                            ) : (
                                <>
                                    {rows.filter((item) => item.tipo === 'MEDICAMENTO').length > 0 && (
                                        <>
                                            <h3>MEDICAMENTOS</h3>
                                            <DataGrid
                                                sx={{ maxHeight: '400px' }}
                                                columns={columns}
                                                rows={rows.filter((item) => item.tipo === 'MEDICAMENTO')}
                                                hideFooter
                                            />
                                        </>
                                    )}
                                    {rows.filter((item) => item.tipo === 'LABORATÓRIO').length > 0 && (
                                        <>
                                            <h3>LABORATÓRIO</h3>
                                            <DataGrid
                                                sx={{ maxHeight: '400px' }}
                                                columns={columnsLab}
                                                rows={rows.filter((item) => item.tipo === 'LABORATÓRIO')}
                                                hideFooter
                                            />
                                        </>
                                    )}
                                    {rows.filter((item) => item.tipo === 'RAIO-X' || item.tipo === 'PROCEDIMENTO').length > 0 && (
                                        <>
                                            <h3>RAIO-X / PROCEDIMENTOS</h3>
                                            <DataGrid
                                                sx={{ maxHeight: '400px' }}
                                                columns={columnsLabRaioProcedimentos}
                                                rows={rows.filter((item) => item.tipo === 'RAIO-X' || item.tipo === 'PROCEDIMENTO')}
                                                hideFooter
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            padding: '10px',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            borderRadius: '7px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>ATESTADOS E RECEITAS</h3>
                        </Box>

                        {rowsAtestadoReceita.length > 0 && (
                            <DataGrid columns={columnsAtestadoReceita} rows={rowsAtestadoReceita} getRowId={(row) => row?.uuid} />
                        )}
                    </Box>
                    <Box
                        sx={{
                            padding: '10px',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            borderRadius: '7px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>ACOMPANHAMENTOS DE ENFERMAGEM CERTIFICADOS</h3>
                        </Box>

                        {rowsNursing.length > 0 && <DataGrid columns={columnsNursing} rows={rowsNursing} />}
                    </Box>

                    <Box
                        sx={{
                            padding: '10px',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            borderRadius: '7px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>Relatório de Acompanhamento</h3>
                            <Tooltip title="Gerar Relatório">
                                <IconButton onClick={() => setOpenConfirmIdentity(true)}>
                                    <IconArticle />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {nurseReport.length > 0 && (
                            <DataGrid
                                getRowHeight={(params) => Math.max(50, params?.size || 0)}
                                rows={nurseReport}
                                sx={{ maxHeight: '400px' }}
                                columns={columnsReport}
                                hideFooter
                            />
                        )}
                    </Box>

                    <Box sx={{ width: '100%', marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="contained" color="error" onClick={handleBackPage}>
                            Voltar
                        </Button>
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip title="Gerar Relatório">
                                <Button variant="contained" onClick={() => setOpenModalFicha(true)}>
                                    <IconPrinter />
                                </Button>
                            </Tooltip>
                            <Button variant="contained" onClick={() => setOpenFinalizationModal(true)}>
                                Alterar status ficha
                            </Button>
                        </Box>
                    </Box>
                    <MedicalFinalizationModal
                        open={openFinalizationModal}
                        setOpenFinalizationModal={setOpenFinalizationModal}
                        prontuarioId={prontuarioId}
                        rows={rows}
                    />
                </Grid>
            )}
        </MainCard>
    );
}
