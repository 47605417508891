import MainCard from 'ui-component/cards/MainCard';
import { Grid, TablePagination, Box } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';

import TempoMedio from '../components/TempoMedio';
import TempoEspera from '../components/TempoEspera';
import Aguardando from '../components/Aguardando';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { TitleComponent } from '../components/TitleComponent';

const XRayMonitoring = ({ nurseData }) => {
    const [averageWaitTime, setAverageWaitTime] = useState('');
    const [waitTime, setWaitTime] = useState('');

    const columns = [
        { field: 'id', headerName: 'F.A', flex: 0.4 },
        { field: 'name', headerName: 'PACIENTE', flex: 1 },
        { field: 'data', headerName: 'DATA', flex: 0.5 },
        { field: 'hora', headerName: 'HORA', flex: 0.4 }
    ];

    useEffect(() => {
        if (nurseData?.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(nurseData[0]?.createdAt);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [nurseData]);

    // CALCULO DE TEMPO MEDIO
    useEffect(() => {
        if (nurseData?.length > 0) {
            const calculateAverageWaitTime = () => {
                const currentDate = new Date();
                const totalWaitTimeInSeconds = nurseData.reduce((accumulator, record) => {
                    const recordDate = new Date(record.createdAt);
                    const timeDifference = currentDate.getTime() - recordDate.getTime();
                    return accumulator + Math.floor(timeDifference / 1000);
                }, 0);

                const averageWaitTimeInSeconds = totalWaitTimeInSeconds / nurseData?.length;
                const hours = Math.floor(averageWaitTimeInSeconds / 3600);
                const minutes = Math.floor((averageWaitTimeInSeconds % 3600) / 60);
                const seconds = Math.floor(averageWaitTimeInSeconds % 60);

                setAverageWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateAverageWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [nurseData]);

    return (
        <MainCard title={<TitleComponent title={'RAIO-X'} width={'250px'} />}>
            <Grid container spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignSelf: 'flex-end',
                        gap: '1rem',
                        position: 'absolute',
                        top: '-110px',
                        right: '-290px'
                    }}
                >
                    <TempoMedio tempoMedio={averageWaitTime} name="ESPERA" />
                    <Aguardando aguardandoQTD={nurseData?.length} name="PACIENTES" />
                    {/* <Aguardando aguardandoQTD={nurseData.filter((item) => item.situacao == 'LIBERADO').length} name="LIBERADOS" /> */}
                </Box>

                <Box sx={{ width: '100%', marginTop: '1rem', padding: '10px' }}>
                    <DataGrid
                        sx={{
                            width: '100%',
                            maxHeight: '280px'
                        }}
                        rowHeight={40}
                        rows={nurseData}
                        columns={columns}
                        hideFooter
                    />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default XRayMonitoring;
