import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import Logo from '../../../../../assets/images/Logos/LOGO_DO_LAC.jpg';
import SantaCasaLogo from '../../../../../assets/images/Logos/logo2.png';
import BwLogo from '../../../../../assets/BW TECNOLOGIA.png';

const PdfAllLabResult = ({ examsChecked }) => {
    console.log('🚀 ~ PdfAllLabResult ~ examsChecked:', examsChecked);
    const [patient, setPatient] = useState({});
    const [medico, setMedico] = useState({});
    const [allExams, setAllExams] = useState([]);
    const api = useApi();
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 15,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            padding: 10,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    };

    const getExameData = async () => {
        try {
            const promisse = examsChecked.map((item) => api.getAllExameData(item?.prontuarioId, item?.exameId, item?.exameProntuarioId));
            const response = await Promise.all(promisse);
            console.log('🚀 ~ getExameData ~ response:', response);

            setPatient(response[0].data.paciente);
            setMedico(response[0].data.medico);
            setAllExams(response.map((item) => item?.data));
        } catch (error) {
            console.log('🚀 ~ getExameData ~ error:', error);
        }
    };

    useEffect(() => {
        getExameData();
    }, [examsChecked]);

    const formatarData = (date) => {
        const dateObj = new Date(date);
        const year = dateObj.getUTCFullYear();
        const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getUTCDate()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    function DinamicTable({ dados }) {
        console.log('🚀 ~ DinamicTable ~ dados:', dados);
        const styles = {
            table: { width: '100%', borderCollapse: 'collapse', margin: '10px' },
            cell: { borderBottom: '1px solid #eee', padding: '5px', wordWrap: 'break-word', display: 'flex', justifyContent: 'center' }
        };

        return (
            <View>
                <View style={styles.table}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ ...styles.cell, width: '25%' }}>
                            <Text>NOME CAMPO</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '20%' }}>
                            <Text>VALORES OBTIDOS</Text>
                        </View>

                        <View style={{ ...styles.cell, width: '15%' }}>
                            <Text>UNIDADE</Text>
                        </View>
                        <View style={{ ...styles.cell, width: '40%' }}>
                            <Text>VALORES REFERÊNCIA</Text>
                        </View>
                    </View>

                    {dados?.map((item, index) => (
                        <View key={index} style={{ flexDirection: 'row' }}>
                            <View style={{ ...styles.cell, width: '25%' }}>
                                <Text>{item?.campos_especifico?.campo_nome}</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '20%' }}>
                                <Text>{item?.valor ? item?.valor : '-'}</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '15%' }}>
                                <Text>{item?.campos_especifico?.unidade ? item?.campos_especifico?.unidade : '-'}</Text>
                            </View>
                            <View style={{ ...styles.cell, width: '40%' }}>
                                <Text>{item?.campos_especifico?.valores ? item?.campos_especifico?.valores : '-'}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        );
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image src={Logo} style={styles.image} alt="logo" />
                    <Text>LABORATÓRIO DE ANÁLISES CLÍNICAS SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                </View>
                <View style={{ borderBottom: '1px solid #eee', marginTop: '5px' }} />
                <View style={{ marginTop: '15px', marginBottom: '20px' }}>
                    <View style={{ display: 'flex', justifyContent: 'space-between', fontSize: 9 }}>
                        <View>
                            <Text>Paciente: {patient?.nome_completo}</Text>
                            <Text>Data Nascimento: {formatarData(patient?.data_nascimento)}</Text>
                            <View style={styles.between}>
                                <Text>Médico Solicitante: {medico?.nome_completo}</Text>
                                <Text>CRM: {medico?.crm_coren}</Text>
                            </View>
                            <Text>Data e hora Laudo: {patient?.hora_laudo}</Text>
                        </View>
                    </View>
                </View>
                {allExams.map((item) => {
                    return (
                        <View>
                            <Text style={{ textAlign: 'center' }}>{item?.exame?.nome}</Text>
                            <View style={styles.between}>
                                <Text>Método: {item.exame.metodo}</Text>
                                <Text>Sigla: {item.exame.sigla}</Text>
                            </View>
                            <View>
                                <DinamicTable dados={item?.resultado_campo_especifico} />
                            </View>
                        </View>
                    );
                })}

                <View style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <View style={{ marginTop: '150px' }}>
                        <View style={{ width: '200px', borderTop: '1px solid #eee', textAlign: 'center' }}>
                            <Text>Responsável</Text>
                        </View>
                    </View>
                </View>

                <View fixed style={styles.fixed}>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Image fixed src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '30px' }} />
                        <View
                            fixed
                            style={{
                                padding: '20px',
                                gap: '5px',
                                fontSize: 5
                            }}
                        >
                            <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                            <Text>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Text>
                            <Text>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                        </View>
                    </View>
                    <Image style={{ display: 'flex', width: '15%', justifyContent: 'flex-end', alignItems: 'center' }} src={BwLogo} />
                </View>
            </Page>
        </Document>
    );
};

export default PdfAllLabResult;
