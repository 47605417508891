import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Divider, IconButton, TextField, Tooltip } from '@mui/material';
import { DoctorsSignatureConfirmationModal } from 'components/DoctorsSignatureConfirmationModal';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons';
import { DataGrid } from '@mui/x-data-grid';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export function MonitoringReportModal({ open, setOpen, patient, activeUserId }) {
    const handleClose = () => setOpen(false);
    const [assinaturaId, setAssinaturaId] = useState(null);
    const [type, setType] = useState(null);
    const api = useApi();
    const [description, setDescription] = useState('');
    const [allReports, setAllReports] = useState([]);
    const [allusers, setAllUsers] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    async function createReport() {
        const payload = {
            conteudo: description,
            tipo: type,
            prontuario_id: patient?.id,
            assinatura_id: assinaturaId ?? null,
            responsavel_id: activeUserId
        };
        const toastId = toast.loading('Enviando relatório...');
        try {
            await api.createNurseReport(payload);
            toast.update(toastId, {
                render: 'Relatório enviado com sucesso!',
                type: toast.TYPE.SUCCESS,
                isLoading: false,
                autoClose: 3000
            });
            setIsEditing(false);
            setDescription('');
            setType(null);
            getReports();
        } catch (error) {
            console.log('🚀 ~ createReport ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao enviar relatório.',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function users() {
        try {
            const { data } = await api.getAllUsers();
            setAllUsers(data);
        } catch (error) {
            console.log('🚀 ~ users ~ error:', error);
        }
    }

    async function getReports() {
        try {
            const { data } = await api.getAllNurseReport(patient?.id);

            const rows = data.map((item) => {
                return {
                    ...item,
                    data: item?.dia,
                    hora: item?.hora,
                    tipo: item?.tipo,
                    user: allusers?.usuarios?.find((user) => user?.id === item?.responsavel_id)?.nome
                };
            });
            setAllReports(rows);
        } catch (error) {
            console.log('🚀 ~ getReports ~ error:', error);
        }
    }

    function handleEdit(row) {
        setDescription(row.conteudo);
        setType(row.tipo);
        setIsEditing(true);
        setSelectedReport(row);
    }

    const columns = [
        { field: 'data', headerName: 'DATA', flex: 0.3 },
        { field: 'hora', headerName: 'Hora', flex: 0.3 },
        { field: 'tipo', headerName: 'TIPO', flex: 0.5 },
        { field: 'user', headerName: 'Responsável', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.3,
            renderCell: (params) => (
                <>
                    <Tooltip title="Visualizar relatório">
                        <IconButton onClick={() => handleEdit(params.row)}>
                            <IconEye />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    useEffect(() => {
        getReports();
        users();
    }, [patient]);

    function handleCancelView() {
        setIsEditing(false);
        setDescription('');
        setType(null);
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Box>
                    <h3 style={{ textAlign: 'center' }}>Relaório de acompanhamento</h3>
                    <Box sx={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
                        <p>FA: {patient?.id} - </p> <p>{patient?.paciente}</p>
                    </Box>
                    <Divider />
                </Box>

                <form>
                    <Box sx={{ display: 'flex', justifyContent: 'end', margin: '10px' }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            disabled={isEditing}
                            options={['ALTA', 'ACOMPANHAMENTO']}
                            sx={{ width: 250 }}
                            renderInput={(params) => <TextField {...params} label="Tipo" />}
                            onChange={(e, value) => setType(value)}
                            value={type}
                        />
                    </Box>
                    <TextField
                        id="standard-basic"
                        label="Observação"
                        fullWidth
                        multiline
                        rows={2}
                        value={description}
                        disabled={isEditing}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'end', margin: '10px' }}>
                        {isEditing ? (
                            <Button variant="contained" color="error" onClick={handleCancelView}>
                                Cancelar visualização
                            </Button>
                        ) : (
                            <DoctorsSignatureConfirmationModal
                                buttonText="Assinar"
                                confirmFunction={createReport}
                                setAssinaturaId={setAssinaturaId}
                            />
                        )}
                    </Box>
                </form>

                <Box>
                    <DataGrid rows={allReports} columns={columns} hideFooter />
                </Box>
            </Box>
        </Modal>
    );
}
