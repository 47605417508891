import { useApi } from 'Service/axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useAuth = () => {
    const navigate = useNavigate();
    const api = useApi();

    const [authenticated, setAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loggedUser, setLoggedUser] = useState([]);

    const registerUser = async (user) => {
        try {
            const response = await api.createUser(user);

            if (response.status === 201) {
                navigate('/main/edituser');
                toast.success('Usuário cadastrado com sucesso!');
            }
        } catch (error) {
            setErrorMessage(error);
            toast.error(`Erro: ${error.response.data.mensagem}`);
        }
    };

    async function handleVerifyPinCode(cpf, id) {
        const payload = {
            identificacao: cpf,
            usuario_id: id
        };
        try {
            const response = await api.verifyPinCode(payload);
            if (response.status === 200) {
                localStorage.setItem('pinCodeStatus', true);
            }
        } catch (error) {
            if ((error.response && error.response.status === 401) || error.response.status === 400) {
                localStorage.setItem('pinCodeStatus', false);
            } else {
                console.error('Erro ao verificar o PIN code:', error);
            }
        }
    }

    const login = async (values) => {
        try {
            const response = await api.signin(values);
            const data = response.data;
            setLoggedUser(data);
            await authUser(data);
            toast.success('Bem vindo(a)');
        } catch (error) {
            toast.error(`Erro: ${error.response.data.mensagem}`);
            setErrorMessage(error);
        }
    };

    const logout = () => {
        const statusLogin = localStorage.getItem('login_expired');
        const loginIsExpired = JSON.parse(statusLogin);
        if (loginIsExpired) {
            localStorage.removeItem('consultorio');
        }
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('nome');
        localStorage.removeItem('id');
        localStorage.removeItem('pinCodeStatus');
        localStorage.removeItem('login_expired');
        localStorage.removeItem('userInfos');
        navigate('/');
    };

    //* Salva o dados no localStorage
    const authUser = async (data) => {
        await handleVerifyPinCode(data?.usuario?.cpf, data?.usuario?.id);
        setAuthenticated(true);
        localStorage.setItem('token', JSON.stringify(data.token));
        localStorage.setItem('role', data?.usuario?.role);
        localStorage.setItem('id', data?.usuario?.id);
        localStorage.setItem('nome', data?.usuario?.nome);
        localStorage.setItem('login_expired', data?.login_24_hours);
        localStorage.setItem('userInfos', JSON.stringify(data));
        localStorage.setItem('userIdentity', data?.usuario?.cpf);
        navigate('/main');
        window.location.reload();
    };

    const checkJWT = async (id) => {
        try {
            const response = await api.getUserById(id);
            return response;
        } catch (error) {
            logout();
        }
    };

    return { authenticated, errorMessage, loggedUser, login, registerUser, logout };
};

export default useAuth;
