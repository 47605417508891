import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import formateDate from 'utils/formatDate-1';

const TabelaPainelTriagem = ({ atendimentos }) => {
    const calculateTime = (time) => {
        const recordDate = new Date(time);
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - recordDate.getTime();
        const waitTimeInSeconds = Math.floor(timeDifference / 1000);

        const hours = Math.floor(waitTimeInSeconds / 3600);
        const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
        const seconds = waitTimeInSeconds % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    // Ordenar atendimentos por DATA (mais antigo para mais novo)
    const sortedAtendimentos = [...atendimentos].sort((a, b) => {
        const dateA = new Date(a?.pedido_medicacao?.prontuario?.data_atendimento || a?.exame_prontuario?.data_execucao);
        const dateB = new Date(b?.pedido_medicacao?.prontuario?.data_atendimento || b?.exame_prontuario?.data_execucao);

        return dateA - dateB; // Ordena de forma crescente (mais antigo para mais novo)
    });

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>F.A</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>PACIENTE</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>IDADE</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>DATA</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>HORA</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>ETAPA</b>
                        </TableCell>
                        {/* <TableCell align="center">
                            <b>ESPERA</b>
                        </TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedAtendimentos.map((item) => (
                        <TableRow key={item?.id}>
                            <TableCell>{item?.id}</TableCell>
                            <TableCell align="center">{item?.patient}</TableCell>
                            <TableCell align="center">{item?.age}</TableCell>
                            <TableCell align="center">{item.date}</TableCell>
                            <TableCell align="center">{item?.hour}</TableCell>
                            <TableCell align="center">{item?.situation}</TableCell>
                            {/* <TableCell align="center">
                                {calculateTime(
                                    item?.pedido_medicacao?.prontuario?.data_atendimento || item?.exame_prontuario?.prontuario?.createdAt
                                )}
                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabelaPainelTriagem;
